import AuthContext from 'chairisher/context/auth';
import ObjectUtils from 'chairisher/util/object';

/**
 * Singleton model of a Chairish account.
 */
class AccountModel {
    constructor() {
        this.firstName = '';
        this.lastName = '';
    }

    /**
     * Checks whether the current user identity is known
     *
     * @returns {boolean} true if the current user identity is known; false otherwise.
     */
    isIdentified() {
        const guid = this.getGuid();
        return guid && guid[0] !== '$';
    }

    /**
     * @returns {string}
     */
    getGuid() {
        return AuthContext.getGuid();
    }

    /**
     * @returns {boolean}
     */
    isAuthenticated() {
        return AuthContext.isAuthenticated();
    }

    /**
     * @returns {boolean}
     */
    isStaff() {
        return AuthContext.isStaff();
    }

    /**
     * @returns {boolean}
     */
    isImpersonated() {
        return AuthContext.isImpersonated();
    }

    /**
     * @param {string} firstName
     */
    setFirstName(firstName) {
        this.firstName = firstName;
    }

    /**
     * @param {string} lastName
     */
    setLastName(lastName) {
        this.lastName = lastName;
    }
}

ObjectUtils.addSingletonGetter(AccountModel);

export default AccountModel;
