const ChairishContext = window.chairisher.context;

/**
 * An interface that provides documentation and access to context variables related to the cart
 */
const CartContext = {
    /**
     * @returns {number} The number of products in the cart
     */
    getCartCount() {
        return ChairishContext.CART_COUNT || 0;
    },

    /**
     * @returns {string} The URL to register for an account
     */
    getCartRegisterUrl() {
        return ChairishContext.CART_REGISTER_URL;
    },

    /**
     * @returns {string} The URL for the cart
     */
    getCartUrl() {
        return ChairishContext.CART_URL;
    },
};

export default CartContext;
