import AuthContext from 'chairisher/context/auth';
import SiteContext from 'chairisher/context/site';

const callGtag = (action, ...args) => {
    if ('gtag' in window && !AuthContext.isBot() && !AuthContext.isStaff()) {
        window.gtag(action, ...args);
    }
};

const GoogleAnalyticsUtils = {
    /**
     * Identifies a user in Google Analytics. Only call this for authenticated users.
     *
     * @param {string} userId
     * @see https://developers.google.com/analytics/devguides/collection/ga4/user-id?client_type=gtag
     */
    identifyUser(userId) {
        callGtag('config', SiteContext.getGa4TagId(), {
            user_id: userId,
        });
    },

    /**
     * Sends an event to Google Analytics via window.gtag
     *
     * @param {string} eventName
     * @param {Object=} eventObject
     * @param {string=} sendTo defaults to 'GA4' which comes from the 'groups' param in the global gtag config call
     */
    logEvent(eventName, eventObject = {}, sendTo = 'GA4') {
        callGtag('event', eventName, { ...eventObject, send_to: sendTo });
    },

    /**
     * @param {Object} userDataObject
     */
    setUserData(userDataObject) {
        callGtag('set', 'user_data', userDataObject);
    },
};

export default GoogleAnalyticsUtils;
