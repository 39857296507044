import Cookies from 'js-cookie';

const ChairishContext = window.chairisher.context;

const CookieUtils = {
    /**
     * @returns {string|undefined} The cookie domain defined in settings, or undefined if not set.
     */
    getDomain() {
        return ChairishContext && ChairishContext.COOKIE_DOMAIN;
    },

    /**
     * Returns default cookie writing options including path and configured domain
     *
     * @returns {{path: string, domain: string=}} A default options object
     */
    getDefaultOptions() {
        const domain = CookieUtils.getDomain();
        const options = {
            path: '/',
        };

        if (domain) {
            options.domain = domain;
        }

        return options;
    },

    /**
     * @param {string} cookieName The name of the cookie to get a value for
     * @returns {string} The cookie's value
     */
    getValueFromCookie(cookieName) {
        return Cookies.get(cookieName);
    },

    /**
     * @param {string} cookieName The name of the cookie to get an integer value for
     * @returns {number} An integer representation of the cookie value (0 if the value cannot be parsed)
     */
    getIntValueFromCookie(cookieName) {
        return parseInt(CookieUtils.getValueFromCookie(cookieName), 10) || 0;
    },

    /**
     * Removes a cookie with the given key from all expected path/domain combinations
     *
     * @param {string} key The cookie name to remove
     */
    removeCookie(key) {
        Cookies.remove(key);

        Cookies.remove(key, {
            path: '/',
        });

        const options = CookieUtils.getDefaultOptions();
        Cookies.remove(key, options);
    },

    /**
     * Sets a cookie of a given key, value, and expiration length.
     *
     * @param {string} key The name of the cookie to set
     * @param {string} value The value of the cookie to set
     * @param {number=} expires The number of days in the future when the cookie should expire.
     *    Defaults to a session cookie (no expiration).
     */
    setCookie(key, value, expires) {
        const options = CookieUtils.getDefaultOptions();

        CookieUtils.removeCookie(key);

        if (expires) {
            options.expires = expires;
        }

        return Cookies.set(key, value, options);
    },
};

export default CookieUtils;
