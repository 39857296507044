import ProductContext from 'chairisher/context/product';
import ProductRail from 'chairisher/view/product/rail';

import { trackProductList2 } from 'chairisher/analytics/product';
import { observeIntersectionOnce } from 'chairisher/view/helper/intersectionobserver';

/**
 * Helper function that creates a Product Rail
 *
 * @param {Object} options
 * @param {jQuery} options.$containerEl The element containing the rail
 * @param {jQuery} options.$el The element representing the rail
 * @param {AnalyticsUtils.Tracking.POSITION=} options.analyticsTrackingPosition The position to identify this element's position in various analytics reports
 * @param {AnalyticsUtils.Tracking.SUB_TYPE=} options.analyticsTrackingSubtype The sub-type of the product rail to identify this element in analytics reports
 * @param {AnalyticsUtils.Tracking.TYPE} options.analyticsTrackingType The type of the product rail to identify this element in analytics reports
 * @param {string=} options.listSelector An optional argument for the selector for the containing element of a product list
 * @param {string} options.railUrl The url for the product rail
 * @param {boolean=} options.shouldRestrictToShipsTo An optional argument to only display products that can be shipped to the user
 * @param {boolean=} options.shouldShowBadging An optional argument for the product to show badges
 * @param {boolean=} options.shouldShowDetails An optional argument for the product to show details (badges, title, price)
 * @param {string=} options.sort An optional string to indicate a variation to the sort order (e.g. 'alt')
 * @returns {IntersectionObserver}
 */
export default function createProductRail({
    $containerEl,
    $el,
    analyticsTrackingPosition = null,
    analyticsTrackingSubtype = null,
    analyticsTrackingType,
    listSelector = null,
    railUrl,
    shouldRestrictToShipsTo = false,
    shouldShowBadging = true,
    shouldShowDetails = true,
    sort = null,
}) {
    return observeIntersectionOnce(`#${$containerEl.attr('id')}`, () => {
        const rail = new ProductRail(railUrl, {
            $containerEl,
            $el,
            analyticsTrackingPosition,
            analyticsTrackingSubtype,
            analyticsTrackingType,
            excludeIds: Object.keys(ProductContext.getProductIdToProductObjMap()),
            shouldRestrictToShipsTo,
            shouldShowBadging,
            shouldShowDetails,
            sort,
        });

        rail.fetchData().done(() => {
            trackProductList2({ listSelector });
        });
    });
}
