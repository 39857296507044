const ChairishContext = window.chairisher.context;

/**
 * @returns {string|undefined} The URL to use when redeeming trade rewards
 */
export function getTradeRewardsRedemptionUrl() {
    return ChairishContext.TRADE_REWARD_REDEMPTION_URL;
}

/**
 * @returns {Array.<String>} A list of benefits for the trade program
 */
export function getTradeInsuranceProgramBenefits() {
    return JSON.parse(ChairishContext.TRADE_INSURANCE_PROGRAM_BENEFITS);
}
