/**
 * @returns {Array} The collection (if any) of amplitude ab test tags
 */
export function getAmplitudeTestTags() {
    return window.chairisher.context.AMPLITUDE_TEST_TAGS || [];
}

/**
 * Sets the amplitude test tags in the Chairish JS Context
 *
 * @param {Array.<string>} testTags
 */
export function setAmplitudeTestTags(testTags) {
    window.chairisher.context.AMPLITUDE_TEST_TAGS = testTags || [];
}

/**
 * @param {Function} testClass The experiment class that contains the name of the requested ab test object
 * @returns {Object} The requested ab test object
 * @example
 * getTestObject(ExampleTest): {
 *    experiment_bucket: 1,
 *    experiment_id: "fake-id",
 *    experiment_name: "EXAMPLE_TEST"
 * }
 */
export function getTestObject(testClass) {
    return window.chairisher.context[testClass.getExperimentName()] || {};
}

/**
 * Sets an individual ab test object in the Chairish JS Context
 *
 * @param {Object} testObj
 */
export function setTestObject(testObj) {
    if (testObj && testObj.experiment_name) {
        window.chairisher.context[testObj.experiment_name] = testObj;
    }
}

/**
 * @param {Array.<Object>=} objectsToAdd Optional Collection of AB Test objects to extend
 * @returns {Array.<Object>} A collection of AB test objects; contains name, bucket, id, amplitude test tag
 */
export function getTestObjects(objectsToAdd) {
    const tests = {};
    const testsToAdd = (window.chairisher.context.AB_TESTS || []).concat(objectsToAdd || []);
    testsToAdd.forEach((test) => {
        tests[test.experiment_name] = test;
    });
    return Object.values(tests);
}

/**
 * Checks multiple locations of context for the ab test and returns its experiment bucket, if found
 *
 * @param {Function} testClass The experiment class
 * @returns {number|undefined} The bucket (if any) for the given class
 */
export function getBucketForTest(testClass) {
    const testObjects = [...getTestObjects(), getTestObject(testClass)];
    const testObject = testObjects.find((obj) => obj.experiment_name === testClass.getExperimentName());
    return testObject && testObject.experiment_bucket;
}
