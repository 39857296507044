const ChairishContext = window.chairisher.context;

/**
 * @returns {boolean} An indication of whether the collection is rendering the extended (visual) nav
 */
export function hasExtendedNav() {
    return !!ChairishContext.hasExtendedNav;
}

/**
 * @returns {string} The collection's canonical term
 */
export function getCollectionCanonicalTerm() {
    return ChairishContext.COLLECTION_CANONICAL_TERM;
}

/**
 * @returns {string} URL to use to append products to a curated collection
 */
export function getCollectionCuratePatchUrl() {
    return ChairishContext.COLLECTION_CURATE_PATCH_URL;
}

/**
 * @returns {string} The handle of the collection
 */
export function getCollectionHandle() {
    return ChairishContext.COLLECTION_HANDLE;
}

/**
 * @returns {number|undefined} The id of the collection, if a collection is being displayed
 */
export function getCollectionId() {
    return ChairishContext.COLLECTION_ID;
}

/**
 * @returns {string} URL to use to delete products from a collection sort
 */
export function getCollectionSortDeleteUrl() {
    return ChairishContext.COLLECTION_SORT_DELETE_URL;
}

/**
 * @returns {string} URL to use to append products to a collection sort
 */
export function getCollectionSortPatchUrl() {
    return ChairishContext.COLLECTION_SORT_PATCH_URL;
}

/**
 * @returns {string} The id of the Curated CollectionTypeCode
 */
export function getCollectionTypeCodeCurated() {
    return ChairishContext.COLLECTION_TYPE_CODES_MAP?.CURATED;
}

/**
 * @returns {string} The collection's URL
 */
export function getCollectionUrl() {
    return ChairishContext.COLLECTION_URL;
}

/**
 * @returns {string} The URL of the endpoint to use when saving curation sorts
 */
export function getCollectionCurateUrl() {
    return ChairishContext.COLLECTION_CURATE_URL;
}

/**
 * @returns {string} The URL to use to look up collections using a query string
 */
export function getCollectionLookupViewUrl() {
    return ChairishContext.COLLECTION_LOOKUP_VIEW_URL;
}

/**
 * @returns {string|undefined} The url of the Complete The Look endpoint, if any
 */
export function getCompleteTheLookUrl() {
    return ChairishContext.CTL_URL;
}

/**
 * @returns {string} The default name to use for an untitled folder
 */
export function getDefaultFolderName() {
    return ChairishContext.DEFAULT_FOLDER_NAME;
}

/**
 * @returns {string|undefined} The URL of folder_create if the current user is authenticated
 */
export function getFolderCreateUrl() {
    return ChairishContext.FOLDER_CREATE_URL;
}

/**
 * @returns {string|undefined} The text to use when linking to folder_create if the current user is authenticated
 */
export function getFolderCreateUrlText() {
    return ChairishContext.FOLDER_CREATE_URL_TEXT;
}

/**
 * @returns {string} The URL representing folder_deactivate for the current folder
 */
export function getFolderDeactivateUrl() {
    return ChairishContext.FOLDER_DEACTIVATE_URL;
}

/**
 * @param {number} productId The id of the product to get the URL for
 * @returns {string|undefined} The URL to list all folders relative to a product; undefined when the user is anonymous
 */
export function getFolderProductFolderListUrl(productId) {
    return ChairishContext.FOLDER_PRODUCT_FOLDER_LIST_URL
        ? ChairishContext.FOLDER_PRODUCT_FOLDER_LIST_URL.replace(0, productId)
        : undefined;
}

/**
 * @returns {Object} Object containing src, srcset, and sizes attributes for menu items displayed in Menu Card
 */
export function getFolderMenuItemImageFormats() {
    return ChairishContext.FOLDER_CARD_MENU_ITEM_FORMATS;
}

/**
 * @returns {Object} Object containing src, srcset, and sizes attributes for the product displayed in Menu Card
 */
export function getFolderProductImageFormats() {
    return ChairishContext.FOLDER_CARD_MENU_PRODUCT_FORMATS;
}

/**
 * @returns {string} The title to use when building a new folder
 */
export function getFolderTitle() {
    return ChairishContext.FOLDER_TITLE;
}

/**
 * @returns {Array} The ids of the products currently displayed on the page
 */
export function getGridProductIds() {
    return Object.keys(ChairishContext.PRODUCT_ID_TO_PRODUCT_JSON_MAP || {});
}

/**
 * @returns {number} The total number of products in the collection
 */
export function getNumCollectionProducts() {
    return ChairishContext.NUM_COLLECTION_PRODUCTS || 0;
}

/**
 * @returns {Object} Mapping of product id to product data
 */
export function getProductIdToProductJsonMap() {
    return ChairishContext.PRODUCT_ID_TO_PRODUCT_JSON_MAP || {};
}

/**
 * @returns {boolean} True indicates item is a collection
 */
export function isCollection() {
    return !!(ChairishContext.COLLECTION_IS_CURATED || ChairishContext.COLLECTION_IS_QUERY);
}

/**
 * @returns {boolean} True indicates the current collection is curated
 */
export function isCollectionCurated() {
    return !!ChairishContext.COLLECTION_IS_CURATED;
}

/**
 * @returns {boolean} True indicates the current collection is a folder
 */
export function isFolder() {
    return !!ChairishContext.COLLECTION_IS_FOLDER;
}

/**
 * @returns {boolean} True indicates the current collection is a shop
 */
export function isShop() {
    return !!ChairishContext.COLLECTION_IS_SHOP;
}

/**
 * @returns {string|undefined} The guid of the shop currently being viewed, if any
 */
export function getShopGuid() {
    return ChairishContext.ACCOUNT_GUID;
}
