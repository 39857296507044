/**
 * @class UriUtils
 */
const UriUtils = {
    /**
     * Decodes an application/x-www-form-urlencoded string, which, per MDN, can be achieved
     * by replacing + characters with %20 characters and calling decodeURIComponent.
     *
     * This is the opposite of UriUtils.encodeGetParam.
     *
     * @param {string} getParam The GET param key or value to decode
     * @returns {string} The decoded GET param key or value
     */
    decodeGetParam(getParam) {
        return decodeURIComponent(getParam.replace(/\+/g, ' '));
    },

    /**
     * Encodes a string to be application/x-www-form-urlencoded, which, per MDN, can be achieved
     * by calling encodeURIComponent and replace %20 characters with + characters.
     *
     * This is the opposite of UriUtils.decodeGetParam.
     *
     * @param {string} getParam The GET param key or value to encode
     * @returns {string} The encoded GET param key or value
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
     */
    encodeGetParam(getParam) {
        return encodeURIComponent(getParam).replace(/%20/g, '+');
    },

    /**
     * Returns the value of a specified GET param in a given URI if present
     * @param {string} uri
     * @param {string} paramName
     * @returns {string|undefined}
     */
    extractParamFromUri(uri, paramName) {
        if (uri) {
            const regex = new RegExp(`[\\?&#]${paramName}=([^&#]*)`);
            const params = regex.exec(uri);

            if (params !== null) {
                try {
                    return UriUtils.decodeGetParam(params[1]);
                } catch (e) {
                    return params[1];
                }
            }
        }
        return undefined;
    },

    /**
     * @param {boolean=} shouldRemoveWww True indicates the leading "www." (if any) should be removed
     * @returns {string} The hostname with or without the leading "www."
     */
    getHostname(shouldRemoveWww) {
        const { hostname } = document.location;
        const dubdubdub = 'www.';
        if (shouldRemoveWww && hostname.startsWith(dubdubdub)) {
            return hostname.replace(dubdubdub, '');
        }
        return hostname;
    },

    /**
     * Returns a mapping of decoded GET param keys to their values.
     *
     * @param {string} uriString The URI to collection GET params from
     * @returns {Array.<Object.<string>>} A collection of objects mapping GET param name to value
     */
    getParamMaps(uriString) {
        let uri = uriString;
        const params = [];

        const searchStringIndex = uri.indexOf('?');
        if (searchStringIndex !== -1) {
            uri = uri.substring(searchStringIndex + 1);
        }

        if (uri.length) {
            const vars = uri.split('&');

            for (let i = 0; i < vars.length; i += 1) {
                const p = vars[i].split('=');
                if (p.length === 2) {
                    try {
                        params.push({
                            name: UriUtils.decodeGetParam(p[0]),
                            value: p[1] !== undefined ? UriUtils.decodeGetParam(p[1]) : undefined,
                        });
                    } catch (e) {
                        // pass for now...
                    }
                }
            }
        }

        return params;
    },

    /**
     * Returns the current URI's query string
     *
     * @returns {string}
     */
    getQueryString() {
        return window.location.search;
    },

    /**
     * @param {string} uri The URI to inspect
     * @returns {boolean} True indicates the URI being inspected originates from Facebook
     */
    uriOriginatesFromFacebook(uri) {
        return uri.toLowerCase().includes('utm_source=facebook.com');
    },

    /**
     * @param {string} uri The URI to inspect
     * @returns {boolean} True indicates the URI being inspected originates from CPC
     */
    uriOriginatesFromCPC(uri) {
        return uri.toLowerCase().includes('utm_medium=cpc');
    },

    /**
     * @param {string} uri The URI to inspect
     * @returns {boolean} True indicates the URI being inspected originates from google
     */
    uriOriginatesFromGoogle(uri) {
        const re = /^(http(s)?:\/\/)?(www.)?google.com/;
        return re.test(uri);
    },

    /**
     * @returns {boolean} True indicates the current URI has #welcomeback in it or came from an email
     */
    uriOriginatesFromMarketing() {
        return window.location.hash === '#welcomeback' || UriUtils.uriOriginatesFromEmail();
    },

    /**
     * @returns {boolean} True indicates the current URI came from an SMS message
     */
    uriOriginatesFromSMS() {
        const uri = window.location.href;
        const utmSource = UriUtils.extractParamFromUri(uri, 'utm_source');
        const hasExternalId = !!UriUtils.extractParamFromUri(uri, 'externalId');

        return hasExternalId || utmSource === 'attentive';
    },

    /**
     * @param {string} utmSourceParam The type of ad campaign that the URI originates from
     * @returns {boolean} True indicates the URI came from a PLA ad campaign
     */
    uriOriginatesFromPLA(utmSourceParam) {
        const url = window.location.href;
        const utmSource = (UriUtils.extractParamFromUri(url, 'utm_source') || '').toLowerCase();
        const utmCampaign = UriUtils.extractParamFromUri(url, 'utm_campaign') || '';

        return utmSource === utmSourceParam && utmCampaign.includes('_PLA_');
    },

    /**
     * @returns {boolean} True indicates the current URI came from a Google PLA ad campaign click
     */
    uriOriginatesFromGooglePLA() {
        return UriUtils.uriOriginatesFromPLA('google');
    },

    /**
     * @returns {boolean} True indicates the current URI came from a Bing PLA ad campaign click
     */
    uriOriginatesFromBingPLA() {
        return UriUtils.uriOriginatesFromPLA('bing');
    },

    /**
     * @returns {boolean} True indicates the current URI came from an Instagram Story
     */
    uriOriginatesFromInstagramStory() {
        const url = window.location.href;
        const utmSource = UriUtils.extractParamFromUri(url, 'utm_source') || '';
        const utmCampaign = UriUtils.extractParamFromUri(url, 'utm_campaign') || '';

        return utmSource === 'instagram.com' && utmCampaign === 'Instagram_Stories';
    },

    /**
     * @returns {boolean} True indicates the current URI came from an organic Pinterest click (not a PLA)
     */
    uriOriginatesFromPinterestOrganic() {
        const url = window.location.href;
        const utmSource = UriUtils.extractParamFromUri(url, 'utm_source') || '';
        const utmCampaign = UriUtils.extractParamFromUri(url, 'utm_campaign') || '';
        const utmMedium = UriUtils.extractParamFromUri(url, 'utm_medium') || '';

        return utmSource === 'pinterest' && !utmCampaign && !utmMedium;
    },

    /**
     * @returns {boolean} True indicates the current URI came from a Google Shopping click (not a PLA)
     */
    uriOriginatesFromGoogleShopping() {
        const url = window.location.href;
        const utmSource = UriUtils.extractParamFromUri(url, 'utm_source') || '';
        const utmCampaign = UriUtils.extractParamFromUri(url, 'utm_campaign') || '';
        const utmMedium = UriUtils.extractParamFromUri(url, 'utm_medium') || '';

        return utmSource === 'google' && !utmCampaign && !utmMedium;
    },

    /**
     * @param {string} uri The URI to inspect
     * @returns {boolean} True indicates the URI originates from a seller badge
     */
    uriOriginatesFromSellerBadge(uri) {
        return UriUtils.extractParamFromUri(uri, 'utm_campaign') === 'seller_badge';
    },

    /**
     * @returns {boolean} True indicates the current URI came from an email
     */
    uriOriginatesFromEmail() {
        const uri = window.location.href;
        return UriUtils.extractParamFromUri(uri, 'utm_medium') === 'email';
    },
};

export default UriUtils;
