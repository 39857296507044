import FacebookAnalytics from 'chairisher/analytics/facebook';
import GoogleAnalyticsUtils from 'chairisher/util/googleanalytics';
import UriUtils from 'chairisher/util/uri';

import { getUtmCampaign } from 'chairisher/context/tracking';

import {
    AmplitudeEventProperty,
    TrackingAction,
    TrackingCategory,
    TrackingLabel,
    logAmplitudeEvent,
} from 'chairisher/util/analytics';

/**
 * Tracks a successful enrollment in the mailinglist
 *
 * @param {string} category
 * @param {string} action
 * @param {string=} label
 */
function trackSuccessfulEmailCollection(category, action, label = null) {
    const eventProperties = label ? { [AmplitudeEventProperty.POSITION]: label } : {};

    GoogleAnalyticsUtils.logEvent(`successful email collection - ${category}`, eventProperties);
    FacebookAnalytics.trackFacebookRegistrationConversion();
}

/**
 * Tracks an attempt to enroll someone in the mailinglist
 * @param {boolean} isDismissible
 * */
export function trackEmailModalCollectionAttempt(isDismissible) {
    const eventName = 'marketing email modal - show';
    const eventProperties = {
        [AmplitudeEventProperty.SCREEN_URL]: document.location.pathname,
        [AmplitudeEventProperty.IS_GATED]: !isDismissible,
    };

    logAmplitudeEvent(eventName, eventProperties);
    GoogleAnalyticsUtils.logEvent(eventName, eventProperties);
}

/**
 * Tracks a successful gated email modal collection
 * @param {boolean} isDismissible
 */
export function trackSuccessfulEmailModalCollection(isDismissible) {
    trackSuccessfulEmailCollection(
        TrackingCategory.MODAL,
        TrackingAction.COLLECTION_SUCCESS,
        isDismissible ? TrackingLabel.EMAIL_MODAL : TrackingLabel.EMAIL_MODAL_GATED,
    );
}

export function trackSuccessfulEmailPanelCollection() {
    trackSuccessfulEmailCollection(
        TrackingCategory.PANEL,
        TrackingAction.COLLECTION_SUCCESS,
        TrackingLabel.EMAIL_PANEL,
    );
}

export function trackSuccessfulEmailFooterCollection() {
    trackSuccessfulEmailCollection(
        TrackingCategory.FOOTER,
        TrackingAction.COLLECTION_SUCCESS,
        TrackingLabel.EMAIL_FOOTER,
    );
}

/**
 * Tracks an unsuccessful enrollment in the mailinglist
 */
export function trackUnsuccessfulEmailModalCollection() {
    GoogleAnalyticsUtils.logEvent(`unsuccessful email collection - ${TrackingCategory.MODAL}`, {
        [AmplitudeEventProperty.POSITION]: TrackingLabel.EMAIL_MODAL,
    });
}

/**
 * Tracks email unsubscribes
 */
export function trackUnsubscribe() {
    const templateName = getUtmCampaign();
    if (templateName && UriUtils.uriOriginatesFromEmail()) {
        logAmplitudeEvent('email - unsubscribe', {
            [AmplitudeEventProperty.EMAIL_TEMPLATE]: templateName,
        });
    }
}
