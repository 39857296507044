import $ from 'jquery';
import ErrorContext from 'chairisher/context/error';

/**
 * Provides an API for displaying a flash alert from JavaScript.
 *
 * @example const infoAlert = AlerterView.info(msg);
 * @class AlerterView
 */
const AlerterView = /** @lends AlerterView */ {
    /**
     * Creates and appends the DOM element that displays an alert
     *
     * @param {string} iconClassName The alert icon class name to display
     * @param {string|jQuery} msg The alert message to display
     * @param {string=} optClassName The CSS class name to add to the DOM element
     * @param {number=} optTimeout The amount of time in milliseconds to persist the alert.  A value of 0 displays alert
     *                             indefinitely
     * @return {jQuery} The jQuery element that was generated
     */
    create(iconClassName, msg, optClassName, optTimeout) {
        const $template = $($('#js-alert-template').html());
        const $alert = $template.clone();
        $('.js-alert-container').append($alert);

        // Set the message.
        $alert.prepend([
            $('<span></span>', {
                class: `alert-icon cicon ${iconClassName}`,
            }),
            $('<div></div>', {
                class: 'alert-message-container',
                html: msg,
            }),
        ]);

        // Add any additional classes.
        // This has no effect if optClassName is undefined.
        $alert.addClass(optClassName);

        // Bind the close button via bootstrap.js
        $alert.alert();

        const timeout = typeof optTimeout !== 'undefined' ? optTimeout : 10000;

        // Show the alert on the next run-loop.
        window.setTimeout(() => {
            $alert.addClass('show');
            if (timeout !== 0) {
                window.setTimeout(() => {
                    $alert.removeClass('show');
                    window.setTimeout(() => {
                        $alert.remove();
                    }, 500);
                }, timeout);
            }
        });

        return $alert;
    },
    /**
     * Displays an error alert
     *
     * @param {string=} optMsg The alert message to display
     * @param {number=} optTimeout The amount of time in milliseconds to persist the alert
     * @return {jQuery} The jQuery element that was generated
     */
    error(optMsg, optTimeout) {
        const msg = optMsg === undefined ? ErrorContext.getDefaultErrorText() : optMsg;
        return AlerterView.create('cicon-danger', msg, 'alert-danger', optTimeout);
    },
    /**
     * Displays an info alert
     *
     * @param {string} msg The alert message to display
     * @param {number=} optTimeout The amount of time in milliseconds to persist the alert
     * @return {jQuery} The jQuery element that was generated
     */
    info(msg, optTimeout) {
        return AlerterView.create('cicon-info', msg, 'alert-info', optTimeout);
    },
    /**
     * Displays a success alert
     *
     * @param {string} msg The alert message to display
     * @param {number=} optTimeout The amount of time in milliseconds to persist the alert
     * @return {jQuery} The jQuery element that was generated
     */
    success(msg, optTimeout) {
        return AlerterView.create('cicon-success', msg, 'alert-success', optTimeout);
    },
    /**
     * Displays a warning alert
     *
     * @param {string} msg The alert message to display
     * @param {number=} optTimeout The amount of time in milliseconds to persist the alert
     * @return {jQuery} The jQuery element that was generated
     */
    warning(msg, optTimeout) {
        return AlerterView.create('cicon-exclamationpoint', msg, 'alert-warning', optTimeout);
    },
};

export default AlerterView;
