import $ from 'jquery';
import BscEmailVerificationV2Test from 'chairisher/abtest/experiments/bscemailverificationabtest';
import MulberryABTest from 'chairisher/abtest/experiments/mulberryabtest';
import SearchRedirectV3Test from 'chairisher/abtest/experiments/srtabtest';
import ShippingMarginTest from 'chairisher/abtest/experiments/shippingmarginabtest';

import { getAmplitudeTestTags, setAmplitudeTestTags } from 'chairisher/context/abtest';

/**
 * List of the currently active ab test classes.
 */
const activeABTests = [
    BscEmailVerificationV2Test, // TODO (CHAIR-17403) remove after AB test is complete
    MulberryABTest,
    ShippingMarginTest, // TODO (CHAIR-17547) remove after AB test is complete
    SearchRedirectV3Test, // TODO (CHAIR-17591): remove after test
];

/**
 * Enum mapping the ab test experiment names to their respective ab test class
 *
 * @enum
 * @example testNameToTestClassMap = { 'EXAMPLE_TEST': ExampleTest }
 */
const testNameToTestClassMap = activeABTests.reduce(
    (result, cls) => ({ ...result, [cls.getExperimentName()]: cls }),
    {},
);

/**
 * Runs AB tests
 *
 * @param {Array.<Object>} data
 */
export default function runABTests(data) {
    const amplitudeTestTags = getAmplitudeTestTags();

    data.forEach((testObj) => {
        if (testObj && !$.isEmptyObject(testObj)) {
            const { amplitude_test_tag: tag, experiment_bucket: bucketNumber, experiment_name: testName } = testObj;
            if (tag && !amplitudeTestTags.includes(tag)) {
                amplitudeTestTags.push(tag);
            }

            const ABTestClass = testNameToTestClassMap[testName];
            if (ABTestClass) {
                new ABTestClass({ bucketNumber }).execute();
            }
        }
    });

    setAmplitudeTestTags(amplitudeTestTags);
}
