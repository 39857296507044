import $ from 'jquery';

import {
    InteractionType,
    NavigationType,
    NavigationValue,
    trackCarouselImpression,
    trackCarouselInteraction,
    trackCarouselLinkClick,
    trackCarouselNavigation,
} from 'chairisher/analytics/carousel';
import { prefersReducedMotion } from 'chairisher/util/mediaquery';
import { hasIntersectionObserver, observeIntersectionForElsOnce } from 'chairisher/view/helper/intersectionobserver';

const SLIDE_DURATION_IN_MS = 5000;

/**
 * Component that handles the image carousel logic + optional updating of external element data.
 *
 * @param {object} settings Settings related to the ImageCarousel
 * @param {string} settings.elClass The class used for distinguishing the unique carousel element
 * @param {Array=} settings.externalData Optional data for the carousel to update outside of the images
 * @param {string=} settings.position Optional position to send as part of Amplitude event
 */
class ImageCarousel {
    constructor({ elClass, externalData = [], position = '' }) {
        this.$el = $(`.${elClass}`);
        this.$indicators = this.$el.find('.js-indicator');
        this.$slideWrapper = this.$el.find('.js-image-slide-wrapper');
        this.$slides = this.$el.find('.js-image-slide');
        this.currentIndex = 0;
        this.elClass = elClass;
        this.externalData = externalData;
        this.isAutoScrollEnabled = true;
        this.maxIndex = this.$slides.length - 1;
        this.minIndex = 0;
        this.position = position;

        // if the image carousel is not a child of the $el, then the $el is the image carousel
        const $imageCarousel = this.$el.find('.js-image-carousel');
        this.$imageCarousel = $imageCarousel.length > 0 ? $imageCarousel : this.$el;
    }

    /**
     * Binds all necessary events for the image carousel to function properly
     */
    bind() {
        // fires Amplitude event when carousel enters viewport for the first time
        observeIntersectionForElsOnce(this.$el, () => {
            trackCarouselImpression(this.position, this.currentIndex);
        });

        // binds the arrow and indicator click actions + analytics
        this.$el.find('.js-carousel-arrow-next').on('click', () => {
            const targetIndex = this.getNextSlideIndex();
            this.handleSlideChangeClick(targetIndex);
            trackCarouselNavigation(this.position, NavigationType.ARROW, NavigationValue.NEXT, targetIndex);
        });

        this.$el.find('.js-carousel-arrow-previous').on('click', () => {
            const targetIndex = this.getPreviousSlideIndex();
            this.handleSlideChangeClick(targetIndex);
            trackCarouselNavigation(this.position, NavigationType.ARROW, NavigationValue.PREVIOUS, targetIndex);
        });

        this.$indicators.on('click', ({ currentTarget }) => {
            const targetIndex = $(currentTarget).index();
            this.handleSlideChangeClick(targetIndex);
            trackCarouselNavigation(this.position, NavigationType.INDICATOR, targetIndex, targetIndex);
        });

        // binds the link click analytics
        let $links = this.$el.find('.js-image-slide-link');
        if (this.externalData.length > 0) {
            $links = $links.add(this.$el.find(`.${this.elClass}-link`));
        }

        $links.on('click', ({ currentTarget }) => {
            const $currentTarget = $(currentTarget);
            trackCarouselLinkClick(
                this.position,
                $currentTarget.data('link-name'),
                $currentTarget.attr('href'),
                this.currentIndex,
            );
        });

        // binds how various interactions with the image affect autoscroll behavior
        this.$imageCarousel.on('touchmove', () => this.disableAutoScroll());

        this.$imageCarousel.on('mouseenter', () => {
            this.stopAutoScroll();
            trackCarouselInteraction(this.position, InteractionType.PAUSE);
        });

        this.$imageCarousel.on('mouseleave', () => {
            this.startAutoScroll();
            trackCarouselInteraction(this.position, InteractionType.START);
        });

        // ensures carousel data responds appropriately when manually scrolling
        const endEventName = 'onscrollend' in window ? 'scrollend' : 'touchend';
        this.$slideWrapper.on(endEventName, ({ currentTarget }) => {
            const activeIndex = Math.round(currentTarget.scrollLeft / this.slideWidth);
            if (this.currentIndex !== activeIndex) {
                const previousIndex = this.currentIndex;
                this.handleSlideChange(activeIndex, true);

                const navigationValue = previousIndex > activeIndex ? NavigationValue.PREVIOUS : NavigationValue.NEXT;
                trackCarouselNavigation(this.position, NavigationType.SCROLL, navigationValue, activeIndex);
            }
        });

        // maintains expected slide behavior when images are resized
        $(window).smartresize(() => {
            this.setSlideWidth();
        });

        // starts autoscroll when carousel enters viewport and pauses it when it exits
        if (hasIntersectionObserver()) {
            const carouselObservor = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        this.setSlideWidth();
                        this.startAutoScroll();
                    } else {
                        this.stopAutoScroll();
                    }
                });
            });
            carouselObservor.observe(this.$el[0]);
        }
    }

    /**
     * Disables automatic scrolling of the slides by clearing both the interval and removing event bindings
     */
    disableAutoScroll() {
        if (this.isAutoScrollEnabled) {
            this.stopAutoScroll();
            this.$imageCarousel.off('mouseleave mouseenter touchmove');
            this.isAutoScrollEnabled = false;
            trackCarouselInteraction(this.position, InteractionType.DISABLE);
        }
    }

    /**
     * @returns {number} The index of the next slide to display; either the subsequent or first index of the array
     */
    getNextSlideIndex() {
        return this.currentIndex < this.maxIndex ? this.currentIndex + 1 : this.minIndex;
    }

    /**
     * @returns {number} The index of the previous slide to display; either the preceeding or last index of the array
     */
    getPreviousSlideIndex() {
        return this.currentIndex > this.minIndex ? this.currentIndex - 1 : this.maxIndex;
    }

    /**
     * The actions necessary to implement a change to the displayed slide
     *
     * @param {number} targetIndex The index of the desired image slide to display
     * @param {boolean=} shouldPreventScroll Optional flag to prevent automatic setting of scroll position
     */
    handleSlideChange(targetIndex, shouldPreventScroll = false) {
        this.currentIndex = targetIndex;

        if (!shouldPreventScroll) {
            this.$slideWrapper.scrollLeft(this.slideWidth * targetIndex);
        }

        this.$indicators.removeClass('active');
        this.$indicators.eq(targetIndex).addClass('active');

        this.updateExternalData();
    }

    /**
     * Changes the displayed slide and disables autoscroll
     *
     * @param {number} targetIndex The index of the desired image slide to display
     */
    handleSlideChangeClick(targetIndex) {
        this.handleSlideChange(targetIndex);
        this.disableAutoScroll();
    }

    /**
     * Sets the width of the slides against the class
     */
    setSlideWidth() {
        this.slideWidth = this.$slides.first().width();
    }

    /**
     * Begins the automatic scrolling of the image slides, if reduced motion is not preferred
     */
    startAutoScroll() {
        if (!prefersReducedMotion()) {
            this.stopAutoScroll();
            this.autoScrollId = window.setInterval(() => {
                const targetIndex = this.getNextSlideIndex();
                this.handleSlideChange(targetIndex);
            }, SLIDE_DURATION_IN_MS);
        }
    }

    /**
     * Stops the automatic scrolling of the image slides
     */
    stopAutoScroll() {
        window.clearInterval(this.autoScrollId);
        this.autoScrollId = null;
    }

    /**
     * Updates the details of any external data to match the displayed slide
     */
    updateExternalData() {
        if (this.externalData.length > 0) {
            const currentData = this.externalData[this.currentIndex];
            Object.entries(currentData).forEach(([key, value]) => {
                const $el = this.$el.find(`.${this.elClass}-${key}`);

                if (key === 'link') {
                    $el.attr('href', value);
                } else {
                    $el.text(value);
                }
            });
        }
    }
}

export default ImageCarousel;
