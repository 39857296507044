const ChairishContext = window.chairisher.context;

/**
 * @returns {number} Number of results
 */
export function getNumResults() {
    return ChairishContext.NUM_RESULTS;
}

/**
 * @returns {Boolean} True indicates the search is within a collection
 */
export function isCollectionSearch() {
    return ChairishContext.IS_COLLECTION_SEARCH;
}

/**
 * @returns {Number} The number of search tokens that are required to enable typeahead.
 */
export function getMinTypeaheadTokenLength() {
    return ChairishContext.MIN_TYPEAHEAD_TOKEN_LENGTH;
}

/**
 * Enums of the different facet tag names that can be used for collection searches
 *
 * @enum {string}
 */
export const FacetTagNames = {
    LOCATION: 'location',
    QUERY: 'q',
    RADIUS: 'radius',
    SHIPPING_OPTIONS: 'shipping_options',
};

/**
 * @returns {string} The endpoint to hit when autocompleting a search query
 */
export function getAutocompleteEndpoint() {
    return ChairishContext.SEARCH_LOOKUP_URL;
}

/**
 * @return {string} A JSON object containing all the businesses
 */
export function getBusinessJson() {
    return ChairishContext.BUSINESS_JSON;
}

/**
 * @return {string} The URl to execute a business search
 */
export function getBusinessSearchUrl() {
    return ChairishContext.BUSINESS_SEARCH_URL;
}

/**
 * @returns {number} The default number of products to display
 */
export function getDefaultPageSize() {
    return ChairishContext.DEFAULT_PAGE_SIZE || -1;
}

/**
 * @returns {boolean} True indicates there are search results for the current search
 */
export function hasSearchResults() {
    return Object.keys(ChairishContext.PRODUCT_ID_TO_PRODUCT_JSON_MAP || {}).length > 0;
}

/**
 * @returns {Array.<FacetChoice>} Collection of data that indicates how search results can be filtered
 */
export function getFacets() {
    return ChairishContext.facets || [];
}

/**
 * @returns {Array.<FacetGrouping>} Collection of data indicating how facets should be grouped for display
 */
export function getFacetGroupings() {
    return ChairishContext.facetGroupings || [];
}

/**
 * @returns {Array.<DimensionGroup>} Collection of data to construct dimension filters
 */
export function getDimensionFilterGroups() {
    return ChairishContext.dimensionFilterGroups;
}

/**
 * @returns {Array.<string>}
 */
export function getDimensionFieldNames() {
    return ['depth', 'height', 'width'];
}

/**
 * Gets array of potential prices keys. Only 1 matching facet will be present in dom.
 *
 * @returns {Array.<string>}
 */
export function getPriceFacetFieldNames() {
    return ['price', 'trade_price'];
}

/**
 * @returns {Array.<string>} A list of field names that use a custom range.
 */
export function getCustomRangeFieldNames() {
    return [...getDimensionFieldNames(), ...getPriceFacetFieldNames()];
}

/**
 * @returns {Array.<string>} Array of facets which can only house one value at a time
 */
export function getSingularFieldNames() {
    return [FacetTagNames.LOCATION, FacetTagNames.RADIUS];
}

/**
 * @returns {string} The endpoint the SearchView should hit to valid `validationRequredFieldNames` fields
 */
export function getSearchValidationEndpointUrl() {
    return ChairishContext.searchValidationEndpointUrl;
}

/**
 * @returns {Array.<Object>} Mapping from field names to values to remove from them and errors to display in them
 */
export function getSearchErrors() {
    return ChairishContext.searchErrors || [];
}

/**
 * @returns {Object} A data structure containing the field names and ids of each ProductShippingOption
 */
export function getShippingOptions() {
    return ChairishContext.SHIPPING_OPTIONS;
}
