const ChairishContext = window.chairisher.context;

/**
 * @returns {string|undefined} The url that services the promoted listings popup drawer.
 */
export function getPromotedListingsPopupUrl() {
    return ChairishContext.PROMOTED_LISTINGS_POPUP_URL;
}

/**
 * @returns {string|undefined} The url of the promotedListings endpoint, if any
 */
export function getPromotedListingsUrl() {
    return ChairishContext.PROMOTED_LISTINGS_URL;
}

/**
 * @returns {string} The URL for registering a promoted listing impression.
 */
export function getPromotedListingsImpressionUrl() {
    return ChairishContext.PROMOTED_LISTINGS_IMPRESSION_URL;
}

/**
 * @returns {boolean}
 */
export function isSearchEligibleForPromotedListings() {
    return !!ChairishContext.IS_SEARCH_ELIGIBLE_FOR_PROMOTED_LISTINGS;
}

/**
 * @returns {boolean}
 */
export function isPromotedListingsPopupEnabled() {
    return !!ChairishContext.IS_PROMOTED_LISTINGS_POPUP_ENABLED;
}
