/**
 * Successful Responses (200 - 299)
 * ------------------------------------------------
 */

/**
 * @param {number} code the http response status code to check
 * @returns {boolean} True indicates that the request has succeeded.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/200
 */
export function isOk(code) {
    return code === 200;
}

/**
 * Client Error Responses (400 - 499)
 * ------------------------------------------------
 */

/**
 * @param {number} code the http response status code to check
 * @returns {boolean} True indicates that the request has not been completed because it lacks valid authentication credentials.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/401
 */
export function isUnauthorized(code) {
    return code === 401;
}

/**
 * @param {number} code the http response status code to check
 * @returns {boolean} True indicates that the server understands the request but refuses to authorize it.
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/403
 */
export function isForbidden(code) {
    return code === 403;
}
