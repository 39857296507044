const ChairishContext = window.chairisher.context;

/**
 * @returns {string|undefined} Optional tag used for help suggestions in the livechat menu
 */
export function getLiveChatHelpSuggestionTag() {
    return ChairishContext.LIVECHAT_HELP_SUGGESTION_TAG;
}

/**
 * @returns {object} Optional custom settings for the zendesk webWidget
 */
export function getLiveChatSettings() {
    return ChairishContext.LIVECHAT_SETTINGS;
}
