const ChairishContext = window.chairisher.context;

/**
 * @returns {string|undefined} The recatptcha v3 key to our recaptcha site, which will be undefined on localhost
 */
export function getRecaptchaSiteKey() {
    return ChairishContext.RECAPTCHA_SITE_KEY;
}

/**
 * @returns {string|undefined} The string describing the recaptcha action
 */
export function getRecaptchaActionName() {
    return ChairishContext.RECAPTCHA_ACTION_NAME;
}

/**
 * @returns {object} The RecaptchaAction Enum from the backend
 */
export function getRecaptchaActionsObject() {
    return ChairishContext.RECAPTCHA_ACTIONS_JSON;
}

/**
 * An interface that provides documentation and access to context variables related to authentication.
 */
const AuthContext = {
    /**
     * @returns {string|undefined} The current user's buyer type code
     */
    getBuyerTypeCode() {
        return ChairishContext.BUYER_TYPE_CODE;
    },

    /**
     * @return {string} The viewer's guid
     */
    getGuid() {
        return ChairishContext.GUID;
    },

    /**
     * @param {string} guid
     */
    setGuid(guid) {
        ChairishContext.GUID = guid;
    },

    /**
     * @returns {string} The URL for logging in
     */
    getLoginUrl() {
        return ChairishContext.LOGIN_URL || '';
    },

    /**
     * @returns {string} The MD5 Hash of the GUID
     */
    getMD5Guid() {
        return ChairishContext.MD5GUID || '';
    },

    /**
     * @param {string=} optTrackingCreateType Optional tracking create type to add as a GET param
     * @returns {string} The QuickCreate URL
     */
    getQuickCreateUrl(optTrackingCreateType) {
        let quickCreateUrl = ChairishContext.QUICK_CREATE_URL || '';

        if (quickCreateUrl && optTrackingCreateType) {
            quickCreateUrl += `?v=${optTrackingCreateType}`;
        }

        return quickCreateUrl;
    },

    /**
     * @returns {boolean} True indicates the current user is a bot
     */
    isBot() {
        return ChairishContext.IS_BOT;
    },

    /**
     * @returns {boolean} True indicates the current user is authenticated
     */
    isAuthenticated() {
        return !!ChairishContext.IS_AUTHENTICATED;
    },

    /**
     * @returns {boolean} True indicates the current user can access the merch tool
     */
    canAccessMerchTool() {
        return !!ChairishContext.CAN_ACCESS_MERCH_TOOL;
    },

    /**
     * @returns {boolean} True indicates the current user is staff
     */
    isStaff() {
        return ChairishContext.IS_STAFF;
    },

    /**
     * @returns {boolean} True indicates the current user is impersonated
     */
    isImpersonated() {
        return ChairishContext.IS_IMPERSONATED;
    },

    /**
     * @returns {boolean} True indicates the current user is a trade member
     */
    isTrade() {
        return ChairishContext.IS_TRADE;
    },

    /**
     * @returns {Object} Object containing QuickCreate data
     */
    getQuickCreateObject() {
        return ChairishContext.QUICK_CREATE;
    },

    /**
     * @returns {boolean} True indicates QuickCreate is initially sign up mode
     */
    isQuickCreateSignUp() {
        if (AuthContext.getQuickCreateObject()) {
            return !!ChairishContext.QUICK_CREATE.IS_INITIAL_SIGNUP;
        }
        return false;
    },

    isTosappAgreementRequired() {
        return !!ChairishContext.IS_TOSAPP_AGREEMENT_REQUIRED;
    },

    /**
     * @returns {boolean}
     */
    wasAccountCreated() {
        return !!ChairishContext.WAS_ACCOUNT_CREATED;
    },
};
export default AuthContext;
