const ChairishContext = window.chairisher.context;

/**
 * An interface that provides documentation and access to context variables related to location lookup.
 */
const LocationContext = {
    countryCodes: {
        ca: 'CA',
        us: 'US',
        unknown: 'UNKNOWN',
        anonymous: 'ANONYMOUS',
    },

    /**
     * @returns {string} The endpoint to hit to toggle country data (states, etc)
     */
    getCountryInfoUrl() {
        return ChairishContext.COUNTRY_INFO_URL;
    },

    /**
     * @returns {Array<string>} An array of alpha-2 country codes.
     */
    getPrimaryCountries() {
        return ChairishContext.PRIMARY_COUNTRIES || [];
    },

    /**
     * @returns {string} Primary country code.
     */
    getPrimaryCountry() {
        return ChairishContext.CLIENT_COUNTRY_CODE || LocationContext.countryCodes.unknown;
    },

    /**
     * @param {string} countryCode An alpha-2 country code
     * @returns {Object} An object containing the `display` and `value` key of the desired country; or an empty object when not found
     */
    getShippableCountryByCode(countryCode) {
        return LocationContext.getShippableCountryChoices().find((obj) => obj.value === countryCode) || {};
    },

    /**
     * @returns {Array<Object>>} An array of objects containing `display` and `value` keys
     */
    getShippableCountryChoices() {
        return ChairishContext.SHIPPABLE_COUNTRY_CHOICES || [];
    },

    /**
     * @returns {Array<string>} An array of alpha-2 country codes.
     */
    getShippableCountryCodes() {
        return ChairishContext.SHIPPABLE_COUNTRY_CODES || [];
    },

    /**
     * @returns {boolean} Is US.
     */
    isUS() {
        return LocationContext.getPrimaryCountry() === LocationContext.countryCodes.us;
    },

    /**
     * @returns {boolean} if user's primary country is a country we ship to
     */
    isUserInCountryWeShipTo() {
        return LocationContext.getShippableCountryCodes().includes(LocationContext.getPrimaryCountry());
    },
};

// Set country codes on LocationContext for lookup by clients (e.g., LocationContext.US will return 'US').
LocationContext.getPrimaryCountries().forEach((countryCode) => {
    LocationContext[countryCode] = countryCode;
});

export default LocationContext;
