import $ from 'jquery';
import ChairishABTest from 'chairisher/abtest';

/**
 * Name of the AB Test, which should match the cookie key serverside
 */
const EXPERIMENT_NAME = 'MULBERRY_AB_TEST';

/**
 * AB Test for determining if increasing the shipping costs has a negative impact on conversion,
 */
class MulberryABTest extends ChairishABTest {
    constructor(settings) {
        super(settings);
        this.name = EXPERIMENT_NAME;
    }

    /**
     * @returns {string}
     */
    static getExperimentName() {
        return EXPERIMENT_NAME;
    }

    control() {
        $('.js-buy-now-form').data('should-show-mulberry', 0);
        $('.js-protection-modal-trigger-container').remove();
    }

    variation() {
        $('.js-buy-now-form').data('should-show-mulberry', 1);
        $('.js-protection-modal-trigger-container').removeClass('mulberry-hidden');
    }
}

export default MulberryABTest;
