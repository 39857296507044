import $ from 'jquery';
import AlerterView from 'chairisher/view/alerter';
import ViewportUtil from 'chairisher/util/viewport';

/**
 * Manages the submission of a form inside a bootstrap modal, replacing the modal body with the
 * HTML response.
 * @param {jQuery} form The unbound form elements.
 */
var ModalViewHelper = {
    bindForm(form) {
        /**
         * Handles the submission of a modal form.
         * @this {HTMLFormElement} The form that was submitted
         * @param {jQuery.Event} event The event object.
         * @private
         */
        const _handleModalFormSubmit = function (event) {
            event.preventDefault();
            const $form = $(event.target);
            const $modal = $form.closest('.modal');
            const $modalBody = $modal.find('.modal-body');

            $.ajax({
                data: $form.serialize(),
                method: 'POST',
                url: $form.attr('action'),
            })
                .done((data) => {
                    // Unbind the form before its destroyed.
                    $form.off('submit');
                    $modalBody.html(data);
                    ModalViewHelper.bindForm($modal);
                })
                .fail((xhr, textStatus) => {
                    AlerterView.error(textStatus);
                });
        };

        form.buttonLoadingHelper().on('submit', _handleModalFormSubmit);
    },

    bindContent(form) {
        /**
         * Handles the submission of a modal form.
         * @this {HTMLFormElement} The form that was submitted
         * @param {jQuery.Event} event The event object.
         * @private
         */
        const _handleModalFormSubmit = function (event) {
            event.preventDefault();
            const $form = $(event.target);
            const $modal = $form.closest('.modal');
            const $modalContent = $modal.find('.modal-content');

            $.ajax({
                data: $form.serialize(),
                method: 'POST',
                url: $form.attr('action'),
            })
                .done((data) => {
                    // Unbind the form before its destroyed.
                    $form.off('submit');
                    $modalContent.html(data);
                    ModalViewHelper.bindContent($modal.find('form'));
                })
                .fail((xhr, textStatus) => {
                    AlerterView.error(textStatus);
                });
        };

        form.buttonLoadingHelper().on('submit', _handleModalFormSubmit);
    },

    /**
     * @returns {jQuery}
     */
    getBackdrop() {
        return $('.modal-backdrop');
    },

    /**
     * Adds a modal backdrop to the page
     *
     * @param {boolean} shouldDisplay True indicates a backdrop should be added; false removes it
     * @returns {jQuery} The backdrop that was added if toggle
     */
    toggleBackdrop(shouldDisplay) {
        const $body = $(document.body);
        let $backdrop = ModalViewHelper.getBackdrop();
        shouldDisplay = shouldDisplay === undefined ? $backdrop.length === 0 : !!shouldDisplay;

        if (shouldDisplay) {
            ViewportUtil.lockViewport();
            $backdrop = $('<div class="modal-backdrop fade in"></div>');
            $body.append($backdrop);
        } else {
            $backdrop.removeClass('in').remove();
            ViewportUtil.unlockViewport();
        }

        return $backdrop;
    },
};

export default ModalViewHelper;
