/**
 * @class ObjectUtils
 */
const ObjectUtils = {
    /**
     * Adds a static `getInstance` accessor to always return the same instance
     *
     * @param {!Function} obj The constructor function to modify
     */
    addSingletonGetter(obj) {
        const Obj = obj;
        Obj.getInstance = () => {
            if (!Obj.instance) {
                Obj.instance = new Obj();
            }
            return Obj.instance;
        };
    },

    /**
     * Recursively tests whether the object has any 'errors' keys that have a value set. Note: arrays are also objects.
     *
     * @param {object} obj
     * @returns {boolean}
     */
    doesObjHaveErrors(obj) {
        return (
            Object.entries(obj).some(([key, val]) => key === 'errors' && val.length > 0) ||
            Object.values(obj).some((val) => val && typeof val === 'object' && ObjectUtils.doesObjHaveErrors(val))
        );
    },

    /**
     * Accepts an array of objects and returns the object which has the specified field name as the parent key
     * If no object is found, or if array is empty, returns an empty object
     *
     * @param {array} arr The array of objects to be searched (e.g. [{ parentKey1: ['value1'] }, { parentKey2: ['value2'] }])
     * @param {string} fieldName The name of the section and also the object's parent key (e.g. parentKey1)
     * @returns {object} The found object or empty object (e.g. { parentKey1: ['value1'] })
     */
    findFieldObj(arr, fieldName) {
        let fieldObj = {};
        for (let i = 0; i < arr.length; i += 1) {
            if (Object.prototype.hasOwnProperty.call(arr[i], fieldName)) {
                fieldObj = arr[i];
                break;
            }
        }

        return fieldObj;
    },

    /**
     * Accepts a top-level object and iterates through each nested level to compile and return all of the values
     *
     * @param {object} parentObj The top-level object to iterate through
     *    (e.g.  { parentKey1: { childKey1: { grandChildKey1: 'value1' } }, parentKey2: { childKey2: { grandChildKey2: 'value2' } })
     * @returns {array} An array of all of the nested values (e.g. ['value1', 'value2'])
     */
    getAllNestedValues(parentObj) {
        const values = [];

        function recursivelyGetValues(obj) {
            Object.keys(obj).forEach((key) => {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    if (typeof obj[key] === 'object') {
                        recursivelyGetValues(obj[key]);
                    } else {
                        values.push(obj[key]);
                    }
                }
            });
        }

        recursivelyGetValues(parentObj);
        return values;
    },

    /**
     * Accepts a field name as the parent key and uses it to gather a list of its nested child keys
     *
     * @param {array} arr The array of objects to be searched (e.g. [{ parentKey: { childKey: ['value1'] } }])
     * @param {string} fieldName The name of the section and also the object's parent key (e.g. parentKey)
     * @returns {array} An array of all of the nested child keys (e.g. ['childKey'])
     */
    getChildKeysByFieldName(arr, fieldName) {
        const fieldObj = ObjectUtils.findFieldObj(arr, fieldName);
        return Object.keys(fieldObj[fieldName]);
    },

    /**
     * Locates an object based on its field name and then returns the values of its child object based on the child key
     * If no objects are found, or if the objects are not populated, returns an empty array
     *
     * @param {array} arr The array of objects to be searched (e.g. [{ parentKey: { childKey: ['value1'] } }])
     * @param {string} fieldName The name of the section and also the object's parent key (e.g. parentKey)
     * @param {string} childKey The key of the child object (e.g. childKey)
     * @returns {array} The found values or empty array (e.g. ['value1'])
     */
    getChildValuesByFieldName(arr, fieldName, childKey) {
        let values = [];
        const fieldObj = ObjectUtils.findFieldObj(arr, fieldName);
        if (Object.keys(fieldObj).length > 0) {
            const nestedObj = fieldObj[fieldName];
            if (Object.prototype.hasOwnProperty.call(nestedObj, childKey)) {
                values = nestedObj[childKey];
            }
        }

        return values;
    },

    /**
     * Locates an object based on its field name and then returns the values of that object
     * If no object is found, or if the object is not populated, returns an empty array
     *
     * @param {array} arr The array of objects to be searched (e.g. [{ parentKey: ['value1', 'value2'] }])
     * @param {string} fieldName The name of the section and also the object's parent key (e.g. parentKey)
     * @returns {array} The found values or empty array (e.g. ['value1', 'value2'])
     */
    getObjValuesByFieldName(arr, fieldName) {
        let values = [];
        const fieldObj = ObjectUtils.findFieldObj(arr, fieldName);
        if (Object.keys(fieldObj).length > 0) {
            values = fieldObj[fieldName];
        }

        return values;
    },
};

export default ObjectUtils;
