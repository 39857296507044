/**
 * Provides an abstract interface for jQuery related helper methods
 */
const JqueryUtils = {
    /**
     * Adds classes and ids to a given jQuery element based on a given single selector. Mostly useful when marshaling new
     * elements and adding classes without duplicating selectors without the preceding dot or hash. For example:
     *
     *   1) `JqueryUtils.decorateElementFromSelector($('<span></span>'), '.sdl');` will yield
     *          `<span class="sdl"></span>`
     *
     *   2) `JqueryUtils.decorateElementFromSelector($('<span></span>'), 'body > #content .sdl');` will yield
     *          `<span class="sdl"></span>`
     *
     *   3) `JqueryUtils.decorateElementFromSelector($('<span></span>'), '.sdl, .sdl1');` will yield
     *          `<span></span>`
     *
     *   4) `JqueryUtils.decorateElementFromSelector($('<span></span>'), '#content > div:last-child .sdl');` will yield
     *          `<span class="sdl"></span>`
     *
     *   5) `JqueryUtils.decorateElementFromSelector($('<span></span>'), '.sdl:last-child');` will yield
     *          `<span class="sdl"></span>`
     *
     *   6) `JqueryUtils.decorateElementFromSelector($('<span></span>'), '#yolo.sdl');` will yield
     *          `<span id="yolo" class="sdl"></span>`
     *
     * @param {jQuery} $el The element to decorate
     * @param {string} selector The selector from which classes and ids should be decorated from
     */
    decorateElementFromSelector($el, selector) {
        const requiredCharsRegex = /[.#]+/g; // at least one class or id is required
        const acceptableCharsRegex = /^[a-zA-Z0-9-_:\s.#>+|~]+$/;

        if (selector.match(requiredCharsRegex) && selector.match(acceptableCharsRegex)) {
            // split on:
            //     "space": descendant combinator
            //     ">>":  descendant combinator
            //     ">": child combinator
            //     "+": next-sibling
            //     "~": subsequent sibling
            //     "||": column combinator
            const pieces = selector.split(/[\s>+~|]+/g);

            if (pieces.length) {
                const keySelector = pieces[pieces.length - 1];

                if (keySelector.match(/^[.#a-zA-Z0-9-_]+:?[a-zA-Z0-9-_]+$/)) {
                    let id;
                    const classes = [];
                    const classesAndIds = keySelector.match(/([.#][a-zA-Z0-9-_]+)/g);

                    classesAndIds.forEach((c) => {
                        const char = c[0];
                        const classOrId = c.substring(1);

                        if (char === '.') {
                            classes.push(classOrId);
                        } else if (char === '#') {
                            id = classOrId;
                        }
                    });

                    $el.addClass(classes.join(' '));

                    if (id) {
                        $el.attr('id', id);
                    }
                }
            }
        }
    },
};

export default JqueryUtils;
