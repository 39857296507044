import $ from 'jquery';
import FormFieldComponent from 'chairisher/component/form/field';
import ErrorContext from 'chairisher/context/error';
import FacebookContext from 'chairisher/context/facebook';

const bind = function () {
    function bindFacebookButtons() {
        // select all unbound FB buttons
        const $btnFacebook = $('.js-btn-facebook:not(.js-is-bound)');

        if ($btnFacebook.length) {
            // disable only the unbound FB buttons
            $btnFacebook.prop('disabled', true);

            // bind the unbound FB buttons
            $btnFacebook.on('click', (e) => {
                e.preventDefault();

                const $btn = $(e.currentTarget);
                const $loginOrCreateForm = $btn.closest('form');
                const $tosOptInField = $loginOrCreateForm.find('[name="tos_opt_in"]');

                if (!$tosOptInField.length || $tosOptInField.prop('disabled') || $tosOptInField.prop('checked')) {
                    $loginOrCreateForm.disableLoadingButtons();
                    $btn.text($btn.data('contacting-text'));

                    const response = FacebookContext.getLoginStatusResponse();

                    if (response.status === 'connected') {
                        postFacebookLogin($loginOrCreateForm, response.authResponse.accessToken);
                    } else {
                        FB.login(
                            (response) => {
                                FacebookContext.setLoginStatusResponse(response);

                                if (response.status === 'connected') {
                                    postFacebookLogin($loginOrCreateForm, response.authResponse.accessToken);
                                } else {
                                    $loginOrCreateForm.resetLoadingButtons();
                                }
                            },
                            { scope: 'public_profile,email' },
                        ); // ask for this data
                    }
                } else {
                    const name = 'tos_opt_in';

                    const tosField = new FormFieldComponent({
                        data: {},
                        $el: $tosOptInField,
                        name,
                    });

                    tosField.renderErrors([{ display: ErrorContext.getDefaultCheckboxError() }]);
                }
            });

            // re-enable the newly bound FB buttons
            $btnFacebook.prop('disabled', false);

            // prevent double binding through exclusion
            $btnFacebook.addClass('js-is-bound');
        }
    }

    function initFacebookLibrary() {
        try {
            FB.init({
                appId: FacebookContext.getAppId(),
                cookie: true, // enable cookies to allow the server to access the session
                xfbml: true, // parse social plugins on this page
                version: FacebookContext.getGraphApiVersion(),
            });
            FacebookContext.setFacebookSdkLoaded(true);
        } catch (e) {
            FacebookContext.setFacebookSdkLoaded(false);
        }

        $('.js-btn-facebook').prop('disabled', true); // disable the button while we fetch login status

        if (FacebookContext.isFacebookSdkLoaded()) {
            FB.getLoginStatus((response) => {
                FacebookContext.setLoginStatusResponse(response);
            });
            bindFacebookButtons();
        }
    }

    if (FacebookContext.getLoginStatusResponse()) {
        bindFacebookButtons();
    } else if (window.FB) {
        initFacebookLibrary();
    } else {
        window.fbAsyncInit = initFacebookLibrary;
    }

    /**
     * Sets access token in the login/create form, indicates attempt is from clicking the facebook button, and submits.
     * @param {jQuery} form The jQuery object representing the form to be mutated and submitted.
     * @param {string} access_token The user's facebook access token.
     */
    function postFacebookLogin($form, access_token) {
        $form.find('[name=fb_token]').val(access_token);
        $form.find('[name=is_provider_initial_attempt]').val('1');
        $form.submit();
    }
};

export default {
    bind,
};
