import $ from 'jquery';

import { trackAccountCreated } from 'chairisher/analytics/auth';
import { trackAccountCreatedForPRT } from 'chairisher/thirdparty/pinterestretargeting';
import { setEmailModalOptOutCookie } from 'chairisher/view/helper/marketingpromo';

export default function bindInlineEmailSignup(options) {
    const { captureType, emailTrackingCallback, formSelector } = options;

    $(formSelector).submit((e) => {
        e.preventDefault();
        const $form = $(e.currentTarget);
        const $container = $form.parent();
        const $confirmation = "<h3>You're on the list!</h3><h4>Stay tuned for some serious eye candy.</h4>";

        $.ajax({
            data: `${$form.serialize()}&capture_type=${captureType}&path_name=${encodeURI(document.location.pathname)}`,
            dataType: 'json',
            method: 'POST',
            url: $form.attr('action'),
        }).done((data) => {
            setEmailModalOptOutCookie();

            if (emailTrackingCallback) {
                emailTrackingCallback();
            }

            if (data.was_account_created) {
                trackAccountCreatedForPRT();
                trackAccountCreated();
            }

            $form.hide();
            $container.addClass('signup-form-success');
            $container.html($confirmation);
        });
    });
}
