import ChairishUri from 'chairisher/util/uri';

/**
 * @class HistoryUtils
 */
const HistoryUtils = {
    /**
     * Removes a param whose name includes the substring from the current history entry
     *
     * @param {string} paramNameSubstr The substring to match
     */
    cleanseParamFromUri(paramNameSubstr) {
        HistoryUtils.cleanseParamsFromUri([paramNameSubstr]);
    },

    /**
     * Removes params whose name include the substrings from the current history entry
     *
     * @param {string} paramNameSubstrs The substrings to match
     */
    cleanseParamsFromUri(paramNameSubstrs) {
        const queryString = ChairishUri.getQueryString();

        const paramMaps = ChairishUri.getParamMaps(queryString);
        let paramString = '';

        for (let i = 0; i < paramMaps.length; i += 1) {
            const param = paramMaps[i];
            const isSubstrInParamName = (paramNameSubstr) => param.name.indexOf(paramNameSubstr) !== -1;
            if (!paramNameSubstrs.some(isSubstrInParamName)) {
                if (param.value !== undefined) {
                    paramString += `${[
                        ChairishUri.encodeGetParam(param.name),
                        ChairishUri.encodeGetParam(param.value),
                    ].join('=')}&`;
                }
            }
        }

        const loc = window.location;
        const newUrl = `${loc.protocol}//${loc.host}${loc.pathname}${
            paramString ? `?${paramString.substring(0, paramString.length - 1)}` : ''
        }`;

        HistoryUtils.replaceState(HistoryUtils.getState(), document.title, newUrl);
    },

    /**
     * Removes utm_* tags from the current history entry in the event that someone favorites the page.
     */
    cleanseUtmTagsFromUri() {
        HistoryUtils.cleanseParamFromUri('utm_');
    },

    /**
     * Accessor for window.history.state.
     * @returns {Object|undefined}
     */
    getState() {
        return window.history.state;
    },

    /**
     * @returns {boolean} True indicates window.history.pushState is available for use
     */
    hasPushState() {
        return window.history.pushState;
    },

    /**
     * @returns {boolean} True indicates window.history.replaceState is available for use
     */
    hasReplaceState() {
        return window.history.replaceState;
    },

    /**
     * @returns {boolean} True indicates the previous page comes from the same origin
     */
    isBackPermitted() {
        return document.referrer.indexOf(window.location.origin) === 0 && window.history.length > 1;
    },

    /**
     * @param {Object} stateData Object containing data related to the new history entry
     * @param {string} title The title of the page
     * @param {string} url The new history entry's URL
     */
    pushState(stateData, title, url) {
        if (HistoryUtils.hasPushState()) {
            window.history.pushState(stateData, title, url);
        } else {
            window.location.url = url;
        }
    },

    /**
     * Wrapper for window.history.replaceState to aid in unit testing
     *
     * @param {Object} stateObject Object containing data related to the state of the new history entry
     * @param {string} title The title of the new history entry
     * @param {string} url The URL for the new history entry
     */
    replaceState(stateObject, title, url) {
        window.history.replaceState(stateObject, title, url);
    },
};

export default HistoryUtils;
