import $ from 'jquery';

/**
 * Logic for generating modals based on a template embedded in base.html
 */
const ModalFactory = {
    /**
     * @enum {string}
     */
    ModalSize: {
        SMALL: 'modal-sm',
        MEDIUM: 'modal-md',
        LARGE: 'modal-lg',
        WIDE: 'modal-wide',
    },

    /**
     * Clones a new modal with the given id and returns it.
     *
     * @param {string} modalId The id of the modal to be created
     * @param {!ModalFactory.ModalSize=} modalSize Optional indicator of how big the modal should be
     * @returns {!jQuery} The modal element created
     */
    createModal(modalId, modalSize) {
        const $modal = $($('#js-template-modal').html()).attr('id', modalId);

        if (modalSize) {
            $modal.find('.modal-dialog').addClass(modalSize);
        }
        return $modal;
    },

    /**
     * Returns a jQuery object with the appropriate modal backdrop classes to be appended to document.body
     *
     * @param {boolean=} isLight Optional indicator of whether the backdrop is not the default dark backdrop
     * @returns {jQuery} The backdrop element
     */
    createModalBackdrop(isLight) {
        const backdropClass = isLight ? 'modal-backdrop-light' : 'modal-backdrop';
        return $(`<div class="js-modal-backdrop ${backdropClass} fade in"></div>`);
    },

    /**
     * Locates and removes the modal backdrop element
     */
    removeModalBackdrop() {
        $('.js-modal-backdrop').remove();
        $(document.body).removeClass('modal-open');
    },
};

export default ModalFactory;
