import $ from 'jquery';

import Autocomplete from 'chairisher/component/autocomplete';
import KeyUtils from 'chairisher/util/key';
import MediaQueryUtils from 'chairisher/util/mediaquery';
import ModalViewHelper from 'chairisher/view/helper/modal';
import NavigationView from 'chairisher/view/navigation';
import SiteContext from 'chairisher/context/site';
import ViewportUtil from 'chairisher/util/viewport';

import { getAutocompleteEndpoint, getMinTypeaheadTokenLength } from 'chairisher/context/search';
import trackGlobalSearch from 'chairisher/analytics/globalsearch';

/**
 * View that encapsulates functionality related to the global search bar
 *
 * @param {Object} settings
 * @param {jQuery=} settings.$menuContainerEl @see this._$menuContainerEl
 * @param {jQuery} settings.$queryEl jQuery element representing the text box input
 * @param {string=} settings.dataEndPoint The endpoint to hit for autocompletion suggestions; default is `search_lookup`
 * @param {number=} settings.limit The number of suggestions that the server should return; default is 12
 * @constructor
 */
const GlobalSearchView = function (settings) {
    settings = $.extend(
        {
            $menuContainerEl: this._$menuContainerEl,
            $cancelEl: this._$cancelEl,
            $queryEl: this._$queryEl,
            dataEndpoint: this._dataEndpoint,
            dataObject: { global: true },
            isGlobalSearch: true,
            limit: this._limit,
            minQueryLength: getMinTypeaheadTokenLength(),
        },
        settings,
    );

    settings.menuElClasses = 'menu';
    if (MediaQueryUtils.isMaxMediumDesktop() && SiteContext.isMotdBannerVisible()) {
        settings.menuElClasses += ' banner-visible';
    }

    settings.$menuContainerEl = function () {
        if (MediaQueryUtils.isMinLargeDesktop()) {
            return this._$el; // `this` points to the autocompleter
        }
        return $('#js-global-search-menu-container');
    };

    $('#js-global-nav').after(
        $('<div></div>', {
            class: 'global-search-menu-container',
            id: 'js-global-search-menu-container',
        }),
    );

    this._autocomplete = new Autocomplete(settings);
    this._$cancelEl = settings.$cancelEl;
    this._$queryEl = this._autocomplete.getQueryEl();
};

/**
 * jQuery element representing the search cancel button
 *
 * @type {jQuery}
 * @private
 */
GlobalSearchView.prototype._$cancelEl = null;

/**
 * Optional jQuery element to append the menu to; if a function, it must return a jQuery object
 *
 * @type {jQuery|function}
 * @private
 */
GlobalSearchView.prototype._$menuContainerEl = null;

/**
 * jQuery element representing the text box input
 *
 * @type {jQuery}
 * @private
 */
GlobalSearchView.prototype._$queryEl = null;

/**
 * The Autocomplete instance tied to `this._$queryEl`
 *
 * @type {Autocomplete}
 * @private
 */
GlobalSearchView.prototype._autocomplete = null;

/**
 * The endpoint to hit for autocompletion suggestions
 *
 * @type {string}
 * @private
 * @default
 */
GlobalSearchView.prototype._dataEndpoint = getAutocompleteEndpoint();

/**
 * The number of suggestions that the server should return
 *
 * @type {number}
 * @private
 * @default
 */
GlobalSearchView.prototype._limit = 12;

/**
 * Binds various actions to the global search view
 */
GlobalSearchView.prototype.bind = function () {
    this._$queryEl.on(
        'autocomplete:blur',
        $.proxy(() => {
            ViewportUtil.unlockViewport();
        }, this),
    );

    this._$queryEl.on(
        'autocomplete:focus',
        $.proxy(function () {
            this._toggleProductNav(false);

            if (SiteContext.getScrollY() === 0 && MediaQueryUtils.isMaxMediumDesktop()) {
                $('html, body').animate(
                    {
                        scrollTop: $('#js-promo-banner').height(),
                    },
                    $.proxy(function () {
                        this._toggleProductNav(false);
                    }, this),
                );
            }

            if (this._autocomplete.canMakeQuery()) {
                this._autocomplete.fetchData();
            }

            if (this._autocomplete.hasQueryText()) {
                if (this._autocomplete.hasChoices()) {
                    this._autocomplete.getMenu().show();
                }
            }
        }, this),
    );

    this._$queryEl.on('autocomplete:keypress', (e) => {
        if (e.which === KeyUtils.KeyCodes.Enter) {
            e.preventDefault();

            const query = this._autocomplete.getQueryText() || '';

            trackGlobalSearch('masthead', query);
            $(e.currentTarget).closest('form').submit();
        }
    });

    this._$queryEl.on(
        'keyup',
        $.proxy(function (e) {
            window.clearTimeout(this._typingTimeout);

            if (KeyUtils.KeyCodes.Escape === e.which) {
                ModalViewHelper.toggleBackdrop(false);
            }

            if (!KeyUtils.isEnter(e.which)) {
                this._typingTimeout = window.setTimeout(
                    $.proxy(function () {
                        const menu = this._autocomplete.getMenu();
                        const canMakeQuery = this._autocomplete.canMakeQuery();

                        NavigationView.toggleGlobalSearchSuggestions(canMakeQuery);

                        if (canMakeQuery) {
                            NavigationView.toggleProductNav(false);
                            ViewportUtil.lockViewport();
                        } else {
                            ViewportUtil.unlockViewport();
                        }

                        menu.toggle(canMakeQuery);
                    }, this),
                    100,
                );
            }
        }, this),
    );

    this._$cancelEl.on(
        'click',
        $.proxy(function () {
            this._autocomplete.setQueryText('');
            this._autocomplete.getMenu().updateSuggestionChoices([]);
            NavigationView.toggleGlobalSearchSuggestions(false);
            ModalViewHelper.toggleBackdrop(false);
        }, this),
    );

    this._$queryEl.on('autocomplete:selected', (_, item) => {
        trackGlobalSearch('masthead', null, item.display || item.additional_display, item.url);
        window.location.href = item.url; // open in current window
    });

    $('#js-mobile-nav-trigger').on(
        'click',
        $.proxy(function () {
            this._autocomplete.setQueryText('');
            this._autocomplete.hidePlaceholder();
            this._autocomplete.getMenu().updateSuggestionChoices([]);
        }, this),
    );

    $('.js-submit-icon').on('click', () => {
        trackGlobalSearch('masthead', this._autocomplete.getQueryText());
    });
};

GlobalSearchView.prototype._toggleProductNav = function (shouldDisplayProductNav) {
    NavigationView.toggleGlobalSearchSuggestions(!shouldDisplayProductNav);
    NavigationView.toggleProductNav(shouldDisplayProductNav);
};

export default GlobalSearchView;
