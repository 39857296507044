/**
 * Base class for an AB test.
 *
 * To use, subclass this and add variations in the constructor using the index of `this.variations`
 * to indicate which variation corresponds to which bucket.
 *
 * @param {number} bucketNumber
 */
class ChairishABTest {
    constructor({ bucketNumber }) {
        this.bucketNumber = bucketNumber || 0;

        /**
         * Collection of variations to choose from. Variation index maps to bucket number.
         *
         * @type {Array.<Function>}
         */
        this.variations = [];
        this.variations.push(this.control);
        this.variations.push(this.variation);

        /**
         * Name of the AB Test. Should match the cookie key serverside
         *
         * @type {string}
         */
        this.name = '';
    }

    /**
     * Control that can be overridden by subclasses
     */
    control() {}

    /**
     * Executes a test variation for a given bucket number
     */
    execute() {
        if (this.bucketNumber < this.variations.length && this.bucketNumber >= 0) {
            this.variations[this.bucketNumber]();
        }
    }

    /**
     * Getter that retrieves the experiment name and can be overridden by subclasses
     *
     * @returns {string} The name of the experiment, which should match the cookie key serverside
     */
    static getExperimentName() {}

    /**
     * @param {number} bucketNumber
     */
    setBucketNumber(bucketNumber) {
        this.bucketNumber = bucketNumber;
    }

    /**
     * Variation that can be overridden by subclasses
     */
    variation() {}
}

export default ChairishABTest;
