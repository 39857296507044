const ChairishContext = window.chairisher.context;

/**
 * Datastructure for keeping track of Apple related variables in chairisher.context
 */
const AppleContext = {
    /**
     * @returns {string}
     */
    getClientId() {
        return ChairishContext.APPLE_CLIENT_ID;
    },
    /**
     * @returns {string}
     */
    getRedirectUri() {
        return ChairishContext.APPLE_REDIRECT_URI;
    },
};

export default AppleContext;
