const ChairishContext = window.chairisher.context;

export function getOrderItems() {
    return ChairishContext.orderItems || [];
}

export function getProductIdToProductMap() {
    return ChairishContext.productIdToProductMap || {};
}

export function getOrder() {
    return ChairishContext.order;
}
