import $ from 'jquery';

import GoogleAnalyticsUtils from 'chairisher/util/googleanalytics';
import ProductContext from 'chairisher/context/product';
import ProductModel from 'chairisher/model/product';

import { trackFavoriteCreatedForPRT } from 'chairisher/thirdparty/pinterestretargeting';
import { AmplitudeEventProperty, logAmplitudeEvent, PDPSection } from 'chairisher/util/analytics';
import { HOUSE_AD_CLASS } from 'chairisher/view/helper/promotedlistings';

/**
 * @param {ProductModel} product
 * @returns {Object} The standard event properties to use for a given product
 */
export function getAmplitudeEventPropertiesForProduct(product) {
    const $products = $('[data-product-id]');
    const $product = $(`[data-product-id="${product.getId()}"]`);
    const productIndex = $products.index($product);

    const productSpotlightTypeCodes = product.getSpotlightTypeCodes().split(',');
    const quossAssignments = productSpotlightTypeCodes.filter((code) =>
        ProductContext.getQuossAssignmentSpotlightTypeCodes().includes(code),
    );

    return {
        [AmplitudeEventProperty.BADGE]: product.getFirstBadge(),
        [AmplitudeEventProperty.CATEGORY_CODE]: product.getCategoryCode(),
        [AmplitudeEventProperty.CUBIC_FEET]: product.getCubicFeet(),
        [AmplitudeEventProperty.DEALER_GUID]: product.getDealerGuid(),
        [AmplitudeEventProperty.DISCOUNT_PERCENT]: product.getDiscountPercent(),
        [AmplitudeEventProperty.ESTIMATED_ARRIVAL_TIMEFRAME]: product.getEstimatedArrivalTimeframe(),
        [AmplitudeEventProperty.HAS_PROP_65_WARNING]: product.getHasProp65Warning(),
        [AmplitudeEventProperty.IS_BOOSTED]: productSpotlightTypeCodes.includes(
            ProductContext.getBoostSpotlightTypeCode(),
        ),
        [AmplitudeEventProperty.IS_MARKDOWN]: product.getIsMarkdown(),
        [AmplitudeEventProperty.IS_NEWLY_MADE]: product.getIsNewlyMade(),
        [AmplitudeEventProperty.IS_ON_HOLD]: product.getIsOnHold(),
        [AmplitudeEventProperty.IS_ON_PRIVATE_SALE_FOR_USER]: product.getIsOnPrivateSaleForUser(),
        [AmplitudeEventProperty.IS_ON_PUBLIC_SALE]: product.getIsOnPublicSale(),
        [AmplitudeEventProperty.IS_PAMONO]: product.getIsPamono(),
        [AmplitudeEventProperty.IS_PART_OF_PRIVATE_SALE]: product.getIsOnPrivateSale(),
        [AmplitudeEventProperty.IS_PROMOTED_LISTING]: product.getIsPromotedListing(),
        [AmplitudeEventProperty.IS_VARIANT]: product.getIsVariant(),
        [AmplitudeEventProperty.IS_WHOLESALE]: product.getIsWholesale(),
        [AmplitudeEventProperty.LEAD_TIME]: product.getLeadTime(),
        [AmplitudeEventProperty.PRODUCT_GROUP_ID]: product.getProductGroupId(),
        [AmplitudeEventProperty.PRODUCT_ID]: product.getId(),
        [AmplitudeEventProperty.PRODUCT_INDEX]: productIndex,
        [AmplitudeEventProperty.PRODUCT_TAXONOMY]: product.getTaxonomy(),
        [AmplitudeEventProperty.PRODUCT_PRICE]: product.getPrice(),
        [AmplitudeEventProperty.QUOSS_ASSIGNMENT]: quossAssignments.join(','),
        [AmplitudeEventProperty.SCREEN_URL]: document.location.pathname,
        [AmplitudeEventProperty.SHIPPING_COST]: product.getShippingCost(),
        [AmplitudeEventProperty.SHIPPING_DISTANCE]: product.getShippingDistance(),
        [AmplitudeEventProperty.SHIPPING_PARTNER_CODE]: product.getShippingPartnerCode(),
        [AmplitudeEventProperty.SHIPPING_TYPE_CODE]: product.getShippingTypeCode(),
        [AmplitudeEventProperty.SPOTLIGHT_TYPE_CODES]: product.getSpotlightTypeCodes(),
        [AmplitudeEventProperty.TRADE_DISCOUNT_PERCENT]: product.getTradeDiscount(),
        [AmplitudeEventProperty.UNIT_TYPE]: product.getUnitType(),
    };
}

/**
 * @param {ProductModel} product
 * @returns {Object} The standard event properties to use for a detail section on PDP for a given product
 */
export function getAmplitudeEventPropertiesForProductDetailsSection(product) {
    return {
        [AmplitudeEventProperty.CATEGORY_CODE]: product.getCategoryCode(),
        [AmplitudeEventProperty.CUBIC_FEET]: product.getCubicFeet(),
        [AmplitudeEventProperty.DEALER_GUID]: product.getDealerGuid(),
        [AmplitudeEventProperty.IS_NEWLY_MADE]: product.getIsNewlyMade(),
        [AmplitudeEventProperty.IS_VARIANT]: product.getIsVariant(),
        [AmplitudeEventProperty.PRODUCT_ID]: product.getId(),
        [AmplitudeEventProperty.PRODUCT_TAXONOMY]: product.getTaxonomy(),
        [AmplitudeEventProperty.PRODUCT_PRICE]: product.getPrice(),
        [AmplitudeEventProperty.SHIPPING_COST]: product.getShippingCost(),
        [AmplitudeEventProperty.SHIPPING_DISTANCE]: product.getShippingDistance(),
        [AmplitudeEventProperty.SHIPPING_PARTNER_CODE]: product.getShippingPartnerCode(),
    };
}

/**
 * Tracks individual product impressions.
 *
 * @param {ProductModel} product
 * @param {string=} position Optional position where this product was displayed
 */
export function logAmplitudeProductImpressionEvent(product, position = null) {
    logAmplitudeEvent('product - impression', {
        ...getAmplitudeEventPropertiesForProduct(product),
        [AmplitudeEventProperty.POSITION]: position,
    });
}

/**
 * Uses the product and socialMediaType for tracking of Social Media sharing.
 *
 * @param {ProductModel} product the product to track
 * @param {TrackingSocialMediaPlatform} socialMediaType
 * @param {string} ctaUrl
 * @param {string} eventName
 */
export function logSocialShareEvent(product, socialMediaType, ctaUrl, eventName = 'social share') {
    const eventProperties = {
        ...getAmplitudeEventPropertiesForProduct(product),
        [AmplitudeEventProperty.TYPE]: socialMediaType,
        [AmplitudeEventProperty.POSITION]: PDPSection.SOCIAL_BAR,
        [AmplitudeEventProperty.CTA_URL]: ctaUrl,
    };

    logAmplitudeEvent(eventName, eventProperties);
    GoogleAnalyticsUtils.logEvent(eventName, eventProperties);
}

/**
 * Uses the product and socialMediaType for tracking of Social Media sharing.
 *
 * @param {ProductModel} product the product to track
 * @param {TrackingSocialMediaPlatform} socialMediaType
 * @param {string} ctaUrl
 */
export function logSocialShareAbandonEvent(product, socialMediaType, ctaUrl) {
    logSocialShareEvent(product, socialMediaType, ctaUrl, 'social share - abandon');
}

/**
 * Uses the product and socialMediaType for tracking of Social Media sharing.
 *
 * @param {ProductModel} product the product to track
 * @param {TrackingSocialMediaPlatform} socialMediaType
 * @param {string} ctaUrl
 */
export function logSocialShareErrorEvent(product, socialMediaType, ctaUrl) {
    logSocialShareEvent(product, socialMediaType, ctaUrl, 'social share - error');
}

/**
 * Tracks the hover or click event for the sustainability message
 *
 * @param {ProductModel} product the product
 */
export function logSustainabilityShowEvent(product) {
    const amplitudeObject = getAmplitudeEventPropertiesForProduct(product);
    logAmplitudeEvent('sustainability message - show', amplitudeObject);
}

/**
 * Tracks favoriting and unfavoriting.
 *
 * @param {string} action
 * @param {string} label
 * @param {ProductModel} product
 */
export function trackFavoritingEvent(action, label, product) {
    if (action === 'favorite') {
        const productId = product.getId();

        const eventProperties = {
            ...getAmplitudeEventPropertiesForProduct(product),
            [AmplitudeEventProperty.POSITION]: label,
        };
        const eventName = 'favorite - create';

        logAmplitudeEvent(eventName, eventProperties);
        GoogleAnalyticsUtils.logEvent(eventName, eventProperties);

        trackFavoriteCreatedForPRT({
            title: product.getTitle(),
            id: productId,
            price: product.getPrice(),
        });
    }
}

/**
 * Tracks impressions and clicks for a list of products.
 *
 * @param {Array.<ProductModel>} products The products displayed
 * @param {string=} listSelector The selector for the containing element of a product list
 * @param {string=} productSelector The selector for the containing element of an individual product
 * @param {string=} linkSelector The selector of a product link within the product element.
 *     Defaults to the product selector if not set.
 */
export function trackProductList(
    products,
    listSelector,
    productSelector = '.product',
    linkSelector = '.js-product-link',
) {
    const $listContainer = $(listSelector);

    $listContainer.on('click', linkSelector, ({ currentTarget }) => {
        const $link = $(currentTarget);
        const $productEl = $link.closest(productSelector);
        const productId = $productEl.data('product-id');
        const product = products.find((productModel) => productModel.id === productId);

        logAmplitudeEvent('product - click', {
            ...getAmplitudeEventPropertiesForProduct(product),
            [AmplitudeEventProperty.IS_HOUSE_AD]: $productEl.hasClass(HOUSE_AD_CLASS),
        });
    });
}

/**
 * Tracks impressions and clicks for a list of products.
 *
 * @param {string} listSelector The selector for the containing element of a product list
 * @param {string=} productSelector The selector for the containing element of an individual product
 * @param {string=} linkSelector The selector of a product link within the product element.
 *     Defaults to the product selector if not set.
 */
export function trackProductList2({
    listSelector,
    productSelector = '.js-product',
    linkSelector = '.js-product-link',
} = {}) {
    const $listContainer = $(listSelector);

    $listContainer.on('click', linkSelector, ({ currentTarget }) => {
        const $link = $(currentTarget);
        const $productEl = $link.closest(productSelector);
        const productId = $productEl.data('product-id');
        const productObj = ProductContext.getProductObjectById(productId);

        if (productObj) {
            const productModel = new ProductModel(productObj);
            logAmplitudeEvent('product - click', {
                ...getAmplitudeEventPropertiesForProduct(productModel),
                [AmplitudeEventProperty.IS_HOUSE_AD]: $productEl.hasClass(HOUSE_AD_CLASS),
            });
        }
    });
}
