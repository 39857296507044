import $ from 'jquery';
import Modernizr from 'chairisher/util/modernizr';

const ChairishContext = window.chairisher.context;

export function prefersReducedMotion() {
    return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
}

const MediaQueryUtils = {
    matchesCurrentMedia(conditionString) {
        return conditionString ? Modernizr.mq(conditionString) : true;
    },
    isDesktopUserAgent() {
        return ChairishContext.IS_DESKTOP_USER_AGENT;
    },
    isMaxPhone() {
        return Modernizr.mq('(max-width: 480px)');
    },
    isMaxTablet() {
        return Modernizr.mq('(max-width: 767px)');
    },
    isMinMediumDesktop() {
        return Modernizr.mq('(min-width: 768px)');
    },
    isMaxMediumDesktop() {
        return Modernizr.mq('(max-width: 991px)');
    },
    isMinLargeDesktop() {
        return Modernizr.mq('(min-width: 992px)');
    },
    isTouchDevice() {
        return $('html').hasClass('touchevents');
    },
};

export default MediaQueryUtils;
