const ChairishContext = window.chairisher.context;

/**
 * A simple interface that provides access to documentation and variables relating to offers
 */
const OfferContext = {
    /**
     * @returns {OfferModel}
     */
    getOffer() {
        return ChairishContext.offer;
    },
};

export default OfferContext;
