import $ from 'jquery';

import AlerterView from 'chairisher/view/alerter';
import ModalFactory from 'chairisher/factory/modal';
import TooltipHelper from 'chairisher/view/helper/tooltip';

import {
    trackTradeRewardsDashboardLinkClick,
    trackTradeRewardsRedemptionError,
    trackTradeRewardsRedemptionLinkClick,
    trackTradeRewardsRedemptionSuccess,
} from 'chairisher/analytics/trade';
import { getTradeRewardsRedemptionUrl } from 'chairisher/context/trade';

/**
 * View that encapsulates behavior for the Trade Rewards dashboard.
 *
 * @param {jQuery} $el Instance of a jQuery element representing the dashboard
 */
class TradeRewardsDashboardView {
    constructor($el) {
        /**
         * Instance of a jQuery element representing the dashboard
         *
         * @type {jQuery}
         */
        this.$el = $el;

        /**
         * Selector used to find elements in the DOM that trigger the company info action
         *
         * @type {string}
         * @default
         */
        this.companyInfoActionSelector = '.js-trade-rewards-company-info-action';

        /**
         * Selector used to select the trade rewards company modal from the DOM
         *
         * @type {string}
         * @default
         */
        this.companyModalSelector = '#js-trade-rewards-company-modal';

        /**
         * Instance of a jQuery element for the redemption modal
         *
         * @type {jQuery|null}
         */
        this.$modal = null;

        /**
         * Selector used to find elements in the DOM that trigger the redemption action
         *
         * @type {string}
         * @default
         */
        this.redemptionActionSelector = '.js-trade-rewards-redemption-action';

        /**
         * Id to use when creating a modal to redeem trade rewards
         *
         * @type {string}
         * @default
         */
        this.rewardsModalId = 'js-trade-rewards-redemption-modal';

        this.bind();
    }

    /**
     * @returns {jQuery} The modal that houses various trade rewards related screens
     */
    getModal(html) {
        if (!this.$modal) {
            this.$modal = ModalFactory.createModal(this.rewardsModalId);
            this.$modal.find('.js-title').remove();

            $(document.body).append(this.$modal);

            this.$modal.on('submit', 'form', (e) => {
                e.preventDefault();
                const $form = $(e.currentTarget);
                this.submitRedemption({
                    url: $form.attr('action'),
                    method: $form.attr('method'),
                    data: $form.serialize(),
                });
            });

            this.$modal.on('click', '.js-close', (e) => {
                e.preventDefault();
                this.$modal.modal('hide');
            });
        }

        this.$modal.find('.js-modal-body').html(html);

        return this.$modal;
    }

    /**
     * Requests the Trade Rewards redemption modal and populates this.$modal with its contents
     *
     * @returns {$.Deferred} Deferred to chain on when the request is complete
     */
    requestRedemptionForm() {
        return $.ajax({
            url: getTradeRewardsRedemptionUrl(),
        })
            .done((data) => {
                this.getModal(data.html).modal();
            })
            .fail((jqXHR) => {
                if (jqXHR.responseJSON.errors) {
                    const { errors } = jqXHR.responseJSON;
                    for (let i = 0; i < errors.length; i += 1) {
                        AlerterView.error(errors[i].message);
                    }
                }
            });
    }

    /**
     * If the current user is a part of a company, this shows the modal listing all other company members
     */
    showCompanyModal() {
        $(this.companyModalSelector).modal('show');
    }

    /**
     * Submits a redemption request
     *
     * @param {Object} settings The settings object to pass into $.ajax()
     * @returns {$.Deferred} Deferred to chain on when the request is complete
     */
    submitRedemption(settings) {
        return $.ajax(settings)
            .done((data) => {
                this.getModal(data.html).modal();

                if (data.is_redemption_complete) {
                    this.$el.find('.js-trade-rewards-redemption').html('');
                    trackTradeRewardsRedemptionSuccess();
                }
            })
            .fail((jqXHR) => {
                if (jqXHR.responseJSON.errors) {
                    const { errors } = jqXHR.responseJSON;
                    for (let i = 0; i < errors.length; i += 1) {
                        AlerterView.error(errors[i].message);
                    }
                    trackTradeRewardsRedemptionError();
                }
            });
    }

    /**
     * Binds various actions to the view's elements
     */
    bind() {
        //
        // Display Company Modal
        //

        $(this.companyInfoActionSelector).on('click', (e) => {
            e.preventDefault();
            this.showCompanyModal();
            trackTradeRewardsDashboardLinkClick(e);
        });

        //
        // Trade Rewards Redemption
        //

        $(this.redemptionActionSelector).on('click', (e) => {
            e.preventDefault();
            this.requestRedemptionForm();
            trackTradeRewardsRedemptionLinkClick(e);
        });

        //
        // Trade Rewards Tooltip
        //

        const $tradeRewardsTooltip = $('.js-trade-rewards-tooltip');
        $tradeRewardsTooltip.tooltip({ placement: TooltipHelper.getPlacement });
    }
}

export default TradeRewardsDashboardView;
