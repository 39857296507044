import { AmplitudeEventProperty, logAmplitudeEvent } from 'chairisher/util/analytics';

/**
 * @enum {string}
 */
export const InteractionType = {
    DISABLE: 'disable',
    PAUSE: 'pause',
    START: 'start',
};

/**
 * @enum {string}
 */
export const NavigationType = {
    ARROW: 'arrow',
    INDICATOR: 'indicator',
    SCROLL: 'scroll',
};

/**
 * @enum {string}
 */
export const NavigationValue = {
    NEXT: 'next',
    PREVIOUS: 'previous',
};

/**
 * Tracks a carousel-specific event
 *
 * @param {string} eventType The type of carousel event to track
 * @param {string} eventPosition The position at which the carousel event is tracked
 * @param {object=} eventProperties Optional additional event properties to include
 */
function trackCarouselEvent(eventType, eventPosition, eventProperties = {}) {
    logAmplitudeEvent(`carousel - ${eventType}`, {
        [AmplitudeEventProperty.POSITION]: eventPosition,
        ...eventProperties,
    });
}

/**
 * Tracks when a carousel slide is in the viewport
 *
 * @param {string} position The position at which the impression is tracked
 * @param {number} slideIndex The 0-based index of the displayed slide
 */
export function trackCarouselImpression(position, slideIndex) {
    trackCarouselEvent('impression', position, {
        'slide index': slideIndex,
    });
}

/**
 * Tracks when a user interacts with the carousel's behavior
 *
 * @param {string} position The position at which the interaction is tracked
 * @param {string} type The type of interaction that occurred
 */
export function trackCarouselInteraction(position, type) {
    trackCarouselEvent('interaction', position, { 'interaction type': type });
}

/**
 * Tracks when a carousel link is clicked
 *
 * @param {string} position The position at which the link click is tracked
 * @param {string} name The name of the link element that was clicked
 * @param {string} url The url of the link element that was clicked
 * @param {number} slideIndex The 0-based index of the slide associated with the link click
 */
export function trackCarouselLinkClick(position, name, url, slideIndex) {
    trackCarouselEvent('link click', position, {
        [AmplitudeEventProperty.CTA_NAME]: name,
        [AmplitudeEventProperty.CTA_URL]: url,
        'slide index': slideIndex,
    });
}

/**
 * Tracks when the carousel is navigated
 *
 * @param {string} position The position at which the navigation is tracked
 * @param {string} type The type of navigational means; e.g. arrow, indicator, scroll
 * @param {string} value The value of the navigation; e.g. next, previous, 0
 * @param {number} targetIndex The 0-based index of the targeted slide to be displayed
 */
export function trackCarouselNavigation(position, type, value, targetIndex) {
    trackCarouselEvent('navigation', position, { 'navigation type': type, value, 'target index': targetIndex });
}
