import UriUtils from 'chairisher/util/uri';

const ChairishContext = window.chairisher.context;
const Adwords = ChairishContext.adwords;

/**
 * @returns {Object} The account data url search params set in _js_controller_call.html
 */
export function getAccountDataURLSearchParams() {
    return ChairishContext.accountDataURLSearchParams;
}

/**
 * @returns {string} A URL that is used for registering a user interaction.
 */
export function getRegisterInteractionUrl() {
    return ChairishContext.REGISTER_INTERACTION_URL;
}

/**
 * @returns {string} The API key to use with Amplitude
 */
export function getAmplitudeApiKey() {
    return ChairishContext.AMPLITUDE_API_KEY;
}

/**
 * @returns {Object} The global object that stores remarketing data
 */
export function getRemarketingObject() {
    return ChairishContext.RemarketingObject;
}

/**
 * Caches off the various utm_* params into the Chairish context
 */
export function storeUtmParams() {
    ChairishContext.utm = {
        utm_campaign: UriUtils.extractParamFromUri(document.location.search, 'utm_campaign'),
        utm_medium: UriUtils.extractParamFromUri(document.location.search, 'utm_medium'),
        utm_source: UriUtils.extractParamFromUri(document.location.search, 'utm_source'),
    };
}

/**
 * @returns {string} The URL hash if any
 */
export function getUrlHash() {
    return ChairishContext.urlHash || '';
}

/**
 * Stores the URL hash for later use
 */
export function storeUrlHash() {
    ChairishContext.urlHash = document.location.hash;
}

/**
 * @returns {Object} The various utm_* params stored in the Chairish context
 */
export function getUtmParams() {
    return ChairishContext.utm || {};
}

/**
 * @returns {string|undefined} The utm_campaign param for the current page, if any
 */
export function getUtmCampaign() {
    return getUtmParams().utm_campaign;
}

/**
 * @returns {string|undefined} The utm_medium param for the current page, if any
 */
export function getUtmMedium() {
    return getUtmParams().utm_medium;
}

/**
 * @returns {string|undefined} The utm_source param for the current page, if any
 */
export function getUtmSource() {
    return getUtmParams().utm_source;
}

/**
 * @returns {string|undefined} The Chairish account id for adwords enhanced conversions
 */
export function getAdwordsEnhancedConversionAccount() {
    return ChairishContext.ADWORDS_ENHANCED_CONVERSION_ACCOUNT;
}

/**
 * @returns {string|undefined} The label for adwords enhanced conversions for offers
 */
export function getAdwordsEnhancedConversionLabelOffer() {
    return ChairishContext.ADWORDS_ENHANCED_CONVERSION_LABEL_OFFER;
}

/**
 * @returns {string|undefined} The label for adwords enhanced conversions for orders
 */
export function getAdwordsEnhancedConversionLabelOrder() {
    return ChairishContext.ADWORDS_ENHANCED_CONVERSION_LABEL_ORDER;
}

/**
 * @returns {string} Label used to track an offer created event in adwords
 */
export function getAdwordsOfferCreatedEventLabel() {
    return Adwords.OFFER_CREATED_EVENT_LABEL;
}

/**
 * @returns {string} Label used to track an order created event in adwords
 */
export function getAdwordsOrderCreatedEventLabel() {
    return Adwords.ORDER_CREATED_EVENT_LABEL;
}

/**
 * @returns {number}
 */
export function getGoogleConversionId() {
    return ChairishContext.GOOGLE_CONVERSION_ID;
}

/**
 * @returns {string}
 */
export function getTrackingScreenModifier() {
    return ChairishContext.TRACKING_SCREEN_MODIFIER;
}

/**
 * @returns {string}
 */
export function getPinterestConversionUrl() {
    return ChairishContext.PINTEREST_CONVERSION_URL;
}

/**
 * Purges amplitude test tags to prevent double-tagging.
 */
export function purgeAmplitudeTestTags() {
    ChairishContext.AMPLITUDE_TEST_TAGS = [];
}
