const ChairishContext = window.chairisher.context;

/**
 * An interface that provides documentation and access to context variables related to products and listings
 */
const ProductContext = {
    UNIT_TYPE_SAMPLE: 'SA',

    /**
     * @returns {boolean} If private messaging is enabled
     */
    getAreMessagesEnabled() {
        return ChairishContext.ARE_MESSAGES_ENABLED;
    },

    /**
     * @returns {string} The spotlight type code indicating a product is boosted
     */
    getBoostSpotlightTypeCode() {
        return ChairishContext.BOOST_SPOTLIGHT_TYPE_CODE;
    },

    /**
     * @returns {Array.<string>} The spotlight type codes used for quoss assignments
     */
    getQuossAssignmentSpotlightTypeCodes() {
        return ChairishContext.QUOSS_ASSIGNMENT_SPOTLIGHT_TYPE_CODES;
    },

    /**
     * @returns {string} URL used to retrieve the "More Like This" grid/rail
     */
    getMoreLikeThisUrl() {
        return ChairishContext.MLT_URL;
    },

    /**
     * @param {string} addressType The order item address type
     * @returns {string|undefined} The display text for the given address type
     */
    getOrderItemAddressType(addressType) {
        return ChairishContext.ORDER_ITEM_ADDRESS_TYPES_JSON[addressType];
    },

    /**
     * @returns {string} URL used to retrieve Product Data
     */
    getProductDataUrl() {
        return ChairishContext.PRODUCT_DATA_URL;
    },

    /**
     * @returns {string} URL used to retrieve the "Product Line" grid/rail
     */
    getProductLineUrl() {
        return ChairishContext.PRODUCT_LINE_URL;
    },

    /**
     * @returns {string} URL used to retrieve the "Tastemaker" grid/rail
     */
    getTastemakerUrl() {
        return ChairishContext.TASTEMAKER_URL;
    },

    /**
     * @returns {string} URL used to retrieve the "You Might Also Like" grid/rail
     */
    getYmalUrl() {
        return ChairishContext.YMAL_URL;
    },

    /**
     * @param {number} orderItemId The id of the order item to get the URL for
     * @returns {string|undefined} The url of the You May Also Like endpoint, if any
     */
    getYmalOrderItemUrl(orderItemId) {
        return ChairishContext.ORDER_ITEM_ID_TO_YMAL_URL_JSON
            ? ChairishContext.ORDER_ITEM_ID_TO_YMAL_URL_JSON[orderItemId]
            : undefined;
    },

    /**
     * @returns {string} URL used to retrieve the "Recently Viewed" grid/rail
     */
    getRecentlyViewedUrl() {
        return ChairishContext.RECENTLY_VIEWED_URL;
    },

    /**
     * @returns {boolean} True indicates favorite hearts can be rendered on this page
     */
    canRenderFavoriteHearts() {
        return ChairishContext.CAN_RENDER_FAVORITE_HEARTS !== false;
    },

    /**
     * @returns {boolean} True indicates folder icons can be rendered on this page
     */
    canRenderFolderIcons() {
        return ChairishContext.CAN_RENDER_FOLDER_ICONS !== false;
    },

    /**
     * @param {Array=} filterByProductIds Optional list of product ids to filter the product search results by
     * @param {Boolean=} shouldSearchSku Optional boolean indicating if we should also search by SKU
     * @returns {string} The endpoint to hit when autocompleting a product query
     */
    getAutocompleteEndpoint(filterByProductIds = null, shouldSearchSku = false) {
        let url = ChairishContext.PRODUCT_LOOKUP_URL;
        if (filterByProductIds || shouldSearchSku) {
            const params = new URLSearchParams();
            params.set('should_search_sku', shouldSearchSku);

            if (filterByProductIds && filterByProductIds.length > 0) {
                params.set('filter_by_product_ids', filterByProductIds);
            }

            url = `${url}?${params}`;
        }
        return url;
    },

    /**
     * @returns {string} The guid of the dealer selling the product
     */
    getDealerGuid() {
        return ChairishContext.DEALER_GUID;
    },

    /**
     * @returns {number} The total number of favorites the current user has
     */
    getFavoriteCount() {
        return ChairishContext.FAVORITES_COUNT || 0;
    },

    /**
     * @param {number} productId ID of the product to favorite
     * @returns {string} URL to use to favorite the product
     */
    getFavoriteCreateUrl(productId) {
        return `/product/${productId}/favorite/create`;
    },

    /**
     * @param {number} productId ID of the product to unfavorite
     * @returns {string} URL to use to unfavorite the product
     */
    getFavoriteDeleteUrl(productId) {
        return `/product/${productId}/favorite/delete`;
    },

    /**
     * @returns {string} The URL used to determine which products are favorited by the current user
     */
    getFavoriteLookupUrl() {
        return ChairishContext.FAVORITE_LOOKUP_URL;
    },

    /**
     * @returns {Array.<number>} Collection of product ids that are in folders
     */
    getFolderedProductIds() {
        return ChairishContext.FOLDERED_PRODUCT_IDS || [];
    },

    /**
     * @returns {number|null} The max number of products that can be curated
     */
    getMaxCurationProducts() {
        return ChairishContext.MAX_CURATION_PRODUCTS || null;
    },

    /**
     * @returns {string} The `sizes` attribute for images displayed in the product grid
     */
    getProductGridImageSizes() {
        return ChairishContext.PRODUCT_GRID_IMAGE_SIZES;
    },

    /**
     * @returns {Object} The object containing data about the embedded product
     */
    getProductObject() {
        return ChairishContext.PRODUCT || {};
    },

    /**
     * @param {number} id The id of the product to look for
     * @returns {Object|undefined} The product object with the given id that is embedded in the JS context
     */
    getProductObjectById(id) {
        const productObj = ProductContext.getProductIdToProductObjMap()[id];
        if (productObj) {
            return productObj;
        }

        // todo (CHAIR-14874): remove next line when all clients are gone
        return (ProductContext.getProductObjectArray() || []).find((productObject) => productObject.id === id);
    },

    /**
     * @returns {Object} Maping of product id to product object
     */
    getProductIdToProductObjMap() {
        return ChairishContext.PRODUCT_ID_TO_PRODUCT_JSON_MAP || {};
    },

    /**
     * @returns {Array.<Object>} The array containing multiple objects that contain data about the embedded products
     */
    getProductObjectArray() {
        return ChairishContext.PRODUCTS;
    },

    /**
     * @param {string} key The object key or name of the product field to look for
     * @returns {string|array|null} The value of the field, if any
     */
    getProductObjectValueByKey(key) {
        const productObj = ProductContext.getProductObject();
        return productObj && productObj[key] && productObj[key].value ? productObj[key].value : null;
    },

    /**
     * @returns {number} The id of the product being displayed
     */
    getProductId() {
        return ChairishContext.PRODUCT_ID;
    },

    /**
     * @returns {string} the seller type for the owner of the product
     */
    getProductSellerType() {
        return ChairishContext.PRODUCT_SELLER_TYPE;
    },

    /**
     * @returns {Object} Mapping of spotlight type code to CSS icon class
     */
    getSpotlightTypesIconMap() {
        return ChairishContext.spotlight_types_icon_map;
    },

    /**
     * @returns {Object} Datastructure of spotlight tag choices in tree format
     */
    getSpotlightTypesChoiceTree() {
        return ChairishContext.SPOTLIGHT_TYPES_CHOICE_TREE;
    },

    /**
     * @returns {string} URL to use to search for spotlight tag choices
     */
    getSpotlightTypesLookupUrl() {
        return ChairishContext.SPOTLIGHT_TYPES_LOOKUP_URL;
    },

    /**
     * @param {number} variantId Id of the variant for which to retrieve data
     * @return {Object.<String, String>|undefined} Relevant data for the variant (e.g., price, title, dimensions)
     */
    getDataForVariantId(variantId) {
        return (ChairishContext.variantIdToVariantDataMap || {})[variantId];
    },

    /**
     *
     * @returns Object containing all variant options
     */
    getVariantChoices() {
        return ChairishContext.variantOptions;
    },

    /**
     * @param {number} productId The product id to check
     * @returns {boolean} True indicates the product is in a folder, false otherwise
     */
    isProductIdInFolder(productId) {
        return ProductContext.getFolderedProductIds().includes(productId);
    },

    /**
     * @returns {boolean} True indicates the product has a sample as one of their variants, false otherwise
     */
    isSampleAvailable() {
        const variantIdToVariantDataMap = ChairishContext.variantIdToVariantDataMap || {};
        return !!Object.keys(variantIdToVariantDataMap).find(
            (key) => variantIdToVariantDataMap[key].unit_type === ProductContext.UNIT_TYPE_SAMPLE,
        );
    },

    /**
     * @param {Array.<number>} folderedProductIds Collection of product ids that are in folders
     */
    setFolderedProductIds(folderedProductIds) {
        ChairishContext.FOLDERED_PRODUCT_IDS = folderedProductIds;
    },

    /**
     * @param {Object} newProductIdToProductObjMap The new mapping to overwrite the old mapping with
     */
    setProductIdToProductObjMap(newProductIdToProductObjMap) {
        ChairishContext.PRODUCT_ID_TO_PRODUCT_JSON_MAP = newProductIdToProductObjMap;
    },

    /**
     * @returns {string} A URL key that indicates whether the user was redirected from the offer create page.
     */
    getMaoRedirectKey() {
        return ChairishContext.MAO_REDIRECT_KEY;
    },

    /**
     * Updates the global data structure to indicate whether or not a product is "foldered"
     *
     * @param {number} productId The product id to update
     * @param {boolean} isFoldered True indicates the product is "foldered", false otherwise
     */
    updateFolderedProductId(productId, isFoldered) {
        const index = ChairishContext.FOLDERED_PRODUCT_IDS.indexOf(productId);

        if (index === -1 && isFoldered) {
            ChairishContext.FOLDERED_PRODUCT_IDS.push(productId);
        } else if (index !== -1 && !isFoldered) {
            ChairishContext.FOLDERED_PRODUCT_IDS.splice(index, 1);
        }
    },

    /**
     * Updates a given product object in the JS context
     *
     * @param {Object} productObj
     */
    updateProductObject(productObj) {
        const map = ProductContext.getProductIdToProductObjMap();
        map[productObj.id] = productObj;
        ProductContext.setProductIdToProductObjMap(map);
    },

    /**
     * @param {Object} newProductIdToProductObjMap The new mapping to update the old mapping with
     */
    updateProductIdToProductObjMap(newProductIdToProductObjMap) {
        ChairishContext.PRODUCT_ID_TO_PRODUCT_JSON_MAP = {
            ...ProductContext.getProductIdToProductObjMap(),
            ...newProductIdToProductObjMap,
        };
    },

    /**
     * Updates the list of products in context.
     * Either updates or pushes to the list depending on if the product is already present.
     *
     * @param {Array.<ProductModel>} products The products
     */
    updateProductObjectArray(products) {
        products.forEach((newProduct) => {
            const existingProduct = ProductContext.getProductObjectById(newProduct.id);
            if (existingProduct) {
                const indexToReplace = ChairishContext.PRODUCTS.indexOf(existingProduct);
                ChairishContext.PRODUCTS[indexToReplace] = newProduct;
            } else {
                ChairishContext.PRODUCTS.push(newProduct);
            }
        });
    },
};

export default ProductContext;
