import $ from 'jquery';

import { AmplitudeEventProperty, logAmplitudeEvent, logAmplitudeLinkClickEvent } from 'chairisher/util/analytics';

/**
 * Tracks when one of the rail arrows is clicked
 *
 * @param {string=} position The position of the rail
 * @param {string=} subtype The sub-type of the rail
 * @param {string=} type The type of the rail
 * @param {string=} value The value of the arrow click, e.g. next or previous
 */
export function trackArrowClick({ position = null, subtype = null, type = null, value = null }) {
    logAmplitudeEvent('product rail - arrow click', {
        [AmplitudeEventProperty.POSITION]: position,
        [AmplitudeEventProperty.SUB_TYPE]: subtype,
        [AmplitudeEventProperty.TYPE]: type,
        [AmplitudeEventProperty.VALUE]: value,
    });
}

/**
 * Tracks when one of the rail links is clicked
 *
 * @param {Event} e The link click event to track
 * @param {boolean=} isProduct Whether the link clicked was on a product
 * @param {string=} type The type of the rail
 */
export function trackRailLinkClick({ e, isProduct = false, type = null }) {
    const $link = $(e.currentTarget);
    const linkText = isProduct ? $link.closest('[data-product-title]').data('product-title') : $link.text();

    logAmplitudeLinkClickEvent(linkText.toLowerCase().trim(), $link.attr('href'), type);
}

/**
 * Tracks when the rail is viewed
 *
 * @param {string=} position The position of the rail
 * @param {string=} subtype The sub-type of the rail
 * @param {string=} type The type of the rail
 */
export function trackRailView({ position = null, subtype = null, type = null }) {
    logAmplitudeEvent('product rail - view', {
        [AmplitudeEventProperty.POSITION]: position,
        [AmplitudeEventProperty.SUB_TYPE]: subtype,
        [AmplitudeEventProperty.TYPE]: type,
    });
}
