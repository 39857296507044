import $ from 'jquery';

/**
 * @return {boolean} Whether the device supports IntersectionObserver (old iOS versions do not)
 */
export function hasIntersectionObserver() {
    return 'IntersectionObserver' in window;
}

/**
 * Helper function that creates an IntersectionObserver for one or more jQuery elements
 *
 * @param {jQuery|Array.<jQuery>} $els The element(s) to observe
 * @param {function} callback The callback to execute when the entry is intersecting
 * @param {Object=} settings The collection of options to pass into the IntersectionObserver
 * @returns {IntersectionObserver|null} The observer, or null if the client doesn't support it.
 */
export function observeIntersectionForEls($els, callback, settings = {}) {
    if (!hasIntersectionObserver()) {
        // Rather than execute the callback, just return null, since the callback usually sends amplitude events.
        return null;
    }

    let observer;

    const shouldObserveOnce = !!settings.should_observe_once;
    const nativeOptions = { ...settings };
    // remove so this doesn't get sent into the native IntersectionObserver options
    delete nativeOptions.should_observe_once;

    if ($els.length) {
        observer = new IntersectionObserver((entries, o) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (shouldObserveOnce) {
                        o.unobserve(entry.target);
                    }
                    callback(entry, o);
                }
            });
        }, nativeOptions);

        if (Array.isArray($els)) {
            // array of jQuery elements (e.g. [$('<p></p>')])
            for (let i = 0; i < $els.length; i += 1) {
                observer.observe($els[i].get(0));
            }
        } else if ($els instanceof $) {
            // one jQuery object with multiple jQuery elements (e.g. $('p'))
            for (let i = 0; i < $els.length; i += 1) {
                observer.observe($els.get(i));
            }
        }
    }

    return observer;
}

/**
 * Helper function that creates an IntersectionObserver and unobserves after the first intersection
 *
 * @param {string} selector The selector of the element(s) to observe
 * @param {function} callback The callback to execute when the entry is intersecting
 * @param {Object=} settings Optional settings for the observer
 * @returns {IntersectionObserver}
 */
export function observeIntersectionOnce(selector, callback, settings = {}) {
    return observeIntersectionForEls($(selector), callback, {
        should_observe_once: true,
        ...settings,
    });
}

/**
 * Helper function that creates an IntersectionObserver and unobserves after the first intersection
 *
 * @param {jQuery|Array.<jQuery>} $els The element(s) to observe
 * @param {function} callback The callback to execute when the entry is intersecting
 * @param {Object=} settings Optional settings for the observer
 * @returns {IntersectionObserver}
 */
export function observeIntersectionForElsOnce($els, callback, settings) {
    return observeIntersectionForEls($els, callback, {
        should_observe_once: true,
        ...settings,
    });
}
