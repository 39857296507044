import $ from 'jquery';

/**
 * A component that manages the the flow of data into a form field (see example for expected HTML structure).
 *
 * Complex JS components that are used in forms (like autocompletes, imagefields, etc.) should inherit
 * from this component to ensure a consistent API across form elements.
 *
 * @param {Object} settings
 * @param {jQuery} settings.$el The jQuery element backing the FormFieldComponent
 * @param {Object} settings.data The data used to render the DOM
 * @param {string} settings.name The name of the field that this FormFieldComponent is for
 *
 * @class FormFieldComponent
 * @constructs FormFieldComponent
 *
 * @example
 * <div class="form-group js-form-group email">
 *   <label class="control-label" for="id_email">Email Address</label>
 *   <div class="controls">
 *     <input type="email" name="email" placeholder="Email Address" class="form-control" id="id_email" autocomplete="email" autocorrect="off" required="">
 *   </div>
 *   <div class="help-block js-errors"></div>
 * </div>
 */
const FormFieldComponent = function (settings) {
    $.extend(
        {
            $el: this._$el,
            data: {},
            name: this._name,
        },
        settings,
    );

    this._$el = settings.$el;
    this._$groupEl = this._$el.closest('.js-form-group');
    this._name = settings.name;

    settings.data._name = this._name;
    this._data = settings.data;

    this._bind();
};

/**
 * Marshals a list of messages into a jQuery object that can be displayed.
 *
 * @param {Array.<Message>} data The list of messages to marshal
 * @param {string=} opt_itemClasses Optional string to use as classes on each error list item
 *
 * @returns {jQuery} The rendered message list
 */
FormFieldComponent.marshalMessageList = function (data, opt_itemClasses) {
    const $shell = $('<ul></ul>', { class: 'errorlist' });
    for (let i = 0; i < data.length; i++) {
        $shell.append(
            $('<li></li>', {
                class: opt_itemClasses,
                text: data[i].display,
            }),
        );
    }

    return $shell.children().length ? $shell : '';
};

/**
 * The jQuery element that this data is tied to
 *
 * @type {jQuery}
 * @private
 */
FormFieldComponent.prototype._$el = null;

/**
 * The .js-form-group element wrapping the field
 *
 * @type {jQuery}
 * @private
 */
FormFieldComponent.prototype._$groupEl = null;

/**
 * Object that contains all of the data needed to render this field
 *
 * @type {Object}
 * @private
 * @example
 * {
 *   _name: 'shipping_type_code', // private value useful for debugging
 *   choices: [
 *     {value: 'F'},
 *     {value: 'W'},
 *     {value: 'S'},
 *     {display: '$5-$33', value: 'F'},
 *   ],
 *   errors: [
 *      {'display': 'This field is required.'}
 *   ],
 *   is_readonly: false, // absence of this field means it is not read-only
 *   is_required: true,   // absence of this field means it is not required
 *   label: 'Shipping Options', // custom label to display; supersedes  what is set in a Django form
 *   suggestion: {value: 'F'}, // used to indicate this is the preferred value; only present when choices is present
 *   value: 'S', // can also be an array depending on the field; value: [{value: 'F'}, {value: 'W'}]
 * }
 */
FormFieldComponent.prototype._data = null;

/**
 * The name of the form field
 *
 * @type {string}
 * @private
 */
FormFieldComponent.prototype._name = '';

/**
 * Clears all errors from the field
 */
FormFieldComponent.prototype.clearErrors = function () {
    this.setErrors([]);
};

/**
 * @returns {string} The display text (if any) or the actual value
 */
FormFieldComponent.prototype.getDisplayValue = function () {
    return this._data.display || this._data.value || '';
};

/**
 * @returns {Array.<Object>} Collection of error objects
 */
FormFieldComponent.prototype.getErrors = function () {
    return this._data.errors || [];
};

/**
 * @returns {jQuery} The jQuery element representing this form field
 */
FormFieldComponent.prototype.getEl = function () {
    return this._$el;
};

/**
 * @returns {string} The name of the form field
 */
FormFieldComponent.prototype.getName = function () {
    return this._name;
};

/**
 * @returns {Array|number|Object|string} The field's value
 */
FormFieldComponent.prototype.getValue = function () {
    return this._data.value;
};

/**
 * @returns {boolean} True indicates the field has at least one error
 */
FormFieldComponent.prototype.hasErrors = function () {
    return this.getErrors().length;
};

/**
 * @returns {boolean} True indicates this field is required
 */
FormFieldComponent.prototype.isRequired = function () {
    return !!this._data.is_required;
};

/**
 * @returns {boolean} True indicates this field is error free and has a value, or is error free and
 *   doesn't require a value. Complex form fields should override this function if needed.
 */
FormFieldComponent.prototype.isValid = function () {
    return !this.hasErrors() && (this.getValue() != null || !this.isRequired());
};

/**
 * Renders the form field's choices; defaults to rendering new <option> elements.
 * Complex form fields should override this function if needed.
 */
FormFieldComponent.prototype.renderChoices = function () {
    const { choices } = this._data;
    if (choices) {
        const choiceList = [];
        $.each(choices, (i, el) => {
            const choice = choices[i];
            choiceList.push(
                $('<option></option>', {
                    text: choice.display || choice.value,
                    value: choice.value,
                }),
            );
        });
        this._$el.html(choiceList);
    }
};

/**
 * Renders all of the form field's errors.
 * Complex form fields should override this function if needed.
 *
 * @param {Array.<Object>=} Optional collection of specific errors to render
 */
FormFieldComponent.prototype.renderErrors = function (opt_errors) {
    const errors = opt_errors || this.getErrors();
    let $errors = '';
    let shouldHaveErrorClass = false;
    if (errors && errors.length) {
        $errors = FormFieldComponent.marshalMessageList(errors);
        shouldHaveErrorClass = true;
    }

    this._$groupEl.toggleClass('has-error', shouldHaveErrorClass);
    this._$groupEl.find('.js-errors').html($errors);
};

/**
 * Renders the form field's non-review errors.
 * Complex form fields should override this function if needed.
 */
FormFieldComponent.prototype.renderNonReviewErrors = function () {
    const errors = [];
    $.each(this.getErrors(), (i, error) => {
        if (!error.is_review) {
            errors.push(error);
        }
    });
    this.renderErrors(errors);
};

FormFieldComponent.prototype.renderReviewErrors = function () {
    const errors = [];
    $.each(this.getErrors(), (i, error) => {
        if (error.is_review) {
            errors.push(error);
        }
    });
    this.renderErrors(errors);
};

/**
 * Renders the form field's label.
 * Complex form fields should override this function if needed.
 */
FormFieldComponent.prototype.renderLabel = function () {
    if (this._data.label) {
        this._$el.find('.js-control-label').text(this._data.label);
    }
};

/**
 * Renders the form field's value.
 * Complex form fields should override this function if needed.
 */
FormFieldComponent.prototype.renderValue = function () {
    this._$el.val(this._data.value == null ? undefined : this._data.value);
};

/**
 * Renders the complete form field.
 * Complex form fields should override this function if needed.
 */
FormFieldComponent.prototype.render = function () {
    if (this._data.choices) {
        this.renderChoices();
    }

    this._$el.prop('disabled', !!this._data.is_readonly);

    if (this._data.label) {
        this.renderLabel();
    }

    this.renderValue();
};

/**
 * Sets the field's data
 *
 * @param {Object} data The data object to set
 */
FormFieldComponent.prototype.setData = function (data) {
    data = data || {};
    this._data = $.extend({}, data);
    this._data._name = this._name;
};

/**
 * Sets the error messages for this field
 *
 * @param {Array.<Object>} errors The list of error messages to add to the field
 */
FormFieldComponent.prototype.setErrors = function (errors) {
    this._data.errors = errors;
};

/**
 * Sets the value for this form field.
 * Complex form fields should override this function if needed.
 *
 * @param {number|string|Array|Object} val The value to set
 */
FormFieldComponent.prototype.setValue = function (val) {
    this._data.value = val;
};

/**
 * Binds actions to the form field element
 *
 * @private
 */
FormFieldComponent.prototype._bind = function () {
    this._$el.on(
        'change',
        $.proxy(function () {
            this.setValue(this._$el.val());
        }, this),
    );
};

export default FormFieldComponent;
