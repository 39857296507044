/**
 * A spotlight tag that is applied by staff
 * @typedef {Object} SpotlightType
 * @property {string} key
 * @property {string} value
 */

/**
 * Representation of a Chairish product
 */
class ProductModel {
    /**
     * @param {Object=} initialAttrs A universal key-value product representation
     */
    constructor(initialAttrs) {
        const attrs = {
            brand: '',
            breadcrumb_dicts: [],
            category_code: '',
            cubic_feet: 0,
            dealer_guid: '',
            discount_percent: 0,
            estimated_arrival_timeframe: '',
            first_badge: undefined,
            has_prop_65_warning: false,
            id: undefined,
            is_markdown: false,
            is_multiple: false,
            is_newly_made: false,
            is_on_hold: false,
            is_on_private_sale: false,
            is_on_private_sale_for_user: false,
            is_on_public_sale: false,
            is_pamono: false,
            is_promoted_listing: false,
            is_purchasable: true,
            is_variant: false,
            is_wholesale: false,
            lead_time: '',
            price: 0,
            product_group_id: undefined,
            product_state_display: '',
            quantity: 1,
            reserve_price: undefined,
            shipping_cost: 0,
            shipping_distance: 0,
            shipping_partner_code: '',
            shipping_type_code: '',
            ships_to_country_codes: [],
            spotlight_types: [],
            taxonomy: '',
            title: '',
            trade_discount: 0,
            unit_type: '',
            web_url: '',
            url: '',
            ...initialAttrs,
        };

        this.setBreadcrumbDicts(attrs.breadcrumb_dicts);
        this.setCubicFeet(attrs.cubic_feet);
        this.setCategoryCode(attrs.category_code);
        this.setDealerGuid(attrs.dealer_guid);
        this.setId(attrs.id);
        this.setBrand(attrs.brand);
        this.setDiscountPercent(attrs.discount_percent);
        this.setEstimatedArrivalTimeframe(attrs.estimated_arrival_timeframe);
        this.setFirstBadge(attrs.first_badge);
        this.setHasProp65Warning(attrs.has_prop_65_warning);
        this.setIsMarkdown(attrs.is_markdown);
        this.setIsMultiple(attrs.is_multiple);
        this.setIsNewlyMade(attrs.is_newly_made);
        this.setIsOnHold(attrs.is_on_hold);
        this.setIsOnPrivateSale(attrs.is_on_private_sale);
        this.setIsOnPrivateSaleForUser(attrs.is_on_private_sale_for_user);
        this.setIsOnPublicSale(attrs.is_on_public_sale);
        this.setIsPamono(attrs.is_pamono);
        this.setIsPromotedListing(attrs.is_promoted_listing);
        this.setIsPurchasable(attrs.is_purchasable);
        this.setIsVariant(attrs.is_variant);
        this.setIsWholesale(attrs.is_wholesale);
        this.setLeadTime(attrs.lead_time);
        this.setPrice(attrs.price);
        this.setProductGroupId(attrs.product_group_id);
        this.setProductStateDisplay(attrs.product_state_display);
        this.setQuantity(attrs.quantity);
        this.setReservePrice(attrs.reserve_price);
        this.setShippingCost(attrs.shipping_cost);
        this.setShippingDistance(attrs.shipping_distance);
        this.setShippingPartnerCode(attrs.shipping_partner_code);
        this.setShippingTypeCode(attrs.shipping_type_code);
        this.setShipsToCountryCodes(attrs.ships_to_country_codes);
        this.setSpotlightTypes(attrs.spotlight_types);
        this.setTaxonomy(attrs.taxonomy);
        this.setTitle(attrs.title);
        this.setTradeDiscount(attrs.trade_discount);
        this.setUnitType(attrs.unit_type);
        this.setWebUrl(attrs.web_url || attrs.url);
    }

    /**
     * Creates an array of Product instances from an array of attribute objects
     * @param {Array.<Object>} attrs An array of product attribute objects
     * @returns {Array.<ProductModel>} An array of Product instances
     */
    static bulkCreateFromArray(attrs) {
        return attrs.map((attr) => new ProductModel(attr));
    }

    /**
     * @returns {string}
     */
    getBrand() {
        return this.brand;
    }

    /**
     * @param {string} brand
     */
    setBrand(brand) {
        this.brand = brand;
    }

    /**
     * @returns {Array}
     */
    getBreadcrumbDicts() {
        return this.breadcrumb_dicts;
    }

    /**
     * @param {Array} breadcrumbDicts
     */
    setBreadcrumbDicts(breadcrumbDicts) {
        this.breadcrumb_dicts = breadcrumbDicts;
    }

    /**
     * @returns {string}
     */
    getCategoryCode() {
        return this.categoryCode;
    }

    /**
     * @param {string} categoryCode
     */
    setCategoryCode(categoryCode) {
        this.categoryCode = categoryCode;
    }

    /**
     * @returns {number}
     */
    getCubicFeet() {
        return this.cubicFeet;
    }

    /**
     * @param {number} cubicFeet
     */
    setCubicFeet(cubicFeet) {
        this.cubicFeet = cubicFeet;
    }

    /**
     * @returns {string}
     */
    getDealerGuid() {
        return this.dealerGuid;
    }

    /**
     * @param {string} dealerGuid
     */
    setDealerGuid(dealerGuid) {
        this.dealerGuid = dealerGuid;
    }

    /**
     * @returns {number}
     */
    getDiscountPercent() {
        return this.discountPercent;
    }

    /**
     * @param {number} discountPercent
     */
    setDiscountPercent(discountPercent) {
        this.discountPercent = discountPercent;
    }

    /**
     * @returns {string} Estimated arrival timeframe
     */
    getEstimatedArrivalTimeframe() {
        return this.estimatedArrivalTimeframe;
    }

    /**
     * @param {string} estimatedArrivalTimeframe Estimated arrival timeframe
     */
    setEstimatedArrivalTimeframe(estimatedArrivalTimeframe) {
        this.estimatedArrivalTimeframe = estimatedArrivalTimeframe;
    }

    /**
     * @returns {string} A badge for a product that is specific to the viewer to call attention to special information
     * about the listing i.e. potential sales, free shipping, etc...
     */
    getFirstBadge() {
        return this.firstBadge;
    }

    /**
     * @param {string} firstBadge
     */
    setFirstBadge(firstBadge) {
        this.firstBadge = firstBadge;
    }

    /**
     * @returns {number} The integer representation of the product id
     */
    getId() {
        return this.id;
    }

    /**
     * @param {number} id
     */
    setId(id) {
        this.id = isFinite(id) ? parseInt(id, 10) : undefined;
    }

    /**
     * @returns {boolean} Indication of whether this is a markdown or not
     */
    getIsMarkdown() {
        return this.isMarkdown;
    }

    /**
     * @param {boolean} isMarkdown
     */
    setIsMarkdown(isMarkdown) {
        this.isMarkdown = isMarkdown;
    }

    /**
     * @returns {boolean} Indication of whether this is a multiples product or not
     */
    getIsMultiple() {
        return this.isMultiple;
    }

    /**
     * @param {boolean} isMultiple
     */
    setIsMultiple(isMultiple) {
        this.isMultiple = isMultiple;
    }

    /**
     * @returns {boolean} Indication of whether this is a "newly made" product or not
     */
    getIsNewlyMade() {
        return this.isNewlyMade;
    }

    /**
     * @param {boolean} isNewlyMade
     */
    setIsNewlyMade(isNewlyMade) {
        this.isNewlyMade = isNewlyMade;
    }

    /**
     * @returns {boolean} Indication of whether product is on hold
     */
    getIsOnHold() {
        return this.isOnHold;
    }

    /**
     * @param {boolean} isOnHold
     */
    setIsOnHold(isOnHold) {
        this.isOnHold = isOnHold;
    }

    /**
     * @returns {boolean} Indication of whether this is on private sale or not
     */
    getIsOnPrivateSale() {
        return this.isOnPrivateSale;
    }

    /**
     * @param {boolean} isOnPrivateSale
     */
    setIsOnPrivateSale(isOnPrivateSale) {
        this.isOnPrivateSale = isOnPrivateSale;
    }

    /**
     * @returns {boolean} Indication of whether this is on private sale or not for a user
     */
    getIsOnPrivateSaleForUser() {
        return this.isOnPrivateSaleForUser;
    }

    /**
     * @param {boolean} isOnPrivateSaleForUser
     */
    setIsOnPrivateSaleForUser(isOnPrivateSaleForUser) {
        this.isOnPrivateSaleForUser = isOnPrivateSaleForUser;
    }

    /**
     * @returns {boolean} Indication of whether this is on public sale or not
     */
    getIsOnPublicSale() {
        return this.isOnPublicSale;
    }

    /**
     * @param {boolean} isOnPublicSale
     */
    setIsOnPublicSale(isOnPublicSale) {
        this.isOnPublicSale = isOnPublicSale;
    }

    /**
     * @returns {boolean} Indication of whether this product is a pamono product
     */
    getIsPamono() {
        return this.isPamono;
    }

    /**
     * @param {boolean} isPamono
     */
    setIsPamono(isPamono) {
        this.isPamono = isPamono;
    }

    /**
     * @returns {boolean} Indication of whether this product is a promoted listing
     */
    getIsPromotedListing() {
        return this.isPromotedListing;
    }

    /**
     * @param {boolean} isPromotedListing
     */
    setIsPromotedListing(isPromotedListing) {
        this.isPromotedListing = isPromotedListing;
    }

    /**
     * @returns {boolean} Indication of whether this product has a prop 65 warning
     */
    getHasProp65Warning() {
        return this.hasProp65Warning;
    }

    /**
     * @param {boolean} hasProp65Warning
     */
    setHasProp65Warning(hasProp65Warning) {
        this.hasProp65Warning = hasProp65Warning;
    }

    /**
     * @returns {boolean} Indication of whether this product is purchasable
     * NOTE: 'is_purchasable' is distinct from 'can_be_purchased' in that it does not take into account the viewer
     *  of the product -- only its state.
     */
    getIsPurchasable() {
        return this.isPurchasable;
    }

    /**
     * @param {boolean} isPurchasable
     */
    setIsPurchasable(isPurchasable) {
        this.isPurchasable = isPurchasable;
    }

    /**
     * @return {boolean} Whether this product is a variant
     */
    getIsVariant() {
        return this.isVariant;
    }

    /**
     * @param {boolean} isVariant
     */
    setIsVariant(isVariant) {
        this.isVariant = isVariant;
    }

    /**
     * @return {boolean} Whether this product is a "wholesale"
     */
    getIsWholesale() {
        return this.isWholesale;
    }

    /**
     * @param {boolean} isWholesale
     */
    setIsWholesale(isWholesale) {
        this.isWholesale = isWholesale;
    }

    /**
     * @returns {string} Shipping lead time for newly made
     */
    getLeadTime() {
        return this.leadTime;
    }

    /**
     * @param {string} Shipping lead time for newly made
     */
    setLeadTime(leadTime) {
        this.leadTime = leadTime;
    }

    /**
     * @returns {number} The float representation of the product's price
     */
    getPrice() {
        return this.price;
    }

    /**
     * @param {number} price The float representation of the product's price
     */
    setPrice(price) {
        this.price = isFinite(price) ? parseFloat(price) : 0;
    }

    /**
     * @returns {number|undefined} The integer representation of the product group id, if applicable
     */
    getProductGroupId() {
        return this.productGroupId;
    }

    /**
     * @param {number|undefined} productGroupId The integer representation of the product group id, if applicable
     */
    setProductGroupId(productGroupId) {
        this.productGroupId = productGroupId;
    }

    /**
     * @returns {number} The integer representation of the quantity of this product to consider purchasing
     */
    getQuantity() {
        return this.quantity;
    }

    /**
     * Sets the quantity of this product to consider purchasing
     * @param {number} quantity Integer representing the quantity to consider purchasing
     */
    setQuantity(quantity) {
        this.quantity = isFinite(quantity) ? parseInt(quantity, 10) : 1;
    }

    /**
     * @returns {number} The float representation of the product's reserve price
     */
    getReservePrice() {
        return this.reservePrice;
    }

    /**
     * @param {number} reservePrice The float representation of the product's reserve price
     */
    setReservePrice(reservePrice) {
        this.reservePrice = isFinite(reservePrice) ? parseFloat(reservePrice) : undefined;
    }

    /**
     * @returns {number}
     */
    getShippingCost() {
        return this.shippingCost;
    }

    /**
     * @param {number} shippingCost
     */
    setShippingCost(shippingCost) {
        this.shippingCost = shippingCost;
    }

    /**
     * @returns {number|undefined}
     */
    getShippingDistance() {
        return this.shippingDistance;
    }

    /**
     * @param {number|undefined} shippingDistance
     */
    setShippingDistance(shippingDistance) {
        this.shippingDistance = shippingDistance;
    }

    /**
     * @returns {string}
     */
    getShippingPartnerCode() {
        return this.shippingPartnerCode;
    }

    /**
     * @param {string} shippingPartnerCode
     */
    setShippingPartnerCode(shippingPartnerCode) {
        this.shippingPartnerCode = shippingPartnerCode;
    }

    /**
     * @returns {string}
     */
    getShippingTypeCode() {
        return this.shippingTypeCode;
    }

    /**
     * @param {string} shippingTypeCode
     */
    setShippingTypeCode(shippingTypeCode) {
        this.shippingTypeCode = shippingTypeCode;
    }

    /**
     * @returns {Array.<string>} country codes this product ships to
     */
    getShipsToCountryCodes() {
        return this.shipsToCountryCodes;
    }

    /**
     * @param {Array.<string>} countryCodes
     */
    setShipsToCountryCodes(countryCodes) {
        this.shipsToCountryCodes = countryCodes;
    }

    /**
     * @returns {string} Comma-separated list of spotlight tag type codes
     */
    getSpotlightTypeCodes() {
        return this.spotlightTypes.map((tagType) => tagType.value).join(',');
    }

    /**
     * @param {Array.<SpotlightType>} spotlightTypes
     */
    setSpotlightTypes(spotlightTypes) {
        this.spotlightTypes = spotlightTypes;
    }

    /**
     * @returns {string}
     */
    getProductStateDisplay() {
        return this.productStateDisplay;
    }

    /**
     * @param {string} stateDisplay
     */
    setProductStateDisplay(stateDisplay) {
        this.productStateDisplay = stateDisplay;
    }

    /**
     * @returns {string}
     */
    getTaxonomy() {
        return this.taxonomy;
    }

    /**
     * @param {string} taxonomy
     */
    setTaxonomy(taxonomy) {
        this.taxonomy = taxonomy;
    }

    /**
     * @returns {string} The product's title
     */
    getTitle() {
        return this.title;
    }

    /**
     * @param {string} title
     */
    setTitle(title) {
        this.title = title;
    }

    /**
     * @returns {number} The product's trade discount
     */
    getTradeDiscount() {
        return this.tradeDiscount;
    }

    /**
     * @param {number} tradeDiscount
     */
    setTradeDiscount(tradeDiscount) {
        this.tradeDiscount = tradeDiscount;
    }

    /**
     * @returns {string} The product's unit type
     */
    getUnitType() {
        return this.unitType;
    }

    /**
     * @param {string} unitType The product's unit type
     */
    setUnitType(unitType) {
        this.unitType = unitType;
    }

    /**
     * @returns {string} The product's web URL
     */
    getWebUrl() {
        return this.webUrl;
    }

    /**
     * @param {string} webUrl
     */
    setWebUrl(webUrl) {
        this.webUrl = webUrl;
    }

    /**
     * @returns {string} The URL to use when deleting the product
     */
    getDeleteUrl() {
        return `/product/${this.id}/delete`;
    }

    /**
     * @returns {string} The URL to use when marking the product down
     */
    getMarkdownUrl() {
        return `/product/${this.id}/markdown`;
    }
}

export default ProductModel;
