import $ from 'jquery';
import ErrorContext from 'chairisher/context/error';
import Alerter from 'chairisher/view/alerter';

/**
 * View helper for Chairish on/off toggles
 */

class Toggle {
    constructor({ toggleSelector = '.js-toggle', $toggleEl = null }) {
        this.toggleSelector = toggleSelector;
        this.$toggleEl = $toggleEl ?? $(this.toggleSelector);
        this.activeClass = this.$toggleEl.data('active-class');
        this.inactiveClass = this.$toggleEl.data('inactive-class');
        this.loadingClass = this.$toggleEl.data('loading-class');
    }

    /**
     * @returns {jQuery} The jQuery toggle element
     */
    getEl() {
        return this.$toggleEl;
    }

    /**
     * @returns {boolean} True indicates the toggle is "on"
     */
    isActive() {
        return this.$toggleEl.hasClass(this.activeClass);
    }

    /**
     * @param {boolean} isDisabled True indicates the toggle should be disabled
     */
    setDisabled(isDisabled) {
        this.$toggleEl.prop('disabled', isDisabled);
    }

    /**
     * @param {string} text The text to display next to the toggle
     */
    setText(text) {
        this.$toggleEl.siblings('.js-toggle-text').text(text);
    }

    /**
     * Submits data embedded in the toggle
     *
     * @param {boolean=} shouldToggle Optional boolean that indicates if the toggle action should happen or not
     * @returns {$.Deferred}
     */
    submit(shouldToggle = false) {
        const $toggle = this.$toggleEl;
        const wasActive = this.isActive();
        const shouldBeActive = !wasActive; // the user is requesting a change

        if (shouldToggle) {
            this.toggleLoading();
        }

        return $.ajax({
            contentType: 'application/json; charset=utf-8',
            data: JSON.stringify({ is_active: shouldBeActive }),
            url: $toggle.data('toggle-url'),
            type: $toggle.data('http-method') || 'POST',
        })
            .done((data) => {
                if (shouldToggle) {
                    this.toggle(data.is_active); // assumes `is_active` is passed back from known endpoints...
                }
            })
            .fail((response) => {
                if (shouldToggle) {
                    this.toggle(wasActive);
                }

                if (response.responseJSON && response.responseJSON.errors) {
                    Alerter.error(response.responseJSON.errors[0].message);
                } else {
                    Alerter.error(ErrorContext.getDefaultErrorText());
                }
            });
    }

    /**
     * Handle the toggling of the toggle
     *
     * @param {boolean|null} shouldBeActiveOverride When present, indicates if the toggle should be made active or not
     */
    toggle(shouldBeActiveOverride = null) {
        const shouldBeActive = shouldBeActiveOverride ?? !this.isActive();
        const $toggle = this.$toggleEl;

        $toggle.removeClass([shouldBeActive ? this.inactiveClass : this.activeClass, this.loadingClass].join(' '));
        $toggle.addClass(shouldBeActive ? this.activeClass : this.inactiveClass);
        $toggle.prop('disabled', false);

        const activeText = $toggle.data('active-text');
        const inactiveText = $toggle.data('inactive-text');
        if (activeText !== inactiveText) {
            this.setText($toggle.data(shouldBeActive ? 'active-text' : 'inactive-text'));
        }
    }

    /**
     * Toggles the switch into an intermediary loading state
     */
    toggleLoading() {
        const $toggle = this.$toggleEl;

        $toggle.prop('disabled', true);
        $toggle.removeClass([this.activeClass, this.inactiveClass].join(' '));
        $toggle.addClass(this.loadingClass);

        this.setText($toggle.data('loading-text'));
    }
}

export default Toggle;
