import $ from 'jquery';

import AuthContext from 'chairisher/context/auth';
import MediaQueryUtils from 'chairisher/util/mediaquery';
import SiteContext from 'chairisher/context/site';

import { bindTrackUserEvents, trackStartChatSession } from 'chairisher/analytics/zendesk';
import { getLiveChatHelpSuggestionTag, getLiveChatSettings } from 'chairisher/context/zendesk';

/**
 * Background: the Zendesk web widget hurts our load time (even using their lazy load),
 * so we load a mock Zendesk button that manually bootstraps the plugin on click.
 * On some pages we also initialize an auto-bootstrap sequence set to a timer.
 * Support can then use the Zendesk backend to initialize proactive chat.
 * Usage: This class exports a singleton instance as zendeskWebWidgetInst.
 */
class ZendeskWebWidget {
    constructor() {
        this.trackingPosition = null;
    }

    /**
     * Binds the mock widget's onclick method and all corresponding actions
     */
    bind() {
        $('.js-livechat-widget').on('click', () => this.handleWindowLoad());
    }

    /**
     * Tracks interactions with the zendesk widgets
     */
    bindAnalytics() {
        if (SiteContext.isLiveChatActive()) {
            trackStartChatSession(this.trackingPosition);
            bindTrackUserEvents(this.trackingPosition);
        }
    }

    /**
     * Begins the animation, fetches the Zendesk chat window and sets the mock widget's removal from the dom
     * @param {boolean=} shouldAutoOpen Should auto-open the chat window on load.
     */
    bootstrapZendesk(shouldAutoOpen = true) {
        const $livechatWidget = $('#livechat-widget');
        $livechatWidget.addClass('livechat-widget-animation');

        this.loadZeSnippet(shouldAutoOpen);

        $livechatWidget.on('animationend', () => {
            $livechatWidget.remove();
        });
    }

    /**
     * Bypasses the web widget and immediately displays the chat window
     */
    displayChatWindow() {
        window.zE(() => {
            window.$zopim(() => {
                window.$zopim.livechat.window.show();
            });
        });
    }

    /**
     * Runs full setup if initial load; otherwise, just displays the window
     */
    handleWindowLoad() {
        if (SiteContext.isLiveChatActive()) {
            this.displayChatWindow();
        } else {
            this.bootstrapZendesk();
        }
    }

    /**
     * Additional actions; fired in order upon the loading of the ze snippet
     * @param {boolean} shouldAutoOpen - Should auto-open the chat window on load.
     */
    handleZeSnippetLoad(shouldAutoOpen) {
        this.bindAnalytics();
        this.setLiveChatSettings();
        if (shouldAutoOpen) {
            this.displayChatWindow();
        }
    }

    /**
     * Hides the real web widget
     */
    hide() {
        if (SiteContext.isLiveChatActive()) {
            window.zE('webWidget', 'show');
        }
    }

    /**
     * Hides the mock web widget
     */
    hideMockWidget() {
        $('.js-livechat-widget').addClass('hidden');
    }

    /**
     * Updates the livechat context; then, creates and inserts the Zendesk chat snippet into the dom
     * @param {boolean} shouldAutoOpen - Should auto-open the chat window on load.
     */
    loadZeSnippet(shouldAutoOpen) {
        SiteContext.setLiveChatIsActive(true);

        const script = document.createElement('script');
        script.id = 'ze-snippet';
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=b5e0997a-0c9c-440d-b120-1e5a4c1bd1b1';
        script.onload = () => this.handleZeSnippetLoad(shouldAutoOpen);

        document.body.appendChild(script);
    }

    /**
     * Sets the settings for the livechat menu
     */
    setLiveChatSettings() {
        window.zESettings = getLiveChatSettings();
        const suggestionTag = getLiveChatHelpSuggestionTag();

        if (suggestionTag) {
            window.zE('webWidget', 'helpCenter:setSuggestions', {
                labels: [suggestionTag],
            });
        }
    }

    /**
     * @param {string} position The tracking position to include in analytics calls.
     */
    setTrackingPosition(position) {
        this.trackingPosition = position;
    }

    /**
     * Shows the real web widget
     */
    show() {
        if (SiteContext.isLiveChatActive()) {
            window.zE('webWidget', 'show');
        }
    }

    /**
     * Shows the mock web widget
     */
    showMockWidget() {
        $('.js-livechat-widget').removeClass('hidden');
    }

    /**
     * Start a timer to autoload the zendesk widget
     * @param {number=} timeout - Milliseconds to wait before auto-bootstrapping.
     * @param {boolean=} isOncePerSession - Only auto-boostrap once per session.
     */
    startAutoloadTimer(timeout = 15000, isOncePerSession = true) {
        if (
            MediaQueryUtils.isMinLargeDesktop() &&
            !AuthContext.isTrade() &&
            (!isOncePerSession || !window.sessionStorage.ZDAutobootstrapped)
        ) {
            window.setTimeout(() => {
                window.sessionStorage.ZDAutobootstrapped = true;
                this.bootstrapZendesk(false);
            }, timeout);
        }
    }
}

export default new ZendeskWebWidget();
