/**
 * Safari 14 and 13 don't support all the number format options.
 * Once those versions are phased out we can remove this function and call Intl.NumberFormat directly again.
 *
 * @param {number|string} num
 * @param {string} locale
 * @param {object} options
 * @return {string}
 */
function formatNumber(num, locale, options) {
    try {
        return new Intl.NumberFormat(locale, options).format(num);
    } catch (error) {
        console.error(error);
        return num.toString();
    }
}

/**
 * Formats a given number to be displayed with a letter abbreviation instead of trailing digits
 *
 * @param {number|string} num The number to potentially be formatted, dependent on value
 * @returns {string} The formatted or original number as a string
 * @example abbreviateNumber(12345): '12k'
 */
export function abbreviateNumber(num) {
    return formatNumber(num, 'en-US', {
        maximumFractionDigits: 0,
        notation: 'compact',
        roundingMode: 'floor',
    }).toLowerCase();
}

/**
 * Decodes HTML entities.
 *
 * @param {string} str The string with HTML entities to decode
 * @returns {string} The decoded string
 */
export function decodeHTMLEntity(str) {
    return str.replace(/&#(\d+);/g, (_, dec) => String.fromCharCode(dec));
}

/**
 * Formats a number to display as a currency
 *
 * @param {number|string} price The number to format
 * @param {boolean=} shouldDisplayDecimals True indicates the currency should be displayed with 2 decimal places
 * @param {boolean=} shouldDisplayZeroAmount True returns $0.00 for $0; False returns - instead of $0.00
 * @returns {string}
 */
export function formatCurrency(price, shouldDisplayDecimals = false, shouldDisplayZeroAmount = false) {
    const priceFloat = parseFloat(price);
    if (priceFloat === 0 && !shouldDisplayZeroAmount) {
        // unicode mdash character
        return '—';
    }
    return formatNumber(price, 'en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: shouldDisplayDecimals ? 2 : 0,
    });
}

/**
 * Returns appropriate indefinite article (a, an) for the word.
 *
 * @param {string} word The word
 * @returns {string} The indefinite article
 */
export function getIndefiniteArticle(word) {
    return /^[aeiou]/i.test(word) ? 'an' : 'a';
}

/**
 * Wraps segments of text that don't match the query in <strong> tags
 *
 * @param {string} query A string used to match words in text
 * @param {string} nonMatchingText The text to highlight
 * @returns {string} Returns a string that has the original text with strong tags within it
 */
export function highlightNonMatchingQueryText(query, nonMatchingText) {
    function recursiveHighlightNonMatchingQueryText(pattern, text) {
        const match = text.match(new RegExp(pattern, 'i'));
        const matchWord = match ? match[0] : '';
        if (text.length === 0) {
            return '';
        }
        if (match === null) {
            return `<strong>${text}</strong>`;
        }
        return (
            recursiveHighlightNonMatchingQueryText(pattern, text.substring(0, match.index)) +
            matchWord +
            recursiveHighlightNonMatchingQueryText(pattern, text.substring(match.index + matchWord.length, text.length))
        );
    }

    return recursiveHighlightNonMatchingQueryText(query.trim().split(/\s+/).join('|'), nonMatchingText);
}

/**
 * Comma-separates a number string.
 * @param {number|string} value The value to format.
 * @returns {string} The formatted number.
 */
export function intcomma(value) {
    return new Intl.NumberFormat('en-US').format(value);
}

/**
 * Converts to lowercase, removes non-word characters (alphanumerics and underscores) and converts spaces to hyphens.
 * Also strips leading and trailing whitespace.
 *
 * @param {string} string The input value to convert
 * @returns {string} The converted value
 */
export function slugify(string) {
    return (string || '')
        .toLowerCase()
        .replace(/[^a-z0-9\-\s_]/g, '')
        .trim()
        .replace(/\s/g, '-');
}

/**
 * Accepts a string of text and wraps it with parenthesis. If empty string, returns original text.
 *
 * @param {string} text The text to wrap with parenthesis
 * @returns {string} Either the updated text with parenthesis or the original text
 */
export function wrapTextWithParenthesis(text) {
    return text.trim().length ? `(${text})` : text;
}
