const ChairishContext = window.chairisher.context;

/**
 * An interface that provides documentation and access to context variables related to businesses.
 */
const BusinessContext = {
    /**
     * @returns {string} The type of business being registered or referenced on the page
     */
    getBusinessType() {
        return ChairishContext.BUSINESS_TYPE;
    },

    /**
     * List of business ids followed by the current account.
     *
     * @return {Array.<number>}
     */
    getFollowedBusinessIds() {
        return ChairishContext.FOLLOWED_BUSINESS_IDS;
    },

    /**
     * @returns {string}
     */
    getBusinessRailEndpoint() {
        return ChairishContext.BUSINESS_RAIL_ENDPOINT;
    },

    /**
     * @returns {Object} businessRailData Object mapping business id to data related to that business for a business rail
     */
    getBusinessRailData() {
        return ChairishContext.BUSINESS_RAIL_DATA || {};
    },

    /**
     * @param {Object} businessRailData Object mapping business id to data related to that business for a business rail
     */
    setBusinessRailData(businessRailData) {
        ChairishContext.BUSINESS_RAIL_DATA = businessRailData;
    },
};

export default BusinessContext;
