import CookieUtils from 'chairisher/util/cookie';

const ChairishContext = window.chairisher.context;

/**
 * An interface that provides documentation and access to context variables related to cookies.
 */
const CookiesContext = {
    /**
     * @returns {boolean} True indicates advertising cookies / pixels can be loaded
     */
    canLoadAdvertisingCookies() {
        return !CookiesContext.isOptedOutOfCookie(ChairishContext.ADVERTISING_COOKIES_CODE);
    },

    /**
     * @returns {boolean} True indicates functional cookies / pixels can be loaded
     */
    canLoadFunctionalCookies() {
        return !CookiesContext.isOptedOutOfCookie(ChairishContext.FUNCTIONAL_COOKIES_CODE);
    },

    /**
     * @returns {boolean} True indicates performance cookies / pixels can be loaded
     */
    canLoadPerformanceCookies() {
        return !CookiesContext.isOptedOutOfCookie(ChairishContext.PERFORMANCE_COOKIES_CODE);
    },

    /**
     * @returns {string} Cookies preference code
     */
    getCookiesPreferenceCode() {
        return ChairishContext.COOKIES_PREFERENCES_CODE;
    },

    /**
     * @returns {string} Advertising cookie code
     */
    getAdvertisingCookiesCode() {
        return ChairishContext.ADVERTISING_COOKIES_CODE;
    },

    /**
     * @returns {string} Functional cookie code
     */
    getFunctionalCookiesCode() {
        return ChairishContext.FUNCTIONAL_COOKIES_CODE;
    },

    /**
     * @returns {string} Performance cookie code
     */
    getPerformanceCookiesCode() {
        return ChairishContext.PERFORMANCE_COOKIES_CODE;
    },

    /**
     * @returns {string} Cookie Banner Code
     */
    getCookieBannerCode() {
        return ChairishContext.COOKIE_BANNER_CODE;
    },

    /**
     * @returns {string} Opt out value
     */
    getOptOutOfCookiesValue() {
        return ChairishContext.OPT_OUT_OF_COOKIES_VALUE;
    },

    /**
     * @param {string} cookieCode
     * @returns {boolean} Whether the user is opted out of the cookie
     */
    isOptedOutOfCookie(cookieCode) {
        return CookieUtils.getValueFromCookie(cookieCode) === CookiesContext.getOptOutOfCookiesValue();
    },

    /**
     * @param {string} cookieCode The cookie code to set whether the user is opted in/out of
     * @param {boolean} isOptedOut
     */
    setIsOptedOutOfCookie(cookieCode, isOptedOut) {
        if (isOptedOut) {
            CookieUtils.setCookie(cookieCode, CookiesContext.getOptOutOfCookiesValue());
        } else {
            CookieUtils.removeCookie(cookieCode);
        }
    },
};
export default CookiesContext;
