const ChairishContext = window.chairisher.context;

/**
 * @returns {Promise} Promise for the account data fetch which runs on every page load
 * @see templates/partials/_js_controller_call.html
 */
export function getAccountDataPromise() {
    return ChairishContext.accountDataPromise;
}

/**
 * @param {Object=} params Optional params to append to account data url
 *
 * @returns {string} URL used to retrieve Account Data
 */
export function getAccountDataUrl(params) {
    return ChairishContext.ACCOUNT_DATA_URL + (params ? `?${params}` : '');
}

/**
 * @param {number=} id Optional account ID
 *
 * @returns {string} The URL to hit that creates a new account address
 */
export function getAccountAddressEndpoint(id) {
    return ChairishContext.ACCOUNT_ADDRESS_ENDPOINT + (id ? `/${id}` : '');
}

/**
 * @returns {string} The discount percent of the current account-wide private sale
 */
export function getAccountSaleDiscountPercent() {
    return ChairishContext.ACCOUNT_SALE_DISCOUNT_PERCENT;
}

/**
 * @returns {string} The URL to hit that updates an account-wide sale
 */
export function getAccountSaleUpdateUrl() {
    return ChairishContext.UPCOMING_ACCOUNT_SALE_UPDATE_URL;
}

/**
 * @returns {string} The URL to hit that updates the user's cookie settings
 */
export function getCookieSettingsUpdateUrl() {
    return ChairishContext.ACCOUNT_COOKIE_SETTINGS_UPDATE_URL;
}

/**
 * @returns {string} The URL to hit that creates additional contacts
 */
export function getAdditionalContactEndpoint() {
    return ChairishContext.ADDITIONAL_CONTACT_ENDPOINT;
}

/**
 * @returns {Array<Object>} The list of permissions groups currently assigned to the user
 */
export function getCurrentPermissionsGroups() {
    return ChairishContext.CURRENT_PERMISSIONS_GROUPS;
}

/**
 * @returns {string|undefined} The account's email
 */
export function getEmail() {
    return ChairishContext.EMAIL;
}

/**
 * @returns {number} The number of private sales available for the current user
 */
export function getNumPrivateSales() {
    return ChairishContext.num_private_sale_products || 0;
}

/**
 * @returns {Object} Datastructure of permissions groups choices in tree format
 */
export function getPermissionsGroupsChoiceTree() {
    return ChairishContext.PERMISSIONS_GROUPS_TREE;
}

/**
 * @returns {string} The URL for the current user's private sales
 */
export function getPrivateSaleUrl() {
    return ChairishContext.your_private_sale_url;
}

/**
 * @returns {string} The endpoint to get product ids correlated to the channels that the requester is a member of
 */
export function getProductIdsForAccountChannelsUrl() {
    return ChairishContext.PRODUCT_IDS_FOR_ACCOUNT_CHANNELS_URL;
}

/**
 * @returns {boolean} True indicates the current user has private sales available to them
 */
export function hasPrivateSales() {
    return !!getNumPrivateSales();
}

/**
 * @returns {boolean} True indicates the account has opeted out of data sharing
 */
export function isDataSharingOptedOut() {
    return !!ChairishContext.IS_DATA_SHARING_OPTED_OUT;
}

/**
 * @param {number} discount
 *
 * @returns {number} The discount percent of the current account-wide private sale
 */
export function setAccountSaleDiscountPercent(discount) {
    ChairishContext.ACCOUNT_SALE_DISCOUNT_PERCENT = discount;
}
