import $ from 'jquery';

import { getTradeRewardsRedemptionUrl } from 'chairisher/context/trade';
import { logAmplitudeEvent, logAmplitudeLinkClickEvent } from 'chairisher/util/analytics';

/**
 * Tracking positions for navigation
 *
 * @enum {string}
 */
const Position = {
    TRADE_REWARDS_DASHBOARD: 'trade rewards dashboard',
};

/**
 * Tracks link clicks in the dashboard
 *
 * @param {Event} e
 */
export function trackTradeRewardsDashboardLinkClick(e) {
    const $link = $(e.currentTarget);

    logAmplitudeLinkClickEvent(
        $link.text().trim(),
        $link.attr('href') || window.location,
        Position.TRADE_REWARDS_DASHBOARD,
    );
}

/**
 * Tracks clicks on the REDEEM button in the dashboard
 *
 * @param {Event} e
 */
export function trackTradeRewardsRedemptionLinkClick(e) {
    const $button = $(e.currentTarget);
    logAmplitudeLinkClickEvent($button.text().trim(), getTradeRewardsRedemptionUrl(), Position.TRADE_REWARDS_DASHBOARD);
}

/**
 * Tracks a successful trade rewards redemption
 */
export function trackTradeRewardsRedemptionSuccess() {
    logAmplitudeEvent('trade rewards - redemption success');
}

/**
 * Tracks an error when attempting to redeem trade rewards
 */
export function trackTradeRewardsRedemptionError() {
    logAmplitudeEvent('trade rewards - redemption error');
}
