import $ from 'jquery';

const RailUtils = {
    /**
     * Determines the height of the rail container by adding together the various components of its largest item
     * Note: This multi-step approach is required to avoid the resizing bug that will occur if only using overall height
     *
     * @param {JQuery} $children The jQuery element containing all of the products in the rail
     * @param {Element=} container The rail container; not required if rail is scrollable
     * @returns {string} The new height of the container in px
     */
    calculateContainerHeight({ $children, container = null }) {
        const $firstChild = $children.first();

        let childHeight = $firstChild.height(); // will determine container height if rail doesn't have images

        const $childImg = $firstChild.find('img');
        if ($childImg.length > 0) {
            childHeight = $childImg.height(); // will determine container height if images don't have captions

            let $captions = $children.find('.js-caption');
            if ($captions.length > 0) {
                if (container) {
                    const numVisibleElements = Math.round(container.offsetWidth / $firstChild.width());
                    $captions = $captions.slice(0, numVisibleElements);
                }

                const maxCaptionHeight = Math.max(...$captions.map((_, caption) => $(caption).height()));
                $captions.css('min-height', maxCaptionHeight);

                childHeight += maxCaptionHeight; // will determine container height by combining image and largest caption
            }
        }

        return `${childHeight}px`;
    },
};

export default RailUtils;
