import $ from 'jquery';

import { AmplitudeEventProperty, logAmplitudeEvent } from 'chairisher/util/analytics';
import { trackSearchForPRT } from 'chairisher/thirdparty/pinterestretargeting';

/**
 * Tracks a query term or a suggested search
 *
 * @param {string} location The location of the search input (i.e. masthead, blog masthead, etc.)
 * @param {string=} query The query the user submitted
 * @param {string=} suggestionName The name of the suggestion the user clicked
 * @param {string=} suggestionUrl The URL of the suggestion the user clicked
 * @param {Object.<string, string>=} refinements Refinements by which the search is filtered, if any
 */
export default function trackGlobalSearch(location, query, suggestionName, suggestionUrl, refinements) {
    const eventObj = {};
    eventObj[AmplitudeEventProperty.LOCATION] = location;

    const querySanitized = (query || '').toLowerCase().trim();
    if (querySanitized) {
        eventObj[AmplitudeEventProperty.QUERY] = querySanitized;
        eventObj[AmplitudeEventProperty.NUM_TOKENS] = querySanitized.split(' ').length;
    }

    if (suggestionName && suggestionUrl) {
        eventObj[AmplitudeEventProperty.SUGGESTION_NAME] = suggestionName.trim();
        eventObj[AmplitudeEventProperty.SUGGESTION_URL] = suggestionUrl;
    }

    if (refinements) {
        $.each(refinements, (refinementName, refinementValue) => {
            // Prefix with `refinement` to avoid clash with top-level keys.
            eventObj[`refinement - ${refinementName}`] = refinementValue;
        });
    }

    logAmplitudeEvent('search - submit', eventObj);
    trackSearchForPRT(querySanitized);
}
