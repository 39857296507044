import CookieUtils from 'chairisher/util/cookie';

import { getPromotedListingsImpressionUrl } from 'chairisher/context/promotedlistings';
import { postJSON } from 'chairisher/util/fetch';
import { logHouseAdImpression, logPromotedListingImpression } from 'chairisher/analytics/promotedlistings';

const POPUP_COOKIE_DURATION_DAYS = 1;
const POPUP_OPT_OUT_COOKIE = 'PLPopupClosed';
const POPUP_OPT_OUT_COOKIE_VALUE = 1;

export const HOUSE_AD_CLASS = 'js-is-house-ad';
export const PROMOTED_LISTING_CLASS = 'js-is-promoted-listing';

/**
 * @returns {boolean} An indication of whether the user has already seen the promoted listings popup in the last day.
 */
export function hasSeenPromotedListingsPopup() {
    return CookieUtils.getIntValueFromCookie(POPUP_OPT_OUT_COOKIE) === POPUP_OPT_OUT_COOKIE_VALUE;
}

/**
 * Opts a user out of seeing the promoted listings popup for the day
 */
export function optOutOfPromotedListingsPopup() {
    CookieUtils.setCookie(POPUP_OPT_OUT_COOKIE, POPUP_OPT_OUT_COOKIE_VALUE, POPUP_COOKIE_DURATION_DAYS);
}

/**
 * Since promoted listing products appear in rows we bundle their impressions together.
 * This substantially cuts down on web traffic to the register impression endpoint.
 * The impl uses a timeout to queue up impressions, and then drains the queue after this many MS.
 */
const IMPRESSION_BATCH_COLLECTION_PERIOD_MS = 25;

/**
 * Private class that contains the needed state for batching impressions.
 */
class ImpressionBatchState {
    constructor() {
        this.impressionProductIdSet = new Set();
        this.lastTimeout = null;
        this.productIdQueue = [];
    }

    drainProductIdQueue() {
        const productIds = this.productIdQueue;
        this.productIdQueue = [];
        return productIds;
    }

    /**
     * Executes a POST request to our server that registers promoted listing interactions.
     * The promise isn't returned since clients shouldn't wait for a response - its intended use is 'fire and forget'.
     * Note: this should only be used for actual promoted listings, never house ads.
     *
     * @param {Number|String} productId productId to register impression for
     * @returns {undefined}
     */
    register(productId) {
        if (!this.impressionProductIdSet.has(productId)) {
            this.impressionProductIdSet.add(productId);
            this.productIdQueue.push(productId);

            if (this.lastTimeout) {
                window.clearTimeout(this.lastTimeout);
            }

            this.lastTimeout = window.setTimeout(() => {
                postJSON(getPromotedListingsImpressionUrl(), {
                    product_ids: this.drainProductIdQueue(),
                    source_url: window.location.pathname,
                });

                this.lastTimeout = null;
            }, IMPRESSION_BATCH_COLLECTION_PERIOD_MS);
        }
    }
}

const batchState = new ImpressionBatchState();

/**
 * Registers an impression for either a promoted listing or a house ad.
 *
 * @param {jQuery} $el A product html element
 * @param {string|null} amplitudePosition When present, logs the event to amplitude
 * @param {string|null} amplitudeSubType
 */
export function registerProductImpression($el, amplitudePosition, amplitudeSubType = null) {
    const productId = $el.data('product-id');
    const isPromotedListing = $el.hasClass(PROMOTED_LISTING_CLASS);
    const isHouseAd = $el.hasClass(HOUSE_AD_CLASS);

    if (isPromotedListing) {
        batchState.register(productId);
        if (amplitudePosition) {
            logPromotedListingImpression(productId, amplitudePosition, amplitudeSubType);
        }
    } else if (isHouseAd) {
        if (amplitudePosition) {
            logHouseAdImpression(productId, amplitudePosition, amplitudeSubType);
        }
    }
}
