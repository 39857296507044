import $ from 'jquery';

function toggleLockedViewport(shouldLock) {
    $('html').toggleClass('modal-open', shouldLock);
}

const ViewportUtil = {
    makeScalable() {
        $('meta[name=viewport]').replaceWith(
            $('<meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=yes">'),
        );
    },

    makeNonScalable() {
        $('meta[name=viewport]').replaceWith(
            $('<meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no">'),
        );
    },

    /**
     * Prevents the screen from being able to scroll
     */
    lockViewport() {
        toggleLockedViewport(true);
    },

    /**
     * Allows the screen to scroll after having previously been locked
     */
    unlockViewport() {
        toggleLockedViewport(false);
    },
};

export default ViewportUtil;
