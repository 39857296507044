import $ from 'jquery';

import OfferContext from 'chairisher/context/offer';
import ProductContext from 'chairisher/context/product';
import { getOrder, getOrderItems, getProductIdToProductMap } from 'chairisher/context/order';
import { getPinterestConversionUrl } from 'chairisher/context/tracking';

/**
 * @file functions for pinterest retargeting (shortened as 'PRT')
 */

function generateEventId() {
    const randomPart = Math.random().toString(36).substring(2);
    const timestamp = Date.now();
    return `${randomPart}_${timestamp}`;
}

function postPinterestConversionApi(eventName, obj, eventId) {
    const data = {
        event_name: eventName,
        action_source: 'web',
        event_id: eventId,
        custom_data: obj,
    };

    $.ajax({
        data: JSON.stringify(data),
        method: 'POST',
        contentType: 'application/json',
        url: getPinterestConversionUrl(),
    });
}

/**
 * Tracks a Pinterest event if possible; `window.pintrk` should not be loaded if the account has opted out so
 * in that case this function will be a no-op.
 *
 * @param {string} eventTagName Name of the event for Pinterest tag
 * @param {Object.<string>} obj
 * @param {string} eventConversionName Name of the event for Pinterest conversion API
 * @param {Object.<string>} obj
 */
function track(eventTagName, eventConversionName, obj) {
    const eventId = generateEventId();
    if (window.pintrk) {
        window.pintrk('track', eventTagName, {
            ...obj,
            event_id: eventId,
        });
    }
    if (eventConversionName) {
        postPinterestConversionApi(eventConversionName, obj, eventId);
    }
}

export function trackPageVisitForPRT() {
    const eventObj = {};
    const visitedProduct = ProductContext.getProductObject();

    if (visitedProduct.id) {
        eventObj.value = visitedProduct.price;
        eventObj.currency = 'USD';
        eventObj.line_items = [
            {
                product_name: visitedProduct.title,
                product_id: visitedProduct.id,
                product_price: visitedProduct.price,
            },
        ];
    }

    track('pagevisit', 'page_visit', eventObj);
}

/**
 * @param {string} query
 */
export function trackSearchForPRT(query) {
    track('search', 'search', {
        search_query: query,
    });
}

export function trackCheckoutForPRT() {
    const lineItems = getOrderItems().map((orderItem) => {
        const product = getProductIdToProductMap()[orderItem.product_id];
        return {
            product_name: product.title,
            product_id: product.id,
            product_quantity: 1,
        };
    });

    const order = getOrder();

    track('checkout', 'checkout', {
        currency: 'USD',
        line_items: lineItems,
        order_id: order.id,
        order_quantity: lineItems.length,
        promo_code: order.promotionCode,
        value: order.chairishFee,
    });
}

export function trackAddToCartForPRT() {
    const addedProduct = ProductContext.getProductObject();

    track('addtocart', 'add_to_cart', {
        currency: 'USD',
        line_items: [
            {
                product_id: addedProduct.id,
                product_name: addedProduct.title,
                product_price: addedProduct.price,
                product_quantity: 1,
            },
        ],
        order_quantity: addedProduct.quantity,
        value: addedProduct.price,
    });
}

export function trackAccountCreatedForPRT() {
    track('signup', 'signup');
}

export function trackOfferCreatedForPRT() {
    const offer = OfferContext.getOffer();
    const product = ProductContext.getProductObject();

    track('custom', 'custom', {
        currency: 'USD',
        line_items: [
            {
                product_name: product.title,
                product_id: product.id,
                product_price: offer.chairishFee,
                product_quantity: 1,
            },
        ],
        order_id: offer.id,
        value: offer.chairishFee,
    });
}

export function trackFavoriteCreatedForPRT(product) {
    track('favorite', null, {
        currency: 'USD',
        line_items: [
            {
                product_id: product.id,
                product_name: product.title,
                product_price: product.price,
                product_quantity: 1,
            },
        ],
    });
}
