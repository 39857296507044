import $ from 'jquery';

const paginationEventNamespace = 'pagination';

/**
 * Binds all necessary events to make the "back to top" button work
 */
export default function bindBackToTop() {
    const $backToTop = $('.js-back-to-top');
    const $masthead = $('.js-masthead-container');
    const $window = $(window);

    $backToTop.off('click').on('click', (e) => {
        e.preventDefault();
        $('html,body').animate({ scrollTop: 0 }, 'slow');
    });

    // reset bindings...
    $window.off(`scroll.${paginationEventNamespace}`);
    $window.off(`resize.${paginationEventNamespace}`);

    // affix pagination and display "back to top"
    $window.smartscroll(() => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        const isBackToTopVisible = $backToTop.hasClass('opaque');
        const innerHeight = window.innerHeight - $masthead.height();

        if (!isBackToTopVisible && innerHeight <= scrollTop) {
            $backToTop.addClass('opaque');
        } else if (isBackToTopVisible && innerHeight > scrollTop) {
            $backToTop.removeClass('opaque');
        }
    }, paginationEventNamespace);
}
