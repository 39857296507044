/**
 * Utility to indicate what a given key code represents
 */
const KeyUtils = {
    /**
     * @enum {number}
     */
    KeyCodes: {
        Backspace: 8,
        Tab: 9,
        Enter: 13,
        Escape: 27,
        Space: 32,
        Arrows: [37, 38, 39, 40],
        ArrowLeft: 37,
        ArrowUp: 38,
        ArrowRight: 39,
        ArrowDown: 40,
        Decimal: 46,
        Digits: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57],
        Digit0: 48,
        Digit1: 49,
        Digit2: 50,
        Digit3: 51,
        Digit4: 52,
        Digit5: 53,
        Digit6: 54,
        Digit7: 55,
        Digit8: 56,
        Digit9: 57,
        OS_Key1: 91, // windows key, left command key
        OS_Key2: 93, // windows menu, right command key
        NumpadDecimal: 110,
        Dash: 189,
    },

    /**
     * @param {number} code
     * @returns {boolean} True indicates the given code represents an alpha key
     */
    isAlpha(code) {
        return code > 64 && code < 91;
    },

    /**
     * @param {number} code
     * @returns {boolean} True indicates the given code represents an arrow key
     */
    isArrow(code) {
        return KeyUtils.KeyCodes.Arrows.includes(code);
    },

    /**
     * @param {number} code The keycode to check
     * @returns {boolean} True indicates the given code is for the enter key
     */
    isEnter(code) {
        return KeyUtils.KeyCodes.Enter === code;
    },

    /**
     * @param {number} code The keycode to check
     * @returns {boolean} True indicates the given code is for the escape key
     */
    isEscape(code) {
        return KeyUtils.KeyCodes.Escape === code;
    },

    /**
     * @param {number} code The keycode to check
     * @returns {boolean} True indicates the given code represents a numeric key
     */
    isNumeric(code) {
        return KeyUtils.KeyCodes.Digits.includes(code);
    },

    /**
     * @param {number} code The keycode to check
     * @returns {boolean} True indicates the given code is for the spacebar
     */
    isSpacebar(code) {
        return KeyUtils.KeyCodes.Space === code;
    },
};

export default KeyUtils;
