import { getUrlName } from 'chairisher/context/site';
import { AmplitudeEventProperty, logAmplitudeEvent, logAmplitudeLinkClickEvent } from 'chairisher/util/analytics';

/**
 * Logs an impression for a promoted listing
 *
 * @param {number} productId
 * @param {string} analyticsPosition
 * @param {ProductRailSubType=} subtype
 */
export function logPromotedListingImpression(productId, analyticsPosition, subtype = null) {
    logAmplitudeEvent('promoted listing - impression', {
        [AmplitudeEventProperty.URL_NAME]: getUrlName(),
        [AmplitudeEventProperty.POSITION]: analyticsPosition,
        [AmplitudeEventProperty.PRODUCT_ID]: productId,
        [AmplitudeEventProperty.SUB_TYPE]: subtype,
    });
}

/**
 * Logs an impression for a promoted listing house ad
 *
 * @param {number} productId
 * @param {string} analyticsPosition
 * @param {ProductRailSubType=} subtype
 */
export function logHouseAdImpression(productId, analyticsPosition, subtype = null) {
    logAmplitudeEvent('house ad - impression', {
        [AmplitudeEventProperty.URL_NAME]: getUrlName(),
        [AmplitudeEventProperty.POSITION]: analyticsPosition,
        [AmplitudeEventProperty.PRODUCT_ID]: productId,
        [AmplitudeEventProperty.SUB_TYPE]: subtype,
    });
}

/**
 * Logs an impression for a promoted shop ad
 *
 * @param {string} analyticsPosition
 * @param {string} dealerGuid
 */
export function logShopAdImpression(analyticsPosition, dealerGuid) {
    logAmplitudeEvent('shop ad - impression', {
        [AmplitudeEventProperty.URL_NAME]: getUrlName(),
        [AmplitudeEventProperty.POSITION]: analyticsPosition,
        [AmplitudeEventProperty.DEALER_GUID]: dealerGuid,
    });
}

/**
 * Logs a link click for a promoted shop ad
 *
 * @param {string} adName
 * @param {string} adUrl
 * @param {string} analyticsPosition
 * @param {string} dealerGuid
 */
export function logShopAdClick(adName, adUrl, analyticsPosition, dealerGuid) {
    logAmplitudeLinkClickEvent(adName, adUrl, analyticsPosition, {
        [AmplitudeEventProperty.DEALER_GUID]: dealerGuid,
    });
}
