const ChairishContext = window.chairisher.context;

/**
 * Datastructure for keeping track of Facebook related variables in chairisher.context
 */
const FacebookContext = {
    /**
     * @returns {string}
     */
    getAppId() {
        return ChairishContext.FACEBOOK_APP_ID;
    },

    /**
     * @returns {string}
     */
    getGraphApiVersion() {
        return ChairishContext.FACEBOOK_GRAPH_API_VERSION;
    },

    /**
     * @returns {XMLHttpRequest|undefined}
     */
    getLoginStatusResponse() {
        return ChairishContext.FACEBOOK_LOGIN_STATUS_RESPONSE;
    },

    /**
     * @param {XMLHttpRequest} xhr
     */
    setLoginStatusResponse(xhr) {
        ChairishContext.FACEBOOK_LOGIN_STATUS_RESPONSE = xhr;
    },

    /**
     * @returns {boolean} True indicates the Facebook JS SDK is loaded, false otherwise
     */
    isFacebookSdkLoaded() {
        return !!ChairishContext.IS_FACEBOOK_LOADED;
    },

    /**
     * @param {boolean} isFacebookLoaded True indicates the Facebook JS SDK is loaded, false otherwise
     */
    setFacebookSdkLoaded(isFacebookLoaded) {
        ChairishContext.IS_FACEBOOK_LOADED = !!isFacebookLoaded;
    },
};

export default FacebookContext;
