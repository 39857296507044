const ChairishContext = window.chairisher.context;

/**
 * An interface that provides documentation and access to server-side errors.
 */
const ErrorContext = {
    /**
     * @returns {string}
     */
    getGenericPaymentError() {
        return ChairishContext.GENERIC_PAYMENT_ERROR;
    },

    /**
     * @returns {string} Error message indicating there was a connection error with Braintree
     */
    getBraintreeConnectionError() {
        return ChairishContext.BRAINTREE_CONNECTION_ERROR;
    },

    /**
     * @returns {string} The default error to display on checkboxes
     */
    getDefaultCheckboxError() {
        return ChairishContext.DEFAULT_CHECKBOX_ERROR;
    },

    /**
     * @returns {string} Generic error text to use when something really goes wrong
     */
    getDefaultErrorText() {
        return ChairishContext.DEFAULT_ERROR_TEXT;
    },

    /**
     * @returns {string} Django key for all validation errors that aren't attributed to a specific form field
     */
    getDjangoNonFieldErrorsKey() {
        return ChairishContext.DJANGO_NON_FIELD_ERRORS_KEY;
    },

    /**
     * @returns {string} A generic error string indicating to fix above errors in the section
     */
    getFixAllAboveError() {
        return ChairishContext.FIX_ABOVE_ERRORS;
    },

    /**
     * @returns {string} Error message to render when trying to add a product when the max curation limit is hit
     */
    getMaxCurationErrorString() {
        return ChairishContext.MAX_CURATION_ERROR_STRING;
    },

    /**
     * @returns {string} Error text asking the user to contact support to use when something really goes wrong
     */
    getSupportErrorText() {
        return ChairishContext.SUPPORT_ERROR_TEXT;
    },

    /**
     * @returns {string} The error string to display when someone tries to perform an action without accepting the TOS
     */
    getTosErrorString() {
        return ChairishContext.TOS_ERROR_STRING;
    },

    /**
     * @returns {string} A generic error string to display when submit fails
     */
    getGenericSubmitErrorString() {
        return ChairishContext.GENERIC_SUBMIT_ERROR_STRING;
    },

    /**
     * @returns {string}
     */
    getRequiredFieldErrorString() {
        return ChairishContext.REQUIRED_FIELD_ERROR_STRING;
    },

    /**
     * @returns {boolean} Whether or not a 404 has occurred.
     */
    is404() {
        return ChairishContext.IS_404 || false;
    },
};

export default ErrorContext;
