import $ from 'jquery';

import trackGlobalSearch from 'chairisher/analytics/globalsearch';

import {
    AmplitudeEventProperty,
    BusinessSearchPosition,
    logAmplitudeEvent,
    logAmplitudeLinkClickEvent,
    SearchPosition,
} from 'chairisher/util/analytics';

/**
 * Track a link click event on the design pro search page.
 *
 * @param {Event} event Event object to track
 * @param {string} position Position of the click on the page
 * @param {string=} ctaName Optional override cta name
 * @param {string=} ctaUrl Optional override of cta url
 */
function trackLinkClickEvent(event, position, ctaName, ctaUrl) {
    const $link = $(event.currentTarget);
    const theCtaName = ctaName || $link.text().trim();
    const theCtaUrl = ctaUrl || $link.attr('href');
    logAmplitudeLinkClickEvent(theCtaName, theCtaUrl, position);
}

/**
 * Track a click on the city collection rail.
 *
 * @param {Event} event Click event to track
 */
export function trackCityCollectionRailClick(event) {
    trackLinkClickEvent(event, BusinessSearchPosition.CITY_COLLECTION_RAIL);
}

/**
 * Track a click on the designer contact link.
 *
 * @param {Event} event Click event to track
 * @param {string=} ctaUrl Optional override of cta url
 */
export function trackDesignerContact(event, ctaUrl) {
    trackLinkClickEvent(event, BusinessSearchPosition.DESIGNER_CONTACT, 'Contact Designer', ctaUrl);
}

/**
 * Track impressions for designer businesses
 *
 * @param {string} ctaPosition Position where event occurred
 * @param {boolean} isDmaMatch Flag to indicate if business is a DMA match
 * @param {string} shopUrl The shop url for a given business
 */
export function trackDesignerBusinessImpressions(ctaPosition, isDmaMatch, shopUrl) {
    logAmplitudeEvent('designer impression', {
        [AmplitudeEventProperty.CTA_POSITION]: ctaPosition,
        [AmplitudeEventProperty.IS_DMA_MATCH]: isDmaMatch,
        [AmplitudeEventProperty.SHOP_URL]: shopUrl,
    });
}

/**
 * Track a click on the popular location rail.
 *
 * @param {Event} event Click event to track
 */
export function trackPopularLocationRailClick(event) {
    trackLinkClickEvent(event, BusinessSearchPosition.POPULAR_LOCATION_RAIL);
}

/**
 * Track a click on the bottom pagination.
 *
 * @param {Event} event Click event to track.
 */
export function trackPaginationBottomClick(event) {
    trackLinkClickEvent(event, SearchPosition.BOTTOM_PAGINATION);
}

/**
 * Track a click on the top pagination.
 *
 * @param {Event} event click event to track
 */
export function trackPaginationTopClick(event) {
    trackLinkClickEvent(event, SearchPosition.TOP_PAGINATION);
}

/**
 * Track a business search being submitted.
 *
 * @param {Object.<string, string>} refinementsObj Refinements by which the search is filtered
 */
export function trackSearchSubmit(refinementsObj) {
    const refinements = refinementsObj;

    // Coerce <select> to its displayed value so that the result is human-readable.
    Object.keys(refinements).forEach((name) => {
        const $el = $(`[name=${name}]`);
        if ($el.is('select')) {
            refinements[name] = $el.find('option:selected').text();
        }
    });

    trackGlobalSearch('designer directory', null, null, null, refinements);
}
