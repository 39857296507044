import ChairishABTest from 'chairisher/abtest';

/**
 * AB Test for determining if increasing the shipping costs has a negative impact on conversion,
 **
 * TODO: (CHAIR-17547) Delete file after AB test
 */
export default class ShippingMarginTest extends ChairishABTest {
    static getExperimentName() {
        return 'SHIPPING_MARGIN_ABC_TEST_V5';
    }
}
