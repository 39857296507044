import $ from 'jquery';
import { highlightNonMatchingQueryText } from 'chairisher/util/format';
import KeyUtils from 'chairisher/util/key';
import Menu from 'chairisher/component/menu';

const { KeyCodes } = KeyUtils;

/**
 * @callback responseFilterCallback
 * @param {Array.<Choice>} choices The choices to filter
 * @returns {Array.<Choice>} The filtered choices
 */

/**
 * Autocomplete queries a given endpoint and presents the available choices via a simple dropdown menu.
 *
 * @param {Object} settings Object of settings used to customize the Autocomplete instance
 * @param {jQuery=} settings.$menuContainerEl @see this._$menuContainerEl
 * @param {jQuery} settings.$queryEl @see this._$queryEl
 * @param {boolean} settings.areOnlyLeafNodesSelectable @see this._areOnlyLeafNodesSelectable
 * @param {Object=} settings.dataObject @see this._dataObject
 * @param {string} settings.dataEndpoint @see this._dataEndpoint
 * @param {boolean=} settings.isGlobalSearch @see this.isGlobalSearch
 * @param {boolean=} settings.isMultiSelect @see this._isMultiSelect
 * @param {boolean=} settings.menuChoiceHasThumbnail @see this._menuChoiceHasThumbnail
 * @param {string=} settings.menuChoiceContentTemplateSelector @see this._menuChoiceContentTemplateSelector
 * @param {string=} settings.menuElClasses @see this._menuElClasses
 * @param {number=} settings.placeholderIndex @see this._placeholderIndex
 * @param {responseFilterCallback=} settings.responseFilterCallback @see this._responseFilterCallback
 * @param {boolean=} settings.shouldBoldNonQueryTextResults @see this._shouldBoldNonQueryTextResults
 * @param {boolean=} settings.shouldHideAutocompleteText @see this.shouldHideAutocompleteText
 * @param {boolean=} settings.shouldSubmitFormOnEnter @see this._shouldSubmitFormOnEnter
 * @param {boolean=} settings.shouldTabAndEnterComplete @see this._shouldTabAndEnterCompete
 *
 * @constructor
 * @class Autocomplete
 */
const Autocomplete = function (settings) {
    settings = $.extend(
        {
            $menuContainerEl: this._$menuContainerEl,
            $queryEl: this._$queryEl,
            areOnlyLeafNodesSelectable: this._areOnlyLeafNodesSelectable,
            dataDisplayKey: this._dataDisplayKey,
            dataObject: {},
            dataEndpoint: this._dataEndpoint,
            isGlobalSearch: this.isGlobalSearch,
            isMultiSelect: this._isMultiSelect,
            limit: this._limit,
            menuChoiceContentTemplateSelector: this._menuChoiceContentTemplateSelector,
            menuChoiceHasThumbnail: this._menuChoiceHasThumbnail,
            menuElClasses: this._menuElClasses,
            minQueryLength: this._minQueryLength,
            placeholderIndex: this._placeholderIndex,
            responsefilterCallback: this._responseFilterCallback,
            shouldBoldNonQueryTextResults: this._shouldBoldNonQueryTextResults,
            shouldHideAutocompleteText: this.shouldHideAutocompleteText,
            shouldSubmitFormOnEnter: this._shouldSubmitFormOnEnter,
            shouldTabAndEnterComplete: this._shouldTabAndEnterComplete,
        },
        settings,
    );

    this._$queryEl = settings.$queryEl;
    this._$menuContainerEl = settings.$menuContainerEl;

    this._areOnlyLeafNodesSelectable = settings.areOnlyLeafNodesSelectable;
    this._dataObject = settings.dataObject;
    this._dataDisplayKey = settings.dataDisplayKey;
    this._dataEndpoint = settings.dataEndpoint;
    this.isGlobalSearch = settings.isGlobalSearch;
    this._isMultiSelect = settings.isMultiSelect;
    this._limit = settings.limit;
    this._menuChoiceContentTemplateSelector = settings.menuChoiceContentTemplateSelector;
    this._menuChoiceHasThumbnail = !!settings.menuChoiceHasThumbnail;
    this._menuElClasses = settings.menuElClasses;
    this._minQueryLength = settings.minQueryLength;
    this._placeholderIndex = settings.placeholderIndex;
    this._placeholderText = this._$queryEl.attr('placeholder');
    this._responseFilterCallback = settings.responseFilterCallback;
    this._shouldBoldNonQueryTextResults = settings.shouldBoldNonQueryTextResults;
    this.shouldHideAutocompleteText = settings.shouldHideAutocompleteText;
    this._shouldSubmitFormOnEnter = settings.shouldSubmitFormOnEnter;
    this._shouldTabAndEnterComplete = settings.shouldTabAndEnterComplete;

    this._render();
    this._bind();
};

/**
 * jQuery element for storing the "X" clear search button
 *
 * @type {jQuery}
 */
Autocomplete.prototype.$clearSearchBtn = null;

/**
 * jQuery element that will serve as a wrapper for the query input
 *
 * @type {jQuery}
 * @private
 */
Autocomplete.prototype._$el = null;

/**
 * Optional jQuery element to append the menu to; if a function, it must return a jQuery object
 *
 * @type {jQuery|function}
 * @private
 */
Autocomplete.prototype._$menuContainerEl = null;

/**
 * jQuery element where queries can be input
 *
 * @type {jQuery}
 * @private
 */
Autocomplete.prototype._$queryEl = null;

/**
 * Flag that indicates if all nodes are selectable or just leaf nodes.
 *
 * @type {boolean}
 * @private
 * @default
 */
Autocomplete.prototype._areOnlyLeafNodesSelectable = false;

/**
 * The key found in a choice whose value should be displayed in the query field upon selection
 *
 * @type {string}
 * @private
 * @default
 */
Autocomplete.prototype._dataDisplayKey = 'display';

/**
 * The endpoint on the server that returns autocomplete choices
 *
 * @type {string}
 * @private
 */
Autocomplete.prototype._dataEndpoint = null;

/**
 * Object that contains GET parameters (excluding query) to pass in to `this._dataEndpoint`
 *
 * @type {Object}
 * @private
 */
Autocomplete.prototype._dataObject = null;

/**
 * Flag that indicates if there choices came back from the server
 *
 * @type {boolean}
 * @private
 * @default
 */
Autocomplete.prototype._hasChoices = false;

/**
 * Flag that indicates if autocomplete is part of the global search
 *
 * @type {boolean}
 * @default
 */
Autocomplete.prototype.isGlobalSearch = false;

/**
 * Flag that indicates if the choices are multi-select or not
 *
 * @type {boolean}
 * @private
 */
Autocomplete.prototype._isMultiSelect = true;

/**
 * Limits the number of results autocomplete will have
 *
 * @type {number}
 * @private
 * @default
 */
Autocomplete.prototype._limit = null;

/**
 * The timer id that gets set before firing an ajax request
 *
 * @type {number|undefined}
 * @private
 */
Autocomplete.prototype._keyTimerId = null;

/**
 * The most recent query string found in the `.js-autocomplete-query` element
 *
 * @type {string}
 * @private
 */
Autocomplete.prototype._lastQueryString = '';

/**
 * Instance of a menu used to display choices
 *
 * @type {Menu}
 * @private
 */
Autocomplete.prototype._menu = null;

/**
 * Selector of the element that contains the template to use when marshaling menu choices
 *
 * @type {string}
 * @private
 * @default
 */
Autocomplete.prototype._menuChoiceContentTemplateSelector = '#js-template-menu-choice-content';

/**
 * Flag that indicates if menu choices should expect to display a thumbnail image or not
 *
 * @type {boolean}
 * @private
 * @default
 */
Autocomplete.prototype._menuChoiceHasThumbnail = true;

/**
 * Classes to assign the menu DOM element
 *
 * @type {string}
 * @private
 * @default
 */
Autocomplete.prototype._menuElClasses = 'menu';

/**
 * Used to traverse the DOM to select the autocompleter's placeholder element
 *
 * @type {string}
 * @private
 * @default
 */
Autocomplete.prototype._placeHolderElSelector = '.js-autocomplete-placeholder';

/**
 * Placeholder text for the `.js-autocomplete-query` element
 *
 * @type {string}
 * @private
 */
Autocomplete.prototype._placeholderText = '';

/**
 * Placeholder index for items in dropdown menu
 *
 * @type {number}
 * @private
 */
Autocomplete.prototype._placeholderIndex = 0;

/**
 * Used to traverse the DOM to select the autocompleter's query element
 *
 * @type {string}
 * @private
 * @default
 */
Autocomplete.prototype._queryElSelector = '.js-autocomplete-query';

/**
 * A callback to execute that filters data sent back from the server
 *
 * @type {responseFilterCallback}
 * @private
 */
Autocomplete.prototype._responseFilterCallback = null;

/**
 * Whether or not the autocomplete is enabled.
 *
 * @type {boolean}
 * @private
 * @default
 */
Autocomplete.prototype._areQueriesEnabled = true;

/**
 * Default length of queryString required before a query can be attempted
 *
 * @type {number}
 * @private
 * @default
 */
Autocomplete.prototype._minQueryLength = 2;

/**
 * Flag that indicates whether query result that don't match should be bold
 *
 * @type {boolean}
 * @private
 * @default
 */
Autocomplete.prototype._shouldBoldNonQueryTextResults = false;

/**
 * Flag that indicates whether the autocomplete text should be hidden
 *
 * @type {boolean}
 * @default
 */
Autocomplete.prototype.shouldHideAutocompleteText = false;

/**
 * Flag that indicates whether pressing enter on a field submit the form
 *
 * @type {boolean}
 * @private
 * @default
 */
Autocomplete.prototype._shouldSubmitFormOnEnter = false;

/**
 * Flag that indicates whether pressing tab or enter on a field will fill it out with the first dropdown option
 *
 * @type {boolean}
 * @private
 * @default
 */
Autocomplete.prototype._shouldTabAndEnterComplete = false;

/**
 * @returns {boolean} True indicates there is enough info available to make a query
 */
Autocomplete.prototype.canMakeQuery = function () {
    const queryLength = this.getQueryText()?.length;
    return queryLength && queryLength >= this._minQueryLength;
};

/**
 * @returns {Menu} The Menu instance attached directly to this component
 */
Autocomplete.prototype.getMenu = function () {
    return this._menu;
};

/**
 * @returns {jQuery} The jQuery element that is used as a placeholder
 */
Autocomplete.prototype.getPlaceholderEl = function () {
    return this._$placeholderEl;
};

/**
 * @returns {jQuery} The jQuery element that is used for inputting queries
 */
Autocomplete.prototype.getQueryEl = function () {
    return this._$queryEl;
};

/**
 * @returns {string} The text entered into the query input
 */
Autocomplete.prototype.getQueryText = function () {
    return this.getQueryEl().val();
};

/**
 * @returns {boolean} True indicates choices are available for selection
 */
Autocomplete.prototype.hasChoices = function () {
    return this._hasChoices;
};

/**
 * @param {string} queryText The text to insert in the query element
 */
Autocomplete.prototype.setQueryText = function (queryText) {
    this.getQueryEl().val(this.sanitizeQueryString(queryText));
};

/**
 * @returns {boolean} True indicates the query input has text (including spaces) entered in it
 */
Autocomplete.prototype.hasQueryText = function () {
    return !!this.getQueryText().length;
};

/**
 * Hides the placeholder element and resets its text back to its default
 */
Autocomplete.prototype.hidePlaceholder = function () {
    this.getPlaceholderEl().val('');
};

/**
 * @returns {boolean} True indicates the `.js-autocomplete-placeholder` element is visible
 */
Autocomplete.prototype.isPlaceholderVisible = function () {
    return !this.getPlaceholderEl().hasClass('hidden');
};

/**
 * Removes strong tags and quotation marks from query string
 *
 * @param {string} queryText A display string that will be used in the search bar
 * @returns {string} A string that has no strong tags or quotation marks
 */
Autocomplete.prototype.sanitizeQueryString = function (queryText) {
    const regExp = new RegExp('<strong>|</strong>|"', 'gi');
    return queryText.replace(regExp, '');
};

/**
 * Displays the placeholder element unless text is present in the query element
 */
Autocomplete.prototype.showPlaceholder = function () {
    this.getPlaceholderEl().val(this._placeholderText);
};

/**
 * Disable queries while still allowing input.
 */
Autocomplete.prototype.disableQueries = function () {
    this._areQueriesEnabled = false;
};

/**
 * Enable queries without affecting ability to input text.
 */
Autocomplete.prototype.enableQueries = function () {
    this._areQueriesEnabled = true;
};

/**
 * Disable the autocomplete, preventing all queries.
 */
Autocomplete.prototype.disable = function () {
    this.disableQueries();
    this._$el.addClass('disabled');
    this._$el.find('input').prop('disabled', true);
};

/**
 * Enable the autocomplete, allowing queries.
 */
Autocomplete.prototype.enable = function () {
    this.enableQueries();
    this._$el.removeClass('disabled');
    this._$el.find('input').prop('disabled', false);
};

/**
 * Whether the autocomplete is enabled to make queries.
 *
 * @returns {boolean}
 */
Autocomplete.prototype.areQueriesEnabled = function () {
    return this._areQueriesEnabled;
};

/**
 * Whether the autocomplete is enabled to submit the form when a user presses enter.
 *
 * @returns {boolean}
 */
Autocomplete.prototype.shouldSubmitFormOnEnter = function () {
    return this._shouldSubmitFormOnEnter;
};

/**
 * Whether the autocomplete is enabled to fill in the input with the first menu item when a user presses tab or
 * enter.
 *
 * @returns {boolean}
 */
Autocomplete.prototype.shouldTabAndEnterComplete = function () {
    return this._shouldTabAndEnterComplete;
};

/**
 * Toggles the placeholder element's visibility. If text is present in the query element the placeholder will not
 * be displayed regardless of what is passed in.
 *
 * @param {boolean} shouldDisplay Optional flag indicating if the placeholder should be displayed or not
 * @private
 */
Autocomplete.prototype.togglePlaceholder = function (shouldDisplay) {
    if (shouldDisplay) {
        this.showPlaceholder();
    } else {
        this.hidePlaceholder();
    }
};

/**
 * Binds actions to the various elements that comprise the Autocompleter
 *
 * @private
 */
Autocomplete.prototype._bind = function () {
    const $queryEl = this.getQueryEl();

    $queryEl.closest('.js-form-group').on('click', (e) => {
        if (!$(e.target).is('input')) {
            // Clicking on an input triggers focus without any help.
            $queryEl.trigger('focus');
        }
    });

    $queryEl.on(
        'blur',
        $.proxy(function (e) {
            this.$clearSearchBtn.removeClass('has-focus');
            if (this.shouldTabAndEnterComplete()) {
                if (this._menu.getChoiceEls().eq(0).text() !== this.getQueryEl().val()) {
                    this.setQueryText('');
                    this._menu.updateSuggestionChoices([]);
                    this._trigger(e, 'autocomplete:blur');
                }
            } else {
                this._trigger(e, 'autocomplete:blur');
            }

            this.togglePlaceholder(!this.hasQueryText());
        }, this),
    );

    $queryEl.on(
        'autocomplete:blur',
        $.proxy(function (e, data) {
            window.setTimeout(
                $.proxy(function () {
                    this._menu.hide();
                }, this),
                100,
            );
        }, this),
    );

    $queryEl.on(
        'focus',
        $.proxy(function (e) {
            this.$clearSearchBtn.addClass('has-focus');
            this._trigger(e, 'autocomplete:focus');
            if (!this._menu.isVisible() && this._menu.getSuggestionChoices().length) {
                this._menu.show();
            }
        }, this),
    );

    $queryEl.on(
        'keydown',
        $.proxy(function (e) {
            this._trigger(e, 'autocomplete:keydown');

            const keyCode = e.which;

            // If there is text in the query element:
            //     - Down Arrow opens the menu if there are choices and it is not already opened
            //     - Up and Down Arrow scrolls through the choices is there are any and the menu is open
            //     - Right Arrow selects the first choice if the menu is open, there are menu choices, and none are selected
            //     - Left arrow key doesn't do anything special by default
            if (KeyUtils.isArrow(keyCode) && this.hasQueryText()) {
                if (this._menu.isVisible()) {
                    let newIndex = null;
                    const $selectedChoice = this._menu.getSelectedChoiceEl();
                    const hasSelectedChoice = $selectedChoice.length;

                    if (keyCode === KeyCodes.ArrowDown || keyCode === KeyCodes.ArrowUp) {
                        newIndex = 0;
                        if (hasSelectedChoice) {
                            newIndex =
                                this._menu.getIndexForEl($selectedChoice) + (keyCode === KeyCodes.ArrowUp ? -1 : 1);
                        }
                    } else if (keyCode === KeyCodes.ArrowRight && !hasSelectedChoice) {
                        newIndex = 0;
                    }

                    if (newIndex !== null) {
                        this._menu.deselectChoiceEl();
                        this._menu.makeSelection(newIndex);
                        this.hidePlaceholder();

                        const $menuEl = this._menu.getMenuEl();
                        const menuHeight = $menuEl.height();
                        const menuScrollTop = $menuEl.scrollTop();

                        const $selectedEl = this._menu.getSelectedChoiceEl();
                        const selectedEl = $selectedEl[0];
                        const selectedElHeight = $selectedEl.height();
                        const selectedElOffsetTop = selectedEl.offsetTop;

                        let newOffset = null;

                        if (selectedElOffsetTop + selectedElHeight > menuHeight + menuScrollTop) {
                            newOffset = selectedElOffsetTop - menuHeight + selectedElHeight;
                        } else if (selectedElOffsetTop < menuScrollTop) {
                            newOffset = selectedElOffsetTop;
                        }

                        if (newOffset !== null) {
                            $menuEl.scrollTop(newOffset);
                        }

                        const selectedChoice = this._menu.getSuggestionChoices()[this._menu.getIndexForEl($selectedEl)];
                        this.setQueryText(selectedChoice[this._dataDisplayKey].trim());
                    }
                } else if (keyCode === KeyCodes.ArrowDown && this._menu.getSuggestionChoices().length) {
                    this._menu.show();
                }
            } else if (keyCode === KeyCodes.Enter || keyCode === KeyCodes.Tab) {
                let $selected = this._menu.getSelectedChoiceEl();
                if (this.shouldTabAndEnterComplete() && !$selected.length) {
                    $selected = this._menu.getChoiceEls().eq(0);
                }
                if ($selected.length) {
                    this.setQueryText($selected.text());
                    // clients should handle the `autocomplete:selected` event if they want something to happen
                    // other than the input being filled in with the selected value...
                    e.preventDefault();

                    const selected = this._menu.getSuggestionChoices()[this._menu.getIndexForEl($selected)];

                    this._trigger(e, 'autocomplete:selected', [selected, $selected.index('.js-menu-choice')]);

                    if (this.shouldSubmitFormOnEnter() && keyCode === KeyCodes.Enter) {
                        this._trigger(e, 'autocomplete:submit');
                    }

                    if (keyCode === KeyCodes.Tab) {
                        this._menu.updateSuggestionChoices([selected]);
                        this._menu.hide();
                        this._menu.clearAllSelections();
                    }
                }
            }
        }, this),
    );

    $queryEl.on(
        'keypress',
        $.proxy(function (e) {
            this._trigger(e, 'autocomplete:keypress');
        }, this),
    );

    $queryEl.on(
        'keyup input',
        $.proxy(function (e) {
            const keyCode = e.which;
            const eventType = e.type;
            const queryText = this.getQueryText().trim();

            this.$clearSearchBtn.toggleClass('hidden', !this.hasQueryText());
            this._trigger(e, `autocomplete:${eventType}`);

            if ($.inArray(keyCode, [KeyCodes.Escape, KeyCodes.Enter, KeyCodes.Tab]) !== -1) {
                this._menu.hide();

                // hide suggestion text if there is any, otherwise
                // mimic default placeholder text functionality...
                this.togglePlaceholder(!this.hasQueryText());
                return;
            }

            if (queryText && this.isPlaceholderVisible()) {
                this.hidePlaceholder();
            }

            const shouldAttemptFetch =
                this.areQueriesEnabled() &&
                ((eventType === 'input' && queryText.length) ||
                    (keyCode === KeyCodes.ArrowDown && !this._menu.isVisible()) ||
                    (!KeyUtils.isArrow(keyCode) && queryText !== this._lastQueryString) ||
                    (!KeyUtils.isArrow(keyCode) && $.inArray(keyCode, [KeyCodes.OS_Key1, KeyCodes.OS_Key2]) === -1));

            this._lastQueryString = queryText;

            if (shouldAttemptFetch) {
                if (this.canMakeQuery()) {
                    // throttle the number of AJAX requests by setting a timer:
                    //
                    // when the timer expires execute the last pending AJAX request, but if
                    // another non-arrow key has been pressed before then, cancel the existing
                    // timer and restart the clock before sending the request

                    this._clearOutgoingRequests();

                    this._keyTimerId = window.setTimeout(
                        $.proxy(function () {
                            if (!this.canMakeQuery()) {
                                return; // avoid subtle timing issues...
                            }

                            this.fetchData();
                        }, this),
                        300,
                    );
                } else {
                    this._menu.hide();
                    this._menu.updateSuggestionChoices([]);
                    if (!this.hasQueryText()) {
                        this.showPlaceholder();
                    }
                }
            }
        }, this),
    );

    this.$clearSearchBtn.on('click', () => {
        this.$clearSearchBtn.addClass('hidden');
        this.setQueryText('');
        this._menu.hide();
        this._menu.updateSuggestionChoices([]);
        $queryEl.trigger('focus');
    });

    this._menu.getMenuEl().on(
        'menuchoice:selected',
        $.proxy(function (e, data) {
            this.setQueryText(data[this._dataDisplayKey]);
            this._menu.updateSuggestionChoices([data]);
            this.hidePlaceholder();
            if (!this._isMultiSelect) {
                this._menu.hide();
            }
            this._trigger(e, 'autocomplete:selected', data);
        }, this),
    );
};

/**
 * Fetches data from the server at a given endpoint for a given entity type
 *
 * @returns {$.Deferred}
 * @private
 */
Autocomplete.prototype.fetchData = function () {
    this._trigger($.Event(), 'autocomplete:search');
    const dataObject = $.extend({}, this._dataObject, {
        path_name: document.location.pathname,
        query: this.getQueryText(),
    });

    return $.ajax({
        data: dataObject,
        url: this._dataEndpoint,
    }).done(
        $.proxy(function (data) {
            this._keyTimerId = undefined;

            let { choices } = data;

            if ($.isFunction(this._responseFilterCallback)) {
                choices = this._responseFilterCallback(data.choices);
            }

            if (this._limit && choices.length) {
                choices = choices.slice(0, this._limit);
            }

            if (this._shouldBoldNonQueryTextResults) {
                choices.forEach((choice, index) => {
                    if (index !== 0) {
                        choice.display = highlightNonMatchingQueryText(this.getQueryText(), choice.display);
                    }
                });
            }

            this._menu.updateSuggestionChoices(choices);
            this._menu.toggle(choices.length);

            this._hasChoices = !!choices.length;

            // if there are choices, combine the query and placeholder text to
            // suggest the first choice with the appearance of the query being
            // completed by the placeholder...
            if (this.hasChoices()) {
                const queryText = this.getQueryText();

                const placeholderIndex = this._placeholderIndex >= choices.length ? 0 : this._placeholderIndex;

                const choiceText = choices[placeholderIndex][this._dataDisplayKey];

                const sanitizedChoiceText = this.sanitizeQueryString(choiceText);

                const trimmedChoiceText = sanitizedChoiceText.toLowerCase().trim();
                const trimmedQueryText = queryText.toLowerCase().trim();

                if (trimmedChoiceText.indexOf(trimmedQueryText) === 0) {
                    this.getPlaceholderEl().val(queryText + sanitizedChoiceText.substring(queryText.length));

                    if (!this.isPlaceholderVisible()) {
                        this.showPlaceholder();
                    }
                }
                this._menu.getMenuEl().scrollTop(0);
            } else {
                const noResultsEventType = 'autocomplete:noresults';
                this._trigger($.Event(noResultsEventType), noResultsEventType, {
                    query: this.getQueryText(),
                });
            }
        }, this),
    );
};

/**
 * @param {string} key The key in the data object to update
 * @param {string|number|Array|Object} value The value to set
 */
Autocomplete.prototype.updateDataObject = function (key, value) {
    this._dataObject[key] = value;
};

/**
 * Clears the timer that keeps track of when to send requests, effectively canceling them
 *
 * @private
 */
Autocomplete.prototype._clearOutgoingRequests = function () {
    if (this._keyTimerId !== undefined) {
        window.clearTimeout(this._keyTimerId);
    }
};

/**
 * Renders the widget. If the plugin is being used on a text input, then
 * marshal the appropriate structure and replace the text field with the new structure...
 *
 * @private
 */
Autocomplete.prototype._render = function () {
    // ensure the query input is housed in a wrapper element and has a
    // placeholder element as a sibling so we can provide autocompleted suggestions...
    this._$el = $('<div></div>', {
        class: 'autocomplete-input js-autocomplete-input',
    });

    this._$placeholderEl = $('<input />', {
        'aria-hidden': true,
        'class': 'autocomplete-placeholder js-autocomplete-placeholder',
        'readonly': 'true',
        'tabindex': -1, // prevent the placeholder input from being focusable
    });

    const $queryEl = this.getQueryEl();
    this._$el.append(this.getPlaceholderEl());
    this._$el.insertAfter($queryEl);
    $queryEl.detach();
    this._$el.append($queryEl);

    this.$clearSearchBtn = this._$el.siblings('.js-autocomplete-btn-container').find('.js-btn-clear-search');
    this.$clearSearchBtn.toggleClass('hidden', !$queryEl.val());

    this._menu = new Menu({
        areOnlyLeafNodesSelectable: this._areOnlyLeafNodesSelectable,
        dataDisplayKey: this._dataDisplayKey,
        isGlobalSearch: this.isGlobalSearch,
        isMultiSelect: this._isMultiSelect,
        menuChoiceContentTemplateSelector: this._menuChoiceContentTemplateSelector,
        menuChoiceHasThumbnail: this._menuChoiceHasThumbnail,
        menuElClasses: this._menuElClasses,
    });
    this._menu.hide();

    if (this._$menuContainerEl) {
        const $containerEl = $.isFunction(this._$menuContainerEl) ? this._$menuContainerEl() : this._$menuContainerEl;
        $containerEl.append(this._menu.getMenuEl());
    } else {
        this._$el.append(this._menu.getMenuEl());
    }

    // ensure that that placeholder element mirrors various essential styles
    // that are found on the query input so autocompleted suggestions line up properly...
    const cssPropertiesToCopy = {};
    $.each(
        [
            'background',
            'border',
            'font-family', // need to expand font to keep firefox happy
            'font-size', // need to expand font to keep firefox happy
            'font-style', // need to expand font to keep firefox happy
            'font-weight', // need to expand font to keep firefox happy
            'letter-spacing',
            'line-height',
            'margin-bottom', // need to expand margin to keep firefox happy in some instances...
            'margin-left',
            'margin-right',
            'margin-top',
            'padding-bottom', // need to expand padding to keep firefox happy in some instances...
            'padding-left',
            'padding-right',
            'padding-top',
            'text-transform',
        ],
        (i, prop) => {
            cssPropertiesToCopy[prop] = $queryEl.css(prop);
        },
    );

    cssPropertiesToCopy.height = '100%';

    // move attributes to placeholder
    this.getPlaceholderEl()
        .css(cssPropertiesToCopy)
        .val($queryEl.val() || this._placeholderText);

    const queryElCss = {
        position: 'relative',
    };

    if (this.shouldHideAutocompleteText) {
        this._$placeholderEl.addClass('hidden');
    } else {
        queryElCss.background = 'transparent';
    }

    $queryEl.css(queryElCss).attr({
        autocomplete: 'off',
        spellcheck: false,
        dir: 'auto',
    });

    if (this.isGlobalSearch) {
        $queryEl.attr({
            autocorrect: 'off',
        });
    }
};

/**
 * Triggers a new event while passing along the original event and any other additional data provided
 *
 * @param {jQuery.Event} e The original jQuery event
 * @param {string} type The new event's type
 * @param {Object=} opt_data Additional data to be passed along as an argument to the event handler
 * @private
 */
Autocomplete.prototype._trigger = function (e, type, opt_data) {
    const newEvent = $.extend({}, e); // clone so we don't modify the original event object
    newEvent.type = type; // overwrite e.type before creating the event since e.type overrides the 1st arg type arg
    const event = $.Event(type, newEvent);

    this.getQueryEl().trigger(event, opt_data);
};

export default Autocomplete;
