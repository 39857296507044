import { AmplitudeEventProperty, logAmplitudeEvent } from 'chairisher/util/analytics';

/**
 * Track a cookie setting change on the cookie banner
 *
 * @param {string} cookieCode code of the cookie setting being changed
 * @param {boolean} isOptedOut whether the user is opting out of the setting
 */
export default function trackCookieSettingChange(cookieCode, isOptedOut) {
    logAmplitudeEvent('cookie settings change', {
        [AmplitudeEventProperty.CODE]: cookieCode,
        [AmplitudeEventProperty.IS_OPT_OUT]: isOptedOut,
    });
}
