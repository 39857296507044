/*
 * @file Wrapper functions for https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 */

import CookieUtils from 'chairisher/util/cookie';

/**
 * An exception that is thrown when a fetch function receives an unexpected response.
 */
export class FetchError extends Error {
    constructor({ json, method, status, statusText, url }) {
        super(`${method} ${url} returned ${status} ${statusText}`);
        this.status = status;
        this.json = json;
    }

    /**
     * @returns {String|undefined} The first error message in the json response (not always present).
     */
    get firstErrorMessage() {
        return this.json?.errors?.[0]?.message;
    }
}

const jsonType = 'application/json';

const chairishFetchHeaders = {
    'x-requested-with': 'XMLHttpRequest', // See the python is_ajax function.
};

const jsonHeaders = {
    'Accept': jsonType,
    'Content-Type': jsonType,
    ...chairishFetchHeaders,
};

function getCsrfHeader() {
    return { 'X-CSRFToken': CookieUtils.getValueFromCookie('csrftoken') };
}

async function chairishFetch(url, options) {
    const response = await fetch(url, options);
    let json;
    if (response.headers.get('Content-Type')?.includes(jsonType)) {
        json = await response.json();
    }
    if (response.ok) {
        return json;
    }

    throw new FetchError({
        json,
        method: options.method,
        status: response.status,
        statusText: response.statusText,
        url,
    });
}

/**
 * Sends a GET request to the given url.
 *
 * @param url {String}
 * @return {Promise<Object>}
 * @throws {FetchError}
 */
export function getJSON(url) {
    return chairishFetch(url, {
        method: 'GET',
        headers: jsonHeaders,
    });
}

/**
 * Sends a POST request with a json body to the given url.
 *
 * @param {String} url
 * @param {Object=} body Object to json stringify and set as the request body
 * @return {Promise<Object>}
 * @throws {FetchError}
 */
export function postJSON(url, body = {}) {
    return chairishFetch(url, {
        method: 'POST',
        headers: { ...getCsrfHeader(), ...jsonHeaders },
        body: JSON.stringify(body),
    });
}

/**
 * Submits the given html form asynchronously with fetch.
 *
 * @param {HTMLFormElement} form
 * @param {Object<string, string>=} extraData
 * @return {Promise<Object>}
 * @throws {FetchError}
 */
export function submitForm(form, extraData = {}) {
    const body = new FormData(form);
    Object.entries(extraData).forEach(([key, val]) => {
        body.set(key, val);
    });
    return chairishFetch(form.action, {
        body,
        headers: { ...getCsrfHeader(), ...chairishFetchHeaders },
        method: form.method,
    });
}
