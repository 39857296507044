const ReferrerUtils = {
    /**
     * @returns {string}
     */
    getReferrer() {
        return window.document.referrer;
    },

    /**
     * Parse the domain from the referrer info. https://www.facebook.com/graph => facebook.com
     * @param {string} referrer
     * @returns {string|null}
     */
    getReferringDomain(referrer) {
        if (!referrer || referrer.length === 0) {
            return null;
        }
        const parts = referrer.split('/');
        if (parts.length >= 3) {
            return parts[2];
        }
        return null;
    },

    /**
     * Determines if the traffic is organic (referrer is not chairish.com)
     *
     * @returns {boolean}
     */
    isOrganicReferrer() {
        const referrer = ReferrerUtils.getReferrer();
        return referrer && !referrer.includes(window.location.origin);
    },
};

export default ReferrerUtils;
