import $ from 'jquery';

import KeyUtils from 'chairisher/util/key';
import MediaQueryUtils from 'chairisher/util/mediaquery';
import ModalViewHelper from 'chairisher/view/helper/modal';
import SiteContext from 'chairisher/context/site';
import UriUtils from 'chairisher/util/uri';

import {
    trackDiscoverNavLinkClick,
    trackFavoritesMenuItem,
    trackHeaderCartIconClick,
    trackMotdLinkClick,
    trackProductNavigationHorizontalLinkClick,
    trackProductNavigationLinkClick,
} from 'chairisher/analytics/navigation';
import { hasIntersectionObserver } from 'chairisher/view/helper/intersectionobserver';

const globalSearchNameSpace = 'globalsearch';
const productNavNameSpace = 'productnav';

class NavigationView {
    constructor() {
        this.$window = $(window);

        /**
         * @type {boolean}
         */
        this.isBound = false;

        /**
         * @type {boolean}
         */
        this.isEscapeKeyBound = false;

        /**
         * @type {boolean}
         */
        this.isScreenResizeBound = false;

        /**
         * The current viewport scroll position
         *
         * @type {number}
         */
        this.scrollY = 0;

        /**
         * @type {jQuery}
         */
        this.$productNav = null;
    }

    bind() {
        // sets jQuery object during bind instead of constructor, as data was not yet available
        this.$productNav = $('#js-product-nav');

        if (this.isBound) {
            // prevent the view from being double-bound
            return;
        }

        const shouldAddMotdClass = SiteContext.isMotdBannerVisible() && MediaQueryUtils.isMaxMediumDesktop();
        if (shouldAddMotdClass) {
            this.$productNav.addClass('has-motd');
        }

        const $globalNav = $('#js-global-nav');

        //
        // Sticky Nav
        //
        const crossSiteNav = document.getElementById('js-promo-banner');
        if (crossSiteNav && hasIntersectionObserver()) {
            const crossSiteNavObserver = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    $globalNav.toggleClass('affix', !entry.isIntersecting);
                    this.$productNav.toggleClass('has-motd', entry.isIntersecting && shouldAddMotdClass);
                });
            });
            crossSiteNavObserver.observe(crossSiteNav);

            $('#js-brand-cta').on('click', 'a', (e) => {
                trackDiscoverNavLinkClick(e);
            });
        }

        this.scrollY = window.scrollY;
        SiteContext.setScrollY(this.scrollY);

        this.$window.smartscroll(() => {
            const { scrollY } = window;
            $globalNav.toggleClass('scroll-down', scrollY > this.scrollY);
            $globalNav.toggleClass('scroll-up', scrollY < this.scrollY);

            this.scrollY = scrollY;
            SiteContext.setScrollY(this.scrollY);
        }, 'NavigationView');

        //
        // Mobile Nav
        //

        $('#js-mobile-nav-trigger').on('click', (e) => {
            e.preventDefault();

            this.toggleGlobalSearchSuggestions(false);
            this.toggleProductNav();
        });

        $('.js-actions-account').on('click', () => {
            this.toggleGlobalSearchSuggestions(false);
            this.toggleProductNav(false);
        });

        //
        // Product Nav
        //

        this.$productNav.on('click', '.js-initiator', (e) => {
            if (MediaQueryUtils.isMaxMediumDesktop()) {
                e.preventDefault();

                const $currentTarget = $(e.currentTarget);
                let $siblings = $currentTarget.next('.nav-groups');

                if ($siblings.length === 0) {
                    $siblings = $currentTarget.next('.nav-items');
                }

                $siblings.collapse('toggle');
                $currentTarget.attr('aria-expanded', $siblings.attr('aria-expanded'));
                $currentTarget.find('.cicon').toggleClass('hidden');
            }
        });

        this.$productNav.on('click', 'a', (e) => {
            trackProductNavigationLinkClick(e);
        });

        $('#js-product-nav-horizontal').on('click', 'a', (e) => {
            trackProductNavigationHorizontalLinkClick(e);
        });

        // sets selected state based on pathname
        const href = window.location.pathname + UriUtils.getQueryString();
        const $link = $(`a[href='${href}']`);
        if (MediaQueryUtils.isMinLargeDesktop()) {
            $link.closest('.js-product-nav-item').find('.js-nav-link').addClass('selected');
        } else {
            $link.addClass('selected');
        }

        // maintains hover state on nav bar item when user is within dropdown menu
        this.$productNav.on('mouseenter', '.js-nav-groups', (e) => {
            if (MediaQueryUtils.isMinLargeDesktop()) {
                $(e.currentTarget).closest('.js-product-nav-item').find('.js-nav-link').addClass('hover');
            }
        });

        // removes hover state on nav bar item when user exits dropdown menu
        this.$productNav.on('mouseleave', '.js-nav-groups', (e) => {
            if (MediaQueryUtils.isMinLargeDesktop()) {
                $(e.currentTarget).closest('.js-product-nav-item').find('.js-nav-link').removeClass('hover');
            }
        });

        //
        // Global Search
        //

        $globalNav.on('click', '.js-menu-account, .js-menu-favorites', (e) => {
            const $navItem = $(e.currentTarget);
            const $navItems = $navItem.hasClass('js-menu-account')
                ? $('#js-account-nav-items')
                : $('#js-favorite-nav-items');

            if ($navItems.length) {
                if (!$navItems.hasClass('hidden-xs') || MediaQueryUtils.isMinMediumDesktop()) {
                    e.preventDefault();
                    e.stopPropagation(); // to prevent the menu from closing after binding document.body below

                    $navItem.toggleClass('expanded');
                    $('.nav-item, .js-menu-account, .js-menu-favorites').not($navItem).removeClass('expanded');
                    if ($navItem.hasClass('expanded')) {
                        $(document.body).one('click', () => {
                            $navItem.removeClass('expanded');
                        });
                    }
                }
            }
        });

        // .nav-items that have a link as a child should go to the link's URL
        // if the user taps and holds down on the link
        $('.nav-item > a').on('contextmenu', (e) => {
            e.preventDefault();
            window.location.href = $(e.currentTarget).attr('href');
        });

        //
        // Cart
        //
        $('#id-js-cart-link').on('click', trackHeaderCartIconClick);

        //
        // Favorites Menu Items
        //
        $('.js-favorites-nav-menu .nav-link').on('click', (e) => {
            trackFavoritesMenuItem(e);
            window.location = $(e.currentTarget).attr('href');
        });

        //
        // Message of the Day (MOTD)
        //
        $('#js-banner-motd').on('click', 'a', trackMotdLinkClick);

        this.isBound = true;
    }

    /**
     * Scroll until an element is at the top of the page, accounting for the global nav.
     *
     * @param {jQuery} $el Element to scroll to.
     * @param {number} additionalOffset Any additional pixel offset to add to global nav height
     * @return {$.Deferred} A promise to chain off. Will be resolved when the animation is complete.
     */
    scrollToElement($el, additionalOffset) {
        const navAdditionalOffset = Number.isInteger(additionalOffset) ? additionalOffset : 0;
        const deferred = $.Deferred();

        const $globalNav = $('#js-global-nav');
        let navHeight = $globalNav.outerHeight();
        navHeight += navAdditionalOffset;

        if (MediaQueryUtils.isMaxMediumDesktop()) {
            navHeight += $('.js-masthead .js-search-form').outerHeight();
        } else {
            // The product nav will collapse when we animate, so we need to calculate the actual nav height
            // once the nav is collapsed.
            const isProductNavVisible = !$globalNav.hasClass('affix') || $globalNav.hasClass('scroll-up');
            if (isProductNavVisible) {
                navHeight -= this.$productNav.outerHeight();
            }
        }

        $('html, body').animate(
            {
                scrollTop: $el.offset().top - navHeight,
            },
            () => {
                deferred.resolve();
            },
        );

        return deferred;
    }

    /**
     * Toggles the global product nav to be shown or hidden in relation to global product search suggestions
     * @param {boolean} shouldDisplay
     */
    toggleGlobalSearchSuggestions(shouldDisplay) {
        this.$productNav.toggleClass('search-suggestions', shouldDisplay);
    }

    /**
     * Toggles the global product nav to be shown or hidden.
     * @param {boolean} shouldDisplay True indicates the global product nav should be shown, false hides it
     */
    toggleProductNav(shouldDisplay) {
        const $nav = this.$productNav;
        const shouldDisplayNav = shouldDisplay === undefined ? !$nav.hasClass('visible') : shouldDisplay;

        if (SiteContext.getScrollY() === 0 && MediaQueryUtils.isMaxMediumDesktop()) {
            $('html, body').animate({ scrollTop: 0 }, () => {
                $nav.toggleClass('visible', shouldDisplayNav);
            });
        } else {
            $nav.toggleClass('visible', shouldDisplayNav);
        }

        const $backdrop = ModalViewHelper.toggleBackdrop(shouldDisplayNav);

        if (shouldDisplayNav) {
            $backdrop.addClass('nav-backdrop js-nav-backdrop');

            $backdrop.on('click', (e) => {
                e.preventDefault();

                ModalViewHelper.toggleBackdrop(false);
                this.hideMobileNavAndSearchField();
            });

            this.bindEscapeKey();
            this.bindScreenResize();
        }

        const newClass = shouldDisplayNav ? 'cicon-x' : 'cicon-hamburger';
        const oldClass = shouldDisplayNav ? 'cicon-hamburger' : 'cicon-x';

        $('#js-mobile-nav-trigger').removeClass(oldClass).addClass(newClass);
    }

    /**
     * Binds the escape key to close the product nav and search form when pressed.
     * Unbinds itself when pressed.
     *
     */
    bindEscapeKey() {
        if (!this.isEscapeKeyBound) {
            this.isEscapeKeyBound = true;

            this.$window.one(`keyup.${globalSearchNameSpace}`, (e) => {
                if (e.keyCode === KeyUtils.KeyCodes.Escape) {
                    ModalViewHelper.toggleBackdrop(false);
                    this.hideMobileNavAndSearchField();
                    this.unbindEscapeKey();
                }
            });
        }
    }

    /**
     * Binds the hiding of mobile navigation and modal backdrop when the screen is resized to be desktop.
     * The global search form is hidden on tablet when the product nav is visible.
     *
     */
    bindScreenResize() {
        if (!this.isScreenResizeBound) {
            this.$window.smartresize(() => {
                if (MediaQueryUtils.isMinLargeDesktop()) {
                    ModalViewHelper.toggleBackdrop(false);
                    this.hideMobileNavAndSearchField();
                    this.unbindScreenResize();
                }
            }, productNavNameSpace);

            this.isScreenResizeBound = true;
        }
    }

    /**
     * Hides the mobile product navigation and search field
     *
     */
    hideMobileNavAndSearchField() {
        this.toggleProductNav(false);
        this.toggleGlobalSearchSuggestions(false);
        this.unbindEscapeKey();
        this.unbindScreenResize();
    }

    /**
     * Unbinds the escape key from closing the product nav and search form when pressed.
     *
     */
    unbindEscapeKey() {
        this.$window.off(`keyup.${globalSearchNameSpace}`);
        this.isEscapeKeyBound = false;
    }

    /**
     * Unbinds the hiding of mobile navigation and modal backdrop when the screen is resized to be desktop.
     *
     */
    unbindScreenResize() {
        this.$window.off(`resize.${productNavNameSpace}`);
        this.isScreenResizeBound = false;
    }
}

export default new NavigationView();
