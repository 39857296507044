const PaypalStoreOffer = {};

/**
 * Excludes a user from Paypal Store Offer program if they are opted out of retargeting/advertising cookies
 * or a returning buyer
 *
 * @param {boolean} optedOutOrIneligible
 */
PaypalStoreOffer.excludeUser = function (optedOutOrIneligible) {
    if (optedOutOrIneligible) {
        window.paypalDDL = window.paypalDDL || [];
        window.paypalDDL.push({
            event: 'userIdentification',
            identified: true,
        });
    }
};

export default PaypalStoreOffer;
