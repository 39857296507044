import { isDataSharingOptedOut } from 'chairisher/context/account';

const FacebookAnalytics = {
    /**
     * @type {Array.<Object>}
     * @private
     */
    fbqQueue: [],

    /**
     * Id of the interval being used to flush the facebook event queue
     *
     * @type {number|null}
     * @private
     */
    fbqQueueIntervalId: null,

    /**
     * Sends Facebook a conversion event
     *
     * @param {Array} eventData Event data to pass to Facebook
     */
    logFacebookEvent(eventData) {
        if (window.fbq) {
            window.fbq.apply(window.fbq, eventData);
        } else {
            this.fbqQueue.push(eventData);

            if (!this.fbqQueueIntervalId) {
                this.fbqQueueIntervalId = window.setInterval(() => {
                    if (window.fbq) {
                        window.clearInterval(this.fbqQueueIntervalId);
                        this.fbqQueueIntervalId = null;
                        this.purgeFbqQueue();
                    }
                }, 1000);
            }
        }
    },

    /**
     * Sends enqueued facebook events that occurred before facebook was loaded
     */
    purgeFbqQueue() {
        for (let i = 0; i < this.fbqQueue.length; i += 1) {
            window.fbq.apply(window.fbq, this.fbqQueue[i]);
        }

        this.fbqQueue = [];
    },

    /**
     * Tracks a Facebook CompleteRegistration conversion (email collection)
     *
     */
    trackFacebookRegistrationConversion() {
        if (!isDataSharingOptedOut()) {
            this.logFacebookEvent(['track', 'CompleteRegistration']);
            this.logFacebookEvent(['track', 'Lead']);
        }
    },
};

export default FacebookAnalytics;
