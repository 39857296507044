import $ from 'jquery';

import RailUtils from 'chairisher/util/rail';

import { trackArrowClick } from 'chairisher/analytics/components/rail';

/**
 * A component that turns an element into a RepeatingScroller, which cycles through the elements
 * in either direction infinitely.
 *
 * @param {Object} settings
 * @param {AnalyticsUtils.Tracking.POSITION=} settings.analyticsTrackingPosition The position of the scroller
 * @param {AnalyticsUtils.Tracking.SUB_TYPE=} settings.analyticsTrackingSubtype The sub-type of the scroller
 * @param {AnalyticsUtils.Tracking.TYPE=} settings.analyticsTrackingType The type of the scroller
 * @param {Element} settings.container The outermost element that scrolls
 * @param {Array.<Element>} settings.children Collection of child elements displayed in the scroller
 * @param {boolean=} settings.childrenHaveImages Flag indicating whether every child's primary content is an image. This value is determines how controls are vertically aligned
 * @param {Element} settings.controllerContainerWrapperEl Optional element to place the controller container in for styling purposes
 * @param {boolean=} settings.shouldAutoScroll Flag indicating if the scroller should scroll on its own
 *
 * @class RepeatingScroller
 * @constructs RepeatingScroller
 */
const RepeatingScroller = function (settings) {
    settings = $.extend(
        {
            analyticsTrackingPosition: null,
            analyticsTrackingSubtype: null,
            analyticsTrackingType: null,
            container: this._containerEl,
            children: this._children,
            childrenHaveImages: true,
            controllerContainerWrapperEl: this.controllerContainerWrapperEl,
            shouldAutoScroll: this._shouldAutoScroll,
            slideDurationInMs: 3000,
            transitionDurationInMs: 400, // jQuery.animate's default
        },
        settings,
    );

    this.analyticsTrackingPosition = settings.analyticsTrackingPosition;
    this.analyticsTrackingSubtype = settings.analyticsTrackingSubtype;
    this.analyticsTrackingType = settings.analyticsTrackingType;
    this._containerEl = settings.container;
    this._controllerContainerWrapperEl = settings.controllerContainerWrapperEl;
    this._children = settings.children;
    this._childrenHaveImages = settings.childrenHaveImages;
    this._shouldAutoScroll = settings.shouldAutoScroll;
    this._slideDurationInMs = settings.slideDurationInMs;
    this._transitionDurationInMs = settings.transitionDurationInMs;

    this._$containerEl = $(this._containerEl);
    this._$containerEl.addClass('is-scroller');

    this._$windowEl = $(window);

    this._buildControls();

    this._resizeAction();
    this._isInitialized = true;

    if (this._numVisibleElements < this._children.length && this._childWidth < this._$containerEl.width()) {
        this._shouldAutoScroll = false;
    }

    this._$containerEl.on(this._nameSpacedEvent('scroll'), $.proxy(this._scrollAction, this));
    this._$windowEl.on(this._nameSpacedEvent('resize'), $.proxy(this._resizeAction, this));

    if (this._shouldAutoScroll) {
        this._autoscrollStart();
    }
};

/**
 * jQuery instance of `this._containerEl` to provide for easier binding
 *
 * @type {jQuery}
 * @private
 */
RepeatingScroller.prototype._$containerEl = null;

/**
 * jQuery instance of `window` to provide for easier binding
 *
 * @type {jQuery}
 * @private
 */
RepeatingScroller.prototype._$windowEl = null;

/**
 * Id of the auto scroll interval loop
 *
 * @type {number|null}
 * @private
 */
RepeatingScroller.prototype._autoScrollId = null;

/**
 * Collection of child elements displayed in the scroller
 *
 * @type {Array.<Element>}
 * @private
 */
RepeatingScroller.prototype._children = null;

/**
 * The width of a child element; assumes all children are the same width
 *
 * @type {number}
 * @private
 * @default
 */
RepeatingScroller.prototype._childWidth = 0;

/**
 * The outermost element that scrolls
 *
 * @type {Element}
 * @private
 */
RepeatingScroller.prototype._containerEl = null;

/**
 * Optional Element containing directional arrows
 *
 * @type {Element}
 * @private
 */
RepeatingScroller.prototype._controlContainerEl = null;

/**
 * Optional element to place the controller container in for styling purposes
 *
 * @type {Element|null}
 * @private
 */
RepeatingScroller.prototype._controllerContainerWrapperEl = null;

/**
 * The container element' (`this._containerEl`) current scrollLeft position
 *
 * @type {number}
 * @private
 * @default
 */
RepeatingScroller.prototype._currentScrollLeft = 0;

/**
 * The namespace under which events should be bound
 *
 * @type {string}
 * @private
 * @default
 */
RepeatingScroller.prototype._eventNameSpace = 'repeatingscroller';

/**
 * Flag used to determine if all of the elements have been initialized and are ready for use
 *
 * @type {boolean}
 * @private
 * @default
 */
RepeatingScroller.prototype._isInitialized = false;

/**
 * Flag that indicates the scroller is in the process of resetting everything
 *
 * @type {boolean}
 * @private
 * @default
 */
RepeatingScroller.prototype._isRepositioning = false;

/**
 * The index number that is the midpoint in the collection of children.
 * Used to populate elements before AND after the first element on load
 *
 * @type {number}
 * @private
 * @default
 */
RepeatingScroller.prototype._midpoint = 0;

/**
 * The number of elements that should always be visible
 *
 * @type {number}
 * @private
 * @default
 */
RepeatingScroller.prototype._numVisibleElements = 8;

/**
 * The X coordinate that indicates the initial X coordinate that serves as the origin
 *
 * @type {number}
 * @private
 * @default
 */
RepeatingScroller.prototype._originX = 0;

/**
 * Flag indicating if the repeating scroller should scroll on its own automatically
 *
 * @type {boolean}
 * @private
 */
RepeatingScroller.prototype._shouldAutoScroll = true;

/**
 * @returns {boolean} True indicates the scroller is autoscrolling
 */
RepeatingScroller.prototype.isAutoScrolling = function () {
    return !!this._autoScrollId;
};

/**
 * Scrolls the container backward by n element indexes
 *
 * @param {number} num The number of indexes to scroll backward
 */
RepeatingScroller.prototype.scrollBackward = function (num = 1) {
    this._scrollContainer(this._childWidth * -num);
};

/**
 * Scrolls the container forward by n element indexes
 *
 * @param {number} num The number of indexes to scroll forward
 */
RepeatingScroller.prototype.scrollForward = function (num = 1) {
    this._scrollContainer(this._childWidth * num);
};

/**
 * Stops the scroller from auto scrolling
 *
 * @private
 */
RepeatingScroller.prototype._autoscrollEnd = function () {
    window.clearInterval(this._autoScrollId);
    this._autoScrollId = null;

    const blur = this._nameSpacedEvent('blur');
    const focus = this._nameSpacedEvent('focus');
    const mouseout = this._nameSpacedEvent('mouseout');

    this._$windowEl.off(blur);
    this._$windowEl.off(focus).on(focus, $.proxy(this._autoscrollStart, this));
    this._$containerEl.off(mouseout).on(mouseout, $.proxy(this._autoscrollStart, this));
};

/**
 * Makes the scroller begin scrolling one item at a time
 *
 * @private
 */
RepeatingScroller.prototype._autoscrollStart = function () {
    this._autoscrollEnd(); // ensure we're not creating multiple event loops

    this._autoScrollId = window.setInterval(() => this.scrollForward(), this._slideDurationInMs);

    const blur = this._nameSpacedEvent('blur');
    const focus = this._nameSpacedEvent('focus');
    const mouseover = this._nameSpacedEvent('mouseover');

    this._$windowEl.off(blur).on(blur, $.proxy(this._autoscrollEnd, this));
    this._$windowEl.off(focus);

    this._$containerEl.off(mouseover).on(mouseover, $.proxy(this._autoscrollEnd, this));
};

/**
 * Builds clickable left and right controls that scroll the container forward and backward
 *
 * @private
 */
RepeatingScroller.prototype._buildControls = function () {
    this._controlContainerEl = document.createElement('div');
    this._controlContainerEl.classList.add('repeating-scroller-controls');

    const controlLeft = document.createElement('span');
    controlLeft.classList.add('cicon', 'cicon-chevron-left', 'js-scroll-left');
    controlLeft.dataset.value = 'previous';

    const controlRight = document.createElement('span');
    controlRight.classList.add('cicon', 'cicon-chevron-right', 'js-scroll-right');
    controlRight.dataset.value = 'next';

    if (this._controllerContainerWrapperEl) {
        this._controllerContainerWrapperEl.appendChild(this._controlContainerEl);
    }

    this._controlContainerEl.appendChild(controlLeft);
    this._controlContainerEl.appendChild(controlRight);
    this._containerEl.parentElement.insertBefore(
        this._controllerContainerWrapperEl || this._controlContainerEl,
        this._containerEl,
    );

    const click = this._nameSpacedEvent('click');
    const mouseout = this._nameSpacedEvent('mouseout');
    const mouseover = this._nameSpacedEvent('mouseover');

    const $controlLeft = $(controlLeft);
    $controlLeft.on(click, () => {
        this.scrollBackward(this._numVisibleElements);
        trackArrowClick({
            position: this.analyticsTrackingPosition,
            subtype: this.analyticsTrackingSubtype,
            type: this.analyticsTrackingType,
            value: $controlLeft.data('value'),
        });
    });

    const $controlRight = $(controlRight);
    $controlRight.on(click, () => {
        this.scrollForward(this._numVisibleElements);
        trackArrowClick({
            position: this.analyticsTrackingPosition,
            subtype: this.analyticsTrackingSubtype,
            type: this.analyticsTrackingType,
            value: $controlRight.data('value'),
        });
    });

    if (this._shouldAutoScroll) {
        $controlLeft.on(mouseout, $.proxy(this._autoscrollStart, this));
        $controlLeft.on(mouseover, $.proxy(this._autoscrollEnd, this));
        $controlRight.on(mouseout, $.proxy(this._autoscrollStart, this));
        $controlRight.on(mouseover, $.proxy(this._autoscrollEnd, this));
    }
};

/**
 * Given a calculated index that might be greater or less than the number of children (it is based on position),
 * returns the index of an actual child element
 *
 * @param {number} index The given index, which might be greater or less than the number of children
 * @returns {number} The normalized child index, which should always be present in `this._children`
 * @private
 */
RepeatingScroller.prototype._getNormalizedIndex = function (index) {
    const childrenLength = this._children.length;

    if (index >= childrenLength) {
        index %= childrenLength;
    }

    if (index < 0) {
        index += childrenLength;
    }

    return index;
};

/**
 * @param {string} eventName The name of the event to namespace
 * @returns {string} The namespaced event
 * @private
 */
RepeatingScroller.prototype._nameSpacedEvent = function (eventName) {
    return [eventName, this._eventNameSpace].join('.');
};

/**
 * Positions all of the elements and surrounds index 0 with elements before and after it so it's
 * effectively in the "middle".
 *
 * @private
 */
RepeatingScroller.prototype._positionAllElements = function () {
    let i;
    this._isRepositioning = true; // ensures we don't reposition all elements again while this is in progress...

    for (i = 0; i < this._midpoint; i++) {
        this._positionChildEl(this._children[i], [this._originX + this._childWidth * i, 0]);
    }

    let count = 1;
    for (i = this._children.length - 1; i >= this._midpoint; i--) {
        this._positionChildEl(this._children[i], [this._originX - this._childWidth * count++, 0]);
    }

    this._containerEl.scrollLeft = this._originX;
    this._currentScrollLeft = this._originX;
    this._isRepositioning = false;
};

/**
 * Transforms the element to the given coordinates
 *
 * @param {Element} el The element to position
 * @param {Array.<number>} coordinates The X and Y coordinates to set
 * @private
 */
RepeatingScroller.prototype._positionChildEl = function (el, coordinates) {
    if (!this._isInitialized) {
        el.style.left = 0; // ensure all elements start in the same place so the transform is correct
        el.style.position = 'absolute';
        el.style.willChange = 'transform';
    }

    el.style.transform = `translate(${coordinates[0]}px, ${coordinates[1]}px)`;
};

/**
 * Recalculates all of the necessary dimensions for the given screen size and re-positions everything
 *
 * @private
 */
RepeatingScroller.prototype._resizeAction = function () {
    const containerOffsetWidth = this._containerEl.offsetWidth;
    const numChildren = this._children.length;

    this._childWidth = Math.floor(this._children[0].offsetWidth); // assume that every element is the same width...

    this._childImgHeight = 0;

    if (this._childrenHaveImages) {
        this._childImgHeight = Math.floor($(this._children[0]).find('img')[0].offsetHeight);
    }

    this._numVisibleElements = Math.round(containerOffsetWidth / this._childWidth);
    this._originX = this._childWidth * numChildren;

    this._$containerEl.css('overflow-x', 'scroll');

    this._midpoint =
        Math.floor(this._numVisibleElements * (Math.round(numChildren / this._numVisibleElements) - 1)) ||
        this._numVisibleElements;

    // TODO: (CHAIR-16614) Update when rolling out dynamic heights to all rails
    if (this._$containerEl.parent().hasClass('dynamic-height-rail')) {
        this._containerEl.style.height = RailUtils.calculateContainerHeight({ $children: $(this._children) });
    } else {
        this._containerEl.style.height = `${Math.floor(this._children[0].offsetHeight)}px`;
    }

    this._containerEl.style.position = 'relative';

    if (this._controlContainerEl) {
        const chevronHeight = this._controlContainerEl.getElementsByClassName('cicon')[0].offsetHeight;
        this._controlContainerEl.style.width = `${this._childWidth}px`;
        this._controlContainerEl.style.margin = '0 auto';
        this._controlContainerEl.style.width = '100%';
        if (this._childImgHeight) {
            this._controlContainerEl.style.marginTop = `${
                Math.floor(this._childImgHeight / 2) - Math.floor(chevronHeight / 2)
            }px`;
        }
        this._controlContainerEl.classList.remove('hidden');
    }

    if (this._numVisibleElements >= numChildren) {
        this._$containerEl.css('overflow-x', 'hidden');

        if (this._controlContainerEl) {
            this._controlContainerEl.classList.add('hidden');
        }
    } else {
        this._positionAllElements();
    }
};

/**
 * The action to take on scroll to determine how and when to reposition which elements
 *
 * @private
 */
RepeatingScroller.prototype._scrollAction = function () {
    if (this._numVisibleElements < this._children.length) {
        const containerScrollLeft = this._containerEl.scrollLeft;
        const delta = containerScrollLeft - this._originX;

        const shouldReset = Math.abs(delta) >= this._children.length * this._childWidth;

        let index = Math.floor((this._originX + delta) / this._childWidth) - 1;
        index += isAdvancing ? this._midpoint : 0;

        if (shouldReset) {
            this._positionAllElements();
        } else if (delta !== 0 || !this._isRepositioning) {
            var isAdvancing = containerScrollLeft > this._currentScrollLeft;
            this._currentScrollLeft = containerScrollLeft;

            // calculate the "true" index based on scroll position
            index = Math.floor((this._originX + delta) / this._childWidth) - 1;
            index += isAdvancing ? this._midpoint : 0;

            // calculate the "actual" index based on the collection of children
            const normalizedIndex = this._getNormalizedIndex(index);
            const newXCoord = this._childWidth * index;
            const child = this._children[normalizedIndex];

            this._positionChildEl(child, [newXCoord, 0]);
        }
    }
};

/**
 * Scrolls the container by a given amount (in pixels)
 *
 * @param {number} scrollAmountInPixels the amount to scroll the container element (in pixels)
 * @private
 */
RepeatingScroller.prototype._scrollContainer = function (scrollAmountInPixels) {
    this._$containerEl.stop().animate(
        {
            scrollLeft:
                Math.floor(this._containerEl.scrollLeft / this._childWidth) * this._childWidth + scrollAmountInPixels,
        },
        this._transitionDurationInMs,
    );
};

export default RepeatingScroller;
