import GoogleAnalyticsUtils from 'chairisher/util/googleanalytics';

import { AmplitudeEventProperty } from 'chairisher/util/analytics';

/**
 * Tracks an account create for Bing
 */
export function trackAccountCreated() {
    window.uetq = window.uetq || [];
    window.uetq.push('event', '', { event_category: 'Account - Create' });
}

/**
 * Tracks an attempt to perform an action that requires authentication
 *
 * @param {string} action The action that was attempted that required authorization
 */
export function trackUnauthorizedAction(action) {
    GoogleAnalyticsUtils.logEvent('quick create modal - show', {
        [AmplitudeEventProperty.TYPE]: action,
    });
}

/**
 * Tracks an unsuccessful authorization after attempting to perform an action that requires authentication
 *
 * @param {string} action The action that was attempted that required authorization
 */
export function trackUnsuccessfulAuthorizationForAction(action) {
    GoogleAnalyticsUtils.logEvent('quick create modal - unsuccessful collection', {
        [AmplitudeEventProperty.TYPE]: action,
    });
}

/**
 * Tracks a successful authorization after attempting to perform an action that requires authentication
 *
 * @param {string} action The action that was attempted that required authorization
 */
export function trackSuccessfulAuthorizationForAction(action) {
    GoogleAnalyticsUtils.logEvent('quick create modal - successful collection', {
        [AmplitudeEventProperty.TYPE]: action,
    });
}
