import $ from 'jquery';

import AuthContext from 'chairisher/context/auth';

import { getNumPrivateSales, getPrivateSaleUrl, hasPrivateSales } from 'chairisher/context/account';

const FavoritesNavMenu = {
    bind(data) {
        const $navItemsContainer = $('#js-favorite-nav-items');
        const navItems = [];

        if (AuthContext.isAuthenticated()) {
            if (hasPrivateSales()) {
                const $link = $('<a></a>', {
                    class: 'nav-link',
                    href: getPrivateSaleUrl(),
                }).append([
                    $('<div></div>', {
                        class: 'favorites-nav-private-sale favorites-nav-link-titles',
                        text: 'Private Sale',
                    }),
                    $('<div></div>', {
                        class: 'favorites-nav-link-titles',
                        text: 'Special offers just for you!',
                    }),
                    $('<div></div>', {
                        class: 'favorites-nav-pst-item-icon favorites-nav-private-sale-items favorites-nav-subtitle',
                        text: `${getNumPrivateSales()} ${getNumPrivateSales() > 1 ? ' items' : ' item'}`,
                    }),
                ]);

                const $listItem = $('<li></li>', {
                    class: 'nav-item nav-item-private-sale',
                }).append($link);

                navItems.push($listItem);
            }

            $.each(data.favorite_nav_items, (i, navItem) => {
                const $link = $('<a></a>', {
                    class: 'nav-link',
                    href: navItem.url,
                }).append([
                    $('<div></div>', {
                        class: 'favorites-nav-link-titles',
                        text: navItem.title.display,
                    }),

                    $('<div></div>', {
                        class: 'favorites-nav-subtitle',
                        text: navItem.subtitle.display,
                    }),
                ]);

                const $listItem = $('<li></li>', {
                    class: 'nav-item',
                }).append($link);

                navItems.push($listItem);
            });

            $navItemsContainer.html(navItems);
        }
    },
    favoritesNavIcon() {
        if (hasPrivateSales()) {
            $('#js-favorites-nav').addClass('has-sales');
        }
    },
};

export default FavoritesNavMenu;
